import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { LoggingService } from './logging.service';
import environment from '../../environments/static';

const performCookieRenewals = environment.compiled;
const renewThreshold = 7 * 60 * 1000; // Start renewing if the user has been inactive for 7 minutes, and then becomes active again
const logoutThreshold = 14 * 60 * 1000; // Force logout after 14 minutes of inactivity
const idleCheckPeriod = 60 * 1000; // Check for idleness every minute

@Injectable({
    providedIn: 'root',
})
export class IdleMonitorService {
    private renewingToken = false;
    private lastActivityTime: number;
    private idleCheckerId: number | null = null;

    constructor(private loggingService: LoggingService, private loginService: LoginService) {
        const options = {
            capture: true
        };

        if (performCookieRenewals) {
            document.addEventListener('mousemove', e => this.eventListener(e), options);
            document.addEventListener('keydown', e => this.eventListener(e), options);
            document.addEventListener('wheel', e => this.eventListener(e), options);
            document.addEventListener('mousewheel', e => this.eventListener(e), options);
            document.addEventListener('touchstart', e => this.eventListener(e), options);
            document.addEventListener('touchmove', e => this.eventListener(e), options);
            document.addEventListener('visibilitychange', e => this.eventListener(e), options);
            document.addEventListener('resize', e => this.eventListener(e), options);
            document.addEventListener('mousedown', e => this.eventListener(e), options);
        }
    }

    start() {
        if (this.idleCheckerId !== null) {
            console.debug("IdleMonitorService already started!");
            return;
        }

        if (performCookieRenewals) {
            this.lastActivityTime = Date.now();
            this.idleCheckerId = window.setInterval(() => this.checkIdleness(), idleCheckPeriod);
        }
    }

    stop() {
        if (this.idleCheckerId === null) {
            console.debug("IdleMonitorService already stopped!");
            return;
        }

        if (performCookieRenewals) {
            window.clearInterval(this.idleCheckerId);
            this.idleCheckerId = null;
        }
    }

    private eventListener(e: Event): void {
        if (this.idleCheckerId === null || this.renewingToken)
            return; // Nothing to do!

        if (this.lastActivityTime && Date.now() - this.lastActivityTime > renewThreshold) {
            try {
                this.renewingToken = true;
                this.loginService.renewToken();
                this.lastActivityTime = Date.now();
            } catch (error) {
                this.loggingService.logTrace(`Failed to renew token for user ${this.loginService.currentIdentity?.username ?? '<unknown>'}`);
            } finally {
                this.renewingToken = false;
            }
        }
    }

    private checkIdleness(): void {
        const now = Date.now();
        const delta = now - this.lastActivityTime;

        if (delta > logoutThreshold) {
            alert('You have been logged out due to inactivity.');
            this.loginService.logout();
        }
    }
}
