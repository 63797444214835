import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ApiDataService } from 'src/app/service/api-data.service';
import {
    BatchCorrectionActionTypes,
    GeneratePreviewAction,
    GeneratePreviewSuccessAction,
    LoadErrorsForBatchCorrectionAction,
    LoadErrorsForBatchCorrectionSuccessAction,
    StartBatchResubmissionAction,
    ResubmitErrorAction,
    ResubmitErrorSuccessAction,
    ResubmitErrorFailedAction,
    BatchResubmissionCompletedAction
} from './batch-correction.actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { MultipleErrorRequest } from 'src/app/model/error-record/MultipleErrorRequest';
import { AppState } from '..';
import { Store } from '@ngrx/store';
import { selectCorrectionSteps, selectErrorIds, selectFirstBatchForResbmission, selectNextEntryForResubmission } from './batch-correction.selectors';
import { BatchCorrectionRequest } from 'src/app/model/batch-correction/BatchCorrectionRequest';
import { ErrorNotificationAction } from '../notification/notification.actions';
import { ErrorResubmissionRequest } from 'src/app/model/error-record/ErrorResubmissionRequest';
import { of } from 'rxjs';

@Injectable()
export class BatchCorrectionEffects {

    @Effect()
    loadErrors$ = this.actions$
        .pipe(
            ofType<LoadErrorsForBatchCorrectionAction>(BatchCorrectionActionTypes.LoadErrorsForBatchCorrection),
            mergeMap(action => {
                const request = { ids: action.payload.ids } as MultipleErrorRequest;
                return this.apiDataService.getErrorRecords(request)
                    .pipe(
                        map((result) => new LoadErrorsForBatchCorrectionSuccessAction({ errors: result })),
                        catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to load errors: ${error.message}` })))
                    );
            })
        );

    @Effect({ dispatch: true })
    generatePreview$ = this.actions$
        .pipe(
            ofType<GeneratePreviewAction>(BatchCorrectionActionTypes.GeneratePreview),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => {
                const steps = selectCorrectionSteps(storeState);
                const request: BatchCorrectionRequest = {
                    errorIds: selectErrorIds(storeState),
                    steps: steps
                }
                return this.apiDataService.generateBatchCorrectionPreview(request)
                    .pipe(
                        map((result) => {
                            return new GeneratePreviewSuccessAction({ preview: result });
                        }),
                        catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to generate preview: ${error.message}` })))
                    )
            })
        );

    @Effect()
    startBatchResubmission$ = this.actions$
        .pipe(
            ofType<StartBatchResubmissionAction>(BatchCorrectionActionTypes.StartBatchResubmission),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => selectFirstBatchForResbmission(storeState)
                .map(preview => {
                    return new ResubmitErrorAction({ correction: preview });
                })
            )
        );

    @Effect()
    resubmitError$ = this.actions$
        .pipe(
            ofType<ResubmitErrorAction>(BatchCorrectionActionTypes.ResubmitError),
            mergeMap(action => {
                const correction = action.payload.correction;
                const request: ErrorResubmissionRequest = {
                    payload: {
                        payload: correction.payload.payload
                    },
                    call: correction.call
                };

                return this.apiDataService.resubmitError(correction.id, request)
                    .pipe(
                        map(() => {
                            return new ResubmitErrorSuccessAction({ correction: correction });
                        }),
                        catchError((error) => of(new ResubmitErrorFailedAction({ resubmission: { error, correction } })))
                    )
            })
        );

    @Effect()
    resubmitErrorSuccessOrFailed$ = this.actions$
        .pipe(
            ofType(BatchCorrectionActionTypes.ResubmitErrorSuccess, BatchCorrectionActionTypes.ResubmitErrorFailed),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => {
                const preview = selectNextEntryForResubmission(storeState);
                return preview === null ?
                    of(new BatchResubmissionCompletedAction()) :
                    of(new ResubmitErrorAction({ correction: preview }))
            })
        );

    constructor(
        private actions$: Actions,
        private apiDataService: ApiDataService,
        private store$: Store<AppState>) { }
}
