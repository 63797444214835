import { createSelector } from "@ngrx/store";
import { ExportExcelTopErrorsRequest } from "src/app/model/dashboard/exportExcelTopErrorsRequest";
import { ExportExcelErrorInflowRequest } from "src/app/model/dashboard/exportExcelErrorInflowRequest";
import { TopErrorInflowRequest } from "src/app/model/dashboard/topErrorInflowRequest";
import { AppState } from "..";
import { DashboardState } from "./dashboard.reducers";
import { ErrorInflowSearchRequest } from "src/app/model/dashboard/errorInflowSearchRequest";
import { TopFactoryHittersRequest } from "src/app/model/dashboard/topFactoryHittersRequest";
import { ExportExcelTopFactoryHittersRequest } from "src/app/model/dashboard/exportExcelTopFactoryHittersRequest";

const featureSelector = (state: AppState) => state.dashboard;

export const selectDashboardSearchRequest = createSelector(
    featureSelector,
    (state: DashboardState) => state.searchRequest
);

export const selectTopErrorsResult = createSelector(
    featureSelector,
    (state: DashboardState) => state.topErrorsResult
);

export const selectErrorInflowResult = createSelector(
    featureSelector,
    (state: DashboardState) => state.errorInflowResult
);

export const selectTopFactoryHittersResult = createSelector(
    featureSelector,
    (state: DashboardState) => state.topFactoryHittersResult
);

export const selectTopFactoryHittersRequest = createSelector(
    selectDashboardSearchRequest,
    request => {
        return {
            fromDate: request.fromDate,
            toDate: request.toDate,
            pageSize: request.pageSize,
        } as TopFactoryHittersRequest;
    }
);

export const selectTopErrorsRequest = createSelector(
    selectDashboardSearchRequest,
    request => {
        return {
            fromDate: request.fromDate,
            toDate: request.toDate,
            eventType: request.eventType,
            sendingUnit: request.sendingUnit,
            productNumber: request.productNumber,
            revisionState: request.revisionState,
            errorCategory: request.errorCategory,
        } as TopErrorInflowRequest;
    }
);

export const selectErrorInflowRequest = createSelector(
    selectDashboardSearchRequest,
    request => {
        return {
            fromDate: request.fromDate,
            toDate: request.toDate,
            eventType: request.eventType,
            sendingUnit: request.sendingUnit,
            dateGrouping: request.dateGrouping,
            productNumber: request.productNumber,
            revisionState: request.revisionState,
            errorCategory: request.errorCategory,
            pageSize: request.pageSize
        } as ErrorInflowSearchRequest;
    }
);

export const selectExportTopFactoryHittersToExcelRequest = createSelector(
    selectDashboardSearchRequest,
    request => {
        return {
            fromDate: request.fromDate,
            toDate: request.toDate
        } as ExportExcelTopFactoryHittersRequest;
    }
);

export const selectExportTopErrorsToExcelRequest = createSelector(
    selectTopErrorsRequest,
    request => {
        return {
            fromDate: request.fromDate,
            toDate: request.toDate,
            eventType: request.eventType,
            sendingUnit: request.sendingUnit,
        } as ExportExcelTopErrorsRequest;
    }
);

export const selectExportErrorInflowToExcelRequest = createSelector(
    selectDashboardSearchRequest,
    request => {
        return {
            fromDate: request.fromDate,
            toDate: request.toDate,
            eventType: request.eventType,
            sendingUnit: request.sendingUnit,
            dateGrouping: request.dateGrouping,
            productNumber: request.productNumber,
            revisionState: request.revisionState,
            errorCategory: request.revisionState
        } as ExportExcelErrorInflowRequest
    }
);
