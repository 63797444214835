import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorRecord } from 'src/app/model/error-record/ErrorRecord';
import { CorrectionStep } from 'src/app/model/batch-correction/CorrectionStep';
import { StepperSelectionEvent } from '@angular/cdk/stepper/stepper';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as PageTitleActions from 'src/app/state/page-title/page-title.actions';
import * as BatchCorrectionActions from 'src/app/state/batch-correction/batch-correction.actions';
import * as ErrorDetailsActions from 'src/app/state/error-details/error-details.actions';
import { Observable } from 'rxjs';
import { selectCorrectionFieldTypeValues, selectCorrectionSteps, selectErrors, selectPreview } from 'src/app/state/batch-correction/batch-correction.selectors';
import { CorrectionFieldType } from 'src/app/model/batch-correction/CorrectionFieldType';

@Component({
  selector: 'app-batch-process-stepper',
  templateUrl: './batch-process-stepper.component.html',
  styleUrls: ['./batch-process-stepper.component.scss'],
})
export class BatchProcessStepperComponent implements OnInit {

  isAddingStep = true;

  correctionSteps$: Observable<CorrectionStep[]>;
  errors$: Observable<ErrorRecord[]>;
  fieldTypeValues$: Observable<CorrectionFieldType>;
  preview$: Observable<ErrorRecord[]>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {

    this.store.dispatch(new PageTitleActions.SetPageTitleAction({ pageTitle: 'Batch Correction' }));

    this.correctionSteps$ = this.store.select(selectCorrectionSteps);
    this.errors$ = this.store.select(selectErrors);
    this.fieldTypeValues$ = this.store.select(selectCorrectionFieldTypeValues);
    this.preview$ = this.store.select(selectPreview);
  }

  onRemoveStep(step: CorrectionStep) {
    this.store.dispatch(new BatchCorrectionActions.RemoveStepAction({ step }));
  }

  startAddingStep() {
    this.isAddingStep = true;
  }

  addNewStep(step: CorrectionStep) {
    this.store.dispatch(new BatchCorrectionActions.AddStepAction({ step }));
    this.stopAddingStep();
  }

  stopAddingStep() {
    this.isAddingStep = false;
  }

  onStepChange(event: StepperSelectionEvent) {
    if (event.selectedIndex === 1 && event.previouslySelectedIndex === 0) {
      this.store.dispatch(new BatchCorrectionActions.GeneratePreviewAction());
    } else if (event.selectedIndex === 2 && event.previouslySelectedIndex === 1) {
      this.store.dispatch(new BatchCorrectionActions.StartBatchResubmissionAction());
    }
  }

  navigateAway() {
    this.store.dispatch(new ErrorDetailsActions.LoadResultsAction({ loadCount: true }));
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
