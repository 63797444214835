export class ErrorRecordSearchRequest {
    searchText: string;
    errorCode: string;
    unitReference: string;
    pageIndex = 0;
    pageSize = 20;
    fromDate: string;
    toDate: string;
    sendingUnit: string;
    status: string;
    errorCategory: string;
    eventCode: string;
    revisionState: string;
}
