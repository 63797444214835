import { createSelector } from '@ngrx/store';
import { getObjectKeys } from 'src/app/lib/key-extractor';
import { trimChar } from 'src/app/lib/trim-char';
import { CorrectionFieldType } from 'src/app/model/batch-correction/CorrectionFieldType';
import { ErrorHeader } from 'src/app/model/error-record/ErrorCall';
import { AppState } from '..';

/**
 * Maximum number of concurrent requests that are allowed towards the backend while batchcorrecting
 */
const maxConcurrent = 20;

const featureSelector = (state: AppState) => state.batchCorrection;

/**
 * Simple selectors. These simply return a slice of the state.
 */
export const selectCorrectionSteps = createSelector(featureSelector, state => state.correctionSteps);
export const selectErrors = createSelector(featureSelector, state => state.errors);
export const selectPreview = createSelector(featureSelector, state => state.preview);
export const selectResubmissionIsOngoing = createSelector(featureSelector, state => state.resubmissionIsOngoing);
export const selectCompletedCorrections = createSelector(featureSelector, state => state.completedCorrections);
export const selectFailedCorrections = createSelector(featureSelector, state => state.failedCorrections);

/**
 * More complex selectors.
 */
export const selectErrorIds = createSelector(selectErrors, errors => errors.map(e => e.id));

export const selectCorrectionFieldTypeValues = createSelector(
    selectErrors,
    errors => {
        if (errors.length === 0) return null;
        const pathParts = trimChar(errors[0]?.call.endpoint, '/').split('/');
        const fieldTypeValues: CorrectionFieldType = {
            headers: extractFromHeaders(errors[0]?.call.headers),
            payload: getObjectKeys(errors[0]?.payload?.payload),
            pathParts: pathParts.map((pathPart: string, index: number) => {
                return [index, `${index}: ${pathPart}`];
            })
        }
        return fieldTypeValues;
    }
);

/**
 * Resubmission specific selectors, used by the effects
 */
export const selectFirstBatchForResbmission = createSelector(
    featureSelector,
    state => {
        return state.pendingCorrections.slice(0, maxConcurrent);
    }
);

export const selectNextEntryForResubmission = createSelector(
    featureSelector,
    state => {
        if (state.pendingCorrections.length > 0) {
            return state.pendingCorrections[0];
        }
        return null;
    }
);

// For use in the GUI: How much is completed now, and how did it all go?
export const selectPercentCompleted = createSelector(
    featureSelector,
    state => {
        const completed = state.completedCorrections.length + state.failedCorrections.length;
        const total = state.preview.length;
        return total === 0 ? 0 : completed / total;
    }
);

function extractFromHeaders(headers: any[]) {
    let keys = [];
    for (let i: number = 0; i < headers.length; i++) {
        let val = headers[i];
        let key = val['name'];
        keys.push(key);
    }
    return keys;
}
