import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as PageTitleActions from '../../state/page-title/page-title.actions';
import * as MITActions from '../../state/missing-in-tracy/missing-in-tracy.actions';
import { Observable } from 'rxjs';
import { MITSearchRequest } from 'src/app/model/missing-in-tracy/MITSearchRequest';
import { MITInflowSearchResult, TopMITFactoryHittersSearchResult } from 'src/app/state/missing-in-tracy/missing-in-tracy.reducers';
import * as missingInTracySelectors from 'src/app/state/missing-in-tracy/missing-in-tracy.selectors';

@Component({
    selector: 'app-mit',
    templateUrl: './missing-in-tracy.component.html',
    styleUrls: ['./missing-in-tracy.component.scss']
})

export class MITComponent implements OnInit {

    MITInflowResult$: Observable<MITInflowSearchResult>;
    searchRequest$: Observable<MITSearchRequest>;
    topMITFactoryHittersResult$: Observable<TopMITFactoryHittersSearchResult>;

    constructor(private store: Store<AppState>) { }

    ngOnInit(): void {
        this.store.dispatch(new PageTitleActions.SetPageTitleAction({ pageTitle: 'Missing in Tracy' }));
        this.MITInflowResult$ = this.store.select(missingInTracySelectors.selectMITInflowResult);
        this.searchRequest$ = this.store.select(missingInTracySelectors.selectMITSearchRequest);
        this.topMITFactoryHittersResult$ = this.store.select(missingInTracySelectors.selectTopMITFactoryHittersResult);
    }

    performSearch(event: MITSearchRequest) {
        this.store.dispatch(new MITActions.SetSearchRequestsAction({ request: event }));
        this.store.dispatch(new MITActions.LoadMITInflowResultsAction({ loadCount: true }));
        this.store.dispatch(new MITActions.LoadTopMITFactoryHittersResultsAction({ loadCount: true }));
    }
}
