import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUriService } from './api-uri.service';
import { ErrorRecordSearchRequest } from '../model/error-search/ErrorRecordSearchRequest';
import { ArchiveErrorRequest } from '../model/error-record/ArchiveErrorRequest';
import { ErrorSearchResultRow } from '../model/error-search/ErrorSearchResultRow';
import { ErrorRecord } from '../model/error-record/ErrorRecord';
import { ErrorResubmissionRequest } from '../model/error-record/ErrorResubmissionRequest';
import { forEach } from 'lodash';
import { ErrorCountRequest } from '../model/error-search/ErrorCountRequest';
import { ErrorCount } from '../model/error-search/ErrorCount';
import DuplicateReportRequest from '../model/statistics/DuplicateReportRequest';
import DuplicateReportResultRow from '../model/statistics/DuplicateReportResultRow';
import { ExportExcelRequest } from '../model/error-download/ExportExcelRequest';
import { FileDownloaderService } from './file-downloader.service';
import { ErrorSummaryRequest } from '../model/error-summary/ErrorSummaryRequest';
import { ErrorSummaryResultRow } from '../model/error-summary/ErrorSummaryResultRow';
import { EnumeratedValue } from '../model/lookup/EnumeratedValue';
import MostCommonErrorReportRequest from '../model/statistics/MostCommonErrorReportRequest';
import MostCommonErrorReportResultRow from '../model/statistics/MostCommonErrorReportResultRow';
import InvalidSerialNumberSearchRequest from '../model/algorithm-assisted/invalid-serial-number/InvalidSerialNumberSearchRequest';
import InvalidSerialNumberSearchResultRow from '../model/algorithm-assisted/invalid-serial-number/InvalidSerialNumberSearchResultRow';
import InvalidSerialNumberCountRequest from '../model/algorithm-assisted/invalid-serial-number/InvalidSerialNumberCountRequest';
import ErrorCategoryCount from '../model/algorithm-assisted/invalid-serial-number/ErrorCategoryCount';
import { LegacyErrorSearchRequest } from '../model/legacy-errors/LegacyErrorSearchRequest';
import { LegacyErrorSearchResultRow } from '../model/legacy-errors/LegacyErrorSearchResultRow';
import { LegacyError } from '../model/legacy-errors/LegacyError';
import { MultipleErrorRequest } from '../model/error-record/MultipleErrorRequest';
import { BatchCorrectionRequest } from '../model/batch-correction/BatchCorrectionRequest';
import { ArchiveAllSearchRequest } from '../model/error-record/ArchiveAllSearchRequest';
import { ExportExcelErrorInflowRequest } from '../model/dashboard/exportExcelErrorInflowRequest';
import { TopErrorInflowRequest } from '../model/dashboard/topErrorInflowRequest';
import { ErrorInflowSearchRequest } from '../model/dashboard/errorInflowSearchRequest';
import { ErrorInflowResultRow } from '../model/dashboard/errorInflowResultRow';
import { CheckCookieResult } from '../model/CheckCookieResult';
import { WebUserRenewedTokenResponse } from '../model/WebUserRenewedTokenResponse';
import { TopFactoryHittersResultRow } from '../model/dashboard/topFactoryHittersResultRow';
import { TopFactoryHittersRequest } from '../model/dashboard/topFactoryHittersRequest';
import { TopErrorInflowResultRow } from '../model/dashboard/topErrorInflowResultRow';
import { ExportExcelTopErrorsRequest } from '../model/dashboard/exportExcelTopErrorsRequest';
import { ExportExcelTopFactoryHittersRequest } from '../model/dashboard/exportExcelTopFactoryHittersRequest';
import { MITInflowSearchRequest } from '../model/missing-in-tracy/MITInflowSearchRequest';
import { MITInflowResultRow } from '../model/missing-in-tracy/MITInflowResultRow';
import { MITCount } from '../model/missing-in-tracy/MITCount';
import { TopMITFactoryHittersSearchRequest } from '../model/missing-in-tracy/topMITFactoryHittersSearchRequest';
import { TopMITFactoryHittersResultRow } from '../model/missing-in-tracy/topMITFactoryHittersResultRow';

export interface InteractiveUserAuthorizationResults {
    username: string;
    jwtToken: string;
    roles: Array<string>;
}

const postJsonOptions = {
    headers: {
        "Content-Type": "application/json"
    }
}

@Injectable({
    providedIn: 'root',
})

export class ApiDataService {
    constructor(
        private http: HttpClient,
        private urlServices: ApiUriService,
        private fileDownloaderService: FileDownloaderService) {
    }

    private objectToParams(obj: any): HttpParams {
        let params = new HttpParams();
        forEach(obj, (value: any, key: string) => {
            if (value !== null && value !== '') {
                if (Array.isArray(value)) {
                    value.forEach(element => {
                        params = params.append(key, element);
                    });
                } else {
                    params = params.append(key, value);
                }
            }
        });
        return params;
    }

    archiveError(archiveErrorRequest: ArchiveErrorRequest) {
        return this.http.post(this.urlServices.archiveErrorUrl, archiveErrorRequest, { ...postJsonOptions });
    }

    archiveAllSelectedErrors(archiveAllSearchRequest: ArchiveAllSearchRequest) {
        return this.http.post(this.urlServices.archiveAllErrorsUrl, archiveAllSearchRequest, { ...postJsonOptions });
    }

    unarchiveError(id: number) {
        return this.http.post(this.urlServices.unarchiveErrorUrl(id), '', { ...postJsonOptions });
    }

    // Error records
    getErrorRecord(id: number): Observable<ErrorRecord> {
        return this.http.get<ErrorRecord>(this.urlServices.getErrorRecordUrl + id);
    }

    getErrorRecords(request: MultipleErrorRequest): Observable<ErrorRecord[]> {
        const params = this.objectToParams(request);
        return this.http.get<ErrorRecord[]>(this.urlServices.getMultipleErrorRecordsUrl, { params });
    }

    searchErrorRecords(request: ErrorRecordSearchRequest): Observable<ErrorSearchResultRow[]> {
        const params = this.objectToParams(request);
        return this.http.get<ErrorSearchResultRow[]>(this.urlServices.searchErrorRecordUrl, { params });
    }

    errorCount(request: ErrorCountRequest): Observable<ErrorCount> {
        const params = this.objectToParams(request);
        return this.http.get<ErrorCount>(this.urlServices.errorCountUrl, { params });
    }

    searchErrorSummary(request: ErrorSummaryRequest): Observable<ErrorSummaryResultRow[]> {
        const params = this.objectToParams(request);
        return this.http.get<ErrorSummaryResultRow[]>(this.urlServices.errorSummaryUrl, { params });
    }

    exportErrorsToExcel(exportRequest: ExportExcelRequest): Observable<any> {
        return from(this.fileDownloaderService.downloadStream("tect-errors.csv", this.urlServices.exportToExcelUrl, exportRequest));
    }

    resubmitError(id: number, request: ErrorResubmissionRequest) {
        return this.http.post(this.urlServices.resubmitErrorUrl(id), request);
    }

    // Statistics
    getDuplicateReport(request: DuplicateReportRequest) {
        const params = this.objectToParams(request);
        return this.http.get<DuplicateReportResultRow[]>(this.urlServices.duplicateReportUrl, { params });
    }

    downloadDuplicateReport(request: DuplicateReportRequest) {
        return this.fileDownloaderService.downloadStream("duplicates.csv", this.urlServices.ddownloadDuplicateReportUrl, request);
    }

    getMostCommonErrorReport(request: MostCommonErrorReportRequest) {
        const params = this.objectToParams(request);
        return this.http.get<MostCommonErrorReportResultRow[]>(this.urlServices.mostCommonErrorReportUrl, { params });
    }

    downloadMostCommonErrorReport(request: MostCommonErrorReportRequest) {
        return this.fileDownloaderService.downloadStream("most-common-errors.csv", this.urlServices.downloadCommonErrorReportUrl, request);
    }

    // Algorithm Assisted Correction
    searchInvalidSerialNumber(request: InvalidSerialNumberSearchRequest): Observable<InvalidSerialNumberSearchResultRow[]> {
        const params = this.objectToParams(request);
        return this.http.get<InvalidSerialNumberSearchResultRow[]>(this.urlServices.searchInvalidSerialNumberUrl, { params });
    }

    countInvalidSerialNumber(request: InvalidSerialNumberCountRequest): Observable<ErrorCategoryCount> {
        const params = this.objectToParams(request);
        return this.http.get<ErrorCategoryCount>(this.urlServices.countInvalidSerialNumberUrl, { params });
    }


    // Batch Correction of errors
    generateBatchCorrectionPreview(request: BatchCorrectionRequest): Observable<ErrorRecord[]> {
        return this.http.post<ErrorRecord[]>(this.urlServices.batchCorrectionPreviewUrl, request, { ...postJsonOptions });
    }

    // Legacy errors
    searchLegacyErrors(request: LegacyErrorSearchRequest): Observable<LegacyErrorSearchResultRow[]> {
        const params = this.objectToParams(request);
        return this.http.get<LegacyErrorSearchResultRow[]>(this.urlServices.searchLegacyErrorsUrl, { params });
    }

    getLegacyError(id: number): Observable<LegacyError> {
        return this.http.get<LegacyError>(this.urlServices.getLegacyErrorUrl + id);
    }

    sendEmail(id: number) {
        return this.http.post(this.urlServices.preformattedFeedbackUrl(id), '', { ...postJsonOptions });
    }

    lookupErrorCategories(): Observable<EnumeratedValue<string>[]> {
        return this.http.get<EnumeratedValue<string>[]>(this.urlServices.errorCategoriesUrl);
    }

    lookupErrorStatus(): Observable<EnumeratedValue<string>[]> {
        return this.http.get<EnumeratedValue<string>[]>(this.urlServices.errorStatusesUrl);
    }

    lookupEventCodes(): Observable<EnumeratedValue<string>[]> {
        return this.http.get<EnumeratedValue<string>[]>(this.urlServices.eventCodesUrl);
    }

    lookupDateGroupingOptions(): Observable<EnumeratedValue<string>[]> {
        return this.http.get<EnumeratedValue<string>[]>(this.urlServices.dateGroupingOptionsUrl);
    }

    //Dashboard
    searchDashboardTopErrors(request: TopErrorInflowRequest): Observable<TopErrorInflowResultRow[]> {
        const params = this.objectToParams(request);
        return this.http.get<TopErrorInflowResultRow[]>(this.urlServices.dashboardTopErrorsUrl, { params });
    }

    topErrorCount(request: TopErrorInflowRequest): Observable<ErrorCount> {
        const params = this.objectToParams(request);
        return this.http.get<ErrorCount>(this.urlServices.topErrorsCountUrl, { params });
    }

    exportDashboardTopErrorsToExcel(exportRequest: ExportExcelTopErrorsRequest): Observable<any> {
        return from(this.fileDownloaderService.downloadStream("tect-dashboard-top-errors.csv", this.urlServices.dashboardExportExcelTopErrorsUrl, exportRequest));
    }

    exportDashboardErrorInflowToExcel(exportRequest: ExportExcelErrorInflowRequest): Observable<any> {
        return from(this.fileDownloaderService.downloadStream("tect-dashboard-error-inflow.csv", this.urlServices.dashboardExportExcelTopErrorsUrl, exportRequest));
    }
    searchDashboardErrorInflow(request: ErrorInflowSearchRequest): Observable<ErrorInflowResultRow[]> {
        const params = this.objectToParams(request);
        return this.http.get<ErrorInflowResultRow[]>(this.urlServices.dashboardErrorInflowUrl, { params });
    }

    errorInflowCount(request: ErrorInflowSearchRequest): Observable<ErrorCount> {
        const params = this.objectToParams(request);
        return this.http.get<ErrorCount>(this.urlServices.errorInflowCountUrl, { params });
    }

    searchDashboardTopFactoryHitters(request: TopFactoryHittersRequest): Observable<TopFactoryHittersResultRow[]> {
        const params = this.objectToParams(request);
        return this.http.get<TopFactoryHittersResultRow[]>(this.urlServices.dashboardTopFactoryHittersUrl, { params });
    }

    topFactoryHittersCount(request: TopFactoryHittersRequest): Observable<ErrorCount> {
        const params = this.objectToParams(request);
        return this.http.get<ErrorCount>(this.urlServices.topFactoryHittersCountUrl, { params });
    }

    exportDashboardTopFactoryHittersToExcel(exportRequest: ExportExcelTopFactoryHittersRequest): Observable<any> {
        return from(this.fileDownloaderService.downloadStream("tect-dashboard-top-factory-hitters.csv", this.urlServices.dashboardExportExcelTopFactoryHittersUrl, exportRequest));
    }

    lookupPolicies(): Observable<EnumeratedValue<string>[]> {
        return this.http.get<EnumeratedValue<string>[]>(this.urlServices.policiesUrl);
    }

    checkCookie(): Promise<CheckCookieResult> {
        return new Promise<CheckCookieResult>((resolve, reject) => {
            this.http.post<CheckCookieResult>(this.urlServices.checkCookieUrl, null)
                .subscribe(result => resolve(result), error => reject(error));
        })
    }

    renewToken(): Promise<WebUserRenewedTokenResponse> {
        console.log("renewToken: ", this.urlServices.renewCookieUrl);
        return new Promise<WebUserRenewedTokenResponse>((resolve, reject) => {
            this.http.post<WebUserRenewedTokenResponse>(this.urlServices.renewCookieUrl, null)
                .subscribe(result => resolve(result), error => reject(error));
        })
    }

    loadConfigFromBackend(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.http.get<any>(this.urlServices.loadConfigFromBackendUrl)
                .subscribe(result => resolve(result), error => reject(error));
        });
    }

    //Missing in Tracy

    searchMITInflow(request: MITInflowSearchRequest): Observable<MITInflowResultRow[]> {
        const params = this.objectToParams(request);
        return this.http.get<MITInflowResultRow[]>(this.urlServices.MITInflowUrl, { params });
    }

    MITInflowCount(request: MITInflowSearchRequest): Observable<MITCount> {
        const params = this.objectToParams(request);
        return this.http.get<MITCount>(this.urlServices.MITInflowCountUrl, { params });
    }

    searchTopMITFactoryHitters(request: TopMITFactoryHittersSearchRequest): Observable<TopMITFactoryHittersResultRow[]> {
        const params = this.objectToParams(request);
        return this.http.get<TopMITFactoryHittersResultRow[]>(this.urlServices.TopMITFactoryHittersUrl, { params });
    }

    topMITFactoryHittersCount(request: TopMITFactoryHittersSearchRequest): Observable<MITCount> {
        const params = this.objectToParams(request);
        return this.http.get<MITCount>(this.urlServices.TopMITFactoryHittersCountUrl, { params });
    }
}


