import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/service/login.service';
import { Policies } from 'src/constants';

@Component({
    selector: 'app-side-nav-menu',
    templateUrl: './side-nav-menu.component.html',
    styleUrls: ['./side-nav-menu.component.scss']
})
export class SideNavMenuComponent implements OnInit {

    public errorCode: string;

    hidedownarrow = false;

    constructor(private loginService: LoginService) { }

    ngOnInit() {
    }

    get canDoAlgorithmAssistedCorrection(): boolean {
        return this.loginService.acceptedByPolicy(Policies.CanDoAlgorithmAssistedCorrection);
    }

    get canReadStatistics(): boolean {
        return this.loginService.acceptedByPolicy(Policies.CanReadStatistics);
    }

    get canReadAllErrors(): boolean {
        return this.loginService.acceptedByPolicy(Policies.CanReadAllErrors);
    }

    get canReadErrorsInOwnUnits(): boolean {
        return this.loginService.acceptedByPolicy(Policies.CanReadErrorsInOwnUnits);
    }

    get canReadLegacyErrors(): boolean {
        return this.loginService.acceptedByPolicy(Policies.CanReadLegacyErrors);
    }
}
