import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { mergeMap, withLatestFrom, map, catchError } from 'rxjs/operators';
import { ErrorCount } from 'src/app/model/error-search/ErrorCount';
import { ApiDataService } from 'src/app/service/api-data.service';
import { AppState } from '..';
import { ErrorNotificationAction } from '../notification/notification.actions';
import * as BatchCorrectionActions from '../batch-correction/batch-correction.actions';
import * as NavigationActions from '../navigation/navigation.actions';
import {
  LoadResultsAction,
  ErrorDetailsActionTypes,
  LoadResultsSuccessAction,
  ArchiveAllErrorsAction,
  ArchiveAllErrorsSuccessAction,
  ArchiveErrorsAction,
  ArchiveErrorsSuccessAction,
  ExportToExcelSuccessAction,
  UpdateErrorAction,
  ExportToExcelAction,
} from './error-details.actions';
import {
  selectErrorDetailsRequest,
  selectErrorDetailsCountRequest,
  selectArchiveAllErrorsRequest,
  selectArchiveRequest,
  selectExportToExcelRequest,
  selectErrorDetailsSelectedIds
} from './error-details.selectors';
import { LoadErrorAction } from '../error-update/error-update.actions';


@Injectable()
export class ErrorDetailsEffects {

  /**
   * When a LoadResultsAction is dispatched, call the API to get data from backend
   */
  @Effect()
  loadResults$ = this.actions$
    .pipe(
      ofType<LoadResultsAction>(ErrorDetailsActionTypes.LoadResults),
      withLatestFrom(this.store$),
      mergeMap(([action, storeState]) => {
        return forkJoin({
          searchResult: this.apiDataService.searchErrorRecords(selectErrorDetailsRequest(storeState)),
          countResult: action.payload.loadCount ?
            this.apiDataService.errorCount(selectErrorDetailsCountRequest(storeState)) :
            of({ count: null } as ErrorCount)
        })
          .pipe(
            map(({ searchResult, countResult }) => {
              return new LoadResultsSuccessAction({ rows: searchResult, count: countResult.count });
            }),
            catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to load search: ${error.message}` })))
          );
      }),

    );

  /**
   * Perform HTTP-call to archive all errors when the correct action is received
   */
  @Effect()
  archiveAllErrors$ = this.actions$
    .pipe(
      ofType<ArchiveAllErrorsAction>(ErrorDetailsActionTypes.ArchiveAllErrors),
      withLatestFrom(this.store$),
      mergeMap(([, storeState]) => this.apiDataService.archiveAllSelectedErrors(selectArchiveAllErrorsRequest(storeState))
        .pipe(
          map(() => {
            return new ArchiveAllErrorsSuccessAction();
          }),
          catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to archive errors: ${error.message}` })))
        )
      ),
    );

  /**
   * Perform HTTP-call to archive selected errors when the correct action is received
   */
  @Effect()
  archiveErrors$ = this.actions$
    .pipe(
      ofType<ArchiveErrorsAction>(ErrorDetailsActionTypes.ArchiveErrors),
      withLatestFrom(this.store$),
      mergeMap(([, storeState]) => this.apiDataService.archiveError(selectArchiveRequest(storeState))
        .pipe(
          map(() => {
            return new ArchiveErrorsSuccessAction();
          }),
          catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to archive errors: ${error.message}` })))
        ),
      ),
    );

  /**
   * Fire an effect to perform a full reload (with new count). This can trigger from several types of actions.
   */
  @Effect()
  archiveErrorsSuccess$ = this.actions$
    .pipe(
      ofType(
        ErrorDetailsActionTypes.ArchiveErrorsSuccess,
        ErrorDetailsActionTypes.ArchiveAllErrorsSuccess),
      mergeMap(() => of(new LoadResultsAction({ loadCount: true })))
    );

  /**
   * Start an excel export when the correct action is received. No further action needs
   * to be taken, the apiDataService handles all side effects of this one.
   */
  @Effect()
  excelExport$ = this.actions$
    .pipe(
      ofType<ExportToExcelAction>(ErrorDetailsActionTypes.ExportToExcel),
      withLatestFrom(this.store$),
      mergeMap(([, storeState]) =>
        this.apiDataService.exportErrorsToExcel(selectExportToExcelRequest(storeState))
          .pipe(
            map(() => new ExportToExcelSuccessAction()),
            catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Unable to export error records. HTTP response code: ${error.status}` })))
          )
      ),
    );

  @Effect({ dispatch: true })
  startBatchCorrection$ = this.actions$
    .pipe(
      ofType(ErrorDetailsActionTypes.StartBatchCorrection),
      withLatestFrom(this.store$),
      mergeMap(([, storeState]) => {
        const ids = selectErrorDetailsSelectedIds(storeState);
        return [
          new BatchCorrectionActions.LoadErrorsForBatchCorrectionAction({ ids }),
          new NavigationActions.NavigateToPageAction({ route: ['error-handling', 'batch-correct'] }),
        ];
      })
    );

  @Effect()
  updateError$ = this.actions$
    .pipe(
      ofType<UpdateErrorAction>(ErrorDetailsActionTypes.UpdateError),
      mergeMap(action => [
        new LoadErrorAction({ id: action.payload.id })
      ])
    )

  /**
   * Constructor, with dependency injection
   */
  constructor(
    private actions$: Actions,
    private apiDataService: ApiDataService,
    private store$: Store<AppState>,) { }
}
