import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from '../../service/lookup.service';
import { ErrorInflowSearchResult, TopErrorsSearchResult } from '../../state/dashboard/dashboard.reducers';
import { MITInflowSearchResult } from '../../state/missing-in-tracy/missing-in-tracy.reducers';

@Pipe({
    name: 'MITInflowResultLabels'
})

export class MITInflowResultLabelsPipe implements PipeTransform {

    constructor() { }

    transform(result: MITInflowSearchResult, ...args: any): any[] {
        let barChartLabels = [];
        for (let row of result.rows) {
            barChartLabels.push(row.period)
        }
        return barChartLabels
    }
}
