import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as NavigationActions from 'src/app/state/navigation/navigation.actions';
import * as MITActions from '../../../state/missing-in-tracy/missing-in-tracy.actions';
import { TopMITFactoryHittersSearchResult } from 'src/app/state/missing-in-tracy/missing-in-tracy.reducers';

@Component({
    selector: 'app-MIT-top-factory-hitters',
    templateUrl: './missing-in-tracy-top-factory-hitters.component.html',
    styleUrls: ['./missing-in-tracy-top-factory-hitters.component.scss']
})
export class TopMITFactoryHittersComponent implements OnInit {

    displayedColumns: string[] =
        [
            'sendingUnit',
            'mits',
        ];

    @Input() topMITFactoryHittersResult: TopMITFactoryHittersSearchResult;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit(): void {
    }
}
