import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from '../service/lookup.service';

@Pipe({
  name: 'errorCategory'
})

export class ErrorCategoryPipe implements PipeTransform {

  constructor(private lookupService: LookupService) { }

  transform(value: string | string[], ...args: unknown[]): string {
    if (Array.isArray(value)) {
      return value.map(s => this.lookupService.translateErrorCategory(s)).join(', ');
    }
    return this.lookupService.translateErrorCategory(value);
  }
}

