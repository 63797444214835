<div class="div-panel">
    <div class="flex-container">
        <div class="flex-section">
            <app-display-field header="Serial Number" [value]="legacyError.serNo"></app-display-field>
            <app-display-field header="Record Type" [value]="legacyError.recTp"></app-display-field>
            <app-display-field header="Sending Unit" [value]="legacyError.unitSnd"></app-display-field>
            <app-display-field header="Error Code" [value]="legacyError.msgId"></app-display-field>
            <app-display-field header="Record Contents" [value]="legacyError.errRec"></app-display-field>
        </div>

        <div class="flex-section">
            <app-display-field header="Created" [value]="legacyError.crStamp"></app-display-field>
            <app-display-field header="Created By" [value]="legacyError.crUser"></app-display-field>
            <app-display-field header="Last Changed" [value]="legacyError.chStamp"></app-display-field>
            <app-display-field header="Last Changed By" [value]="legacyError.chUser"></app-display-field>
        </div>
    </div>
</div>