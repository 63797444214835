/***
 * Proudly stolen and lightly modified from StackOverflow
 * https://stackoverflow.com/questions/26156292/trim-specific-character-from-a-string
 */
export function trimChar(input: string, charToRemove: string) {
    while (input.charAt(0) === charToRemove) {
        input = input.substring(1);
    }

    while (input.charAt(input.length - 1) === charToRemove) {
        input = input.substring(0, input.length - 1);
    }

    return input;
}