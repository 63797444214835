import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LegacyError } from 'src/app/model/legacy-errors/LegacyError';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as PageTitleActions from '../../state/page-title/page-title.actions';

@Component({
  selector: 'app-legacy-error-display',
  templateUrl: './legacy-error-display.component.html',
  styleUrls: ['./legacy-error-display.component.scss']
})
export class LegacyErrorDisplayComponent implements OnInit {

  public legacyError: LegacyError;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>
  ) {

  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.legacyError = data.legacyError as LegacyError;
    });
    this.store.dispatch(new PageTitleActions.SetPageTitleAction({ pageTitle: 'Legacy Errors' }));
  }

}
