<div *ngIf="topErrorsResult.rows.length > 0" style="overflow-y: auto; overflow-x: hidden; height: 65vh !important;">

    <button mat-raised-button type="button" style="margin:10px;color: #fff;background-color: #47bac1;"
        (click)="exportTopErrorsToExcel()">Export</button>
    <mat-chip color="primary" style="background-color: #e4e4e4; color: #fff;">
        <span color="primary" style=" font-size:14px !important;  border-radius: 50px; padding:10px !important;">Showing
            <strong>{{topErrorsResult.rows.length}}</strong> of <strong>{{topErrorsResult.count |
                number:'1.0'}}</strong>
            records</span>
    </mat-chip>

    <table mat-table [dataSource]="topErrorsResult.rows" matSort matSort class="mat-elevation-z8">

        <ng-container matColumnDef="errorCategory">
            <th mat-header-cell *matHeaderCellDef> Category </th>
            <td mat-cell *matCellDef="let element">
                {{ element.errorCategory | errorCategory }}
            </td>
        </ng-container>

        <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef> Inflow </th>
            <td mat-cell *matCellDef="let element">
                {{element.errors}}
            </td>
        </ng-container>

        <ng-container matColumnDef="remainingErrors">
            <th mat-header-cell *matHeaderCellDef> Remaining </th>
            <td mat-cell *matCellDef="let element">
                {{element.remainingErrors}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToDetails(row)"></tr>
    </table>
</div>