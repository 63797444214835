import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoggingService } from 'src/app/service/logging.service';
import { LoginService } from 'src/app/service/login.service';
import { AppState } from 'src/app/state';
import { selectPageTitle } from 'src/app/state/page-title/page-title.selectors';

@Component({
    selector: 'app-app-dashboard',
    templateUrl: './app-dashboard.component.html',
    styleUrls: ['./app-dashboard.component.scss']
})
export class AppDashboardComponent implements OnInit {
    totalerror: number;
    isAuthenticated: boolean;
    hideTotalErrorDisplay: boolean;
    username: string;

    pageTitle$: Observable<string>;

    constructor(
        private loginService: LoginService,
        private loggingService: LoggingService,
        private store: Store<AppState>) {

        this.username = loginService.currentIdentity?.username;
        this.loginService.observeLoggedIn(identity => {
            this.username = identity.username;
        });
    }

    ngOnInit() {
        this.totalerror = 0;
        this.isAuthenticated = !!this.loginService.currentIdentity;
        this.pageTitle$ = this.store.pipe(select(selectPageTitle));
    }

    logout() {
        this.loginService.logout();
    }
}
