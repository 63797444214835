import { CorrectionStep } from 'src/app/model/batch-correction/CorrectionStep';
import { ErrorRecord } from 'src/app/model/error-record/ErrorRecord';
import { FailedResubmission } from 'src/app/model/error-record/FailedResubmission';
import { BatchCorrectionActions, BatchCorrectionActionTypes } from './batch-correction.actions';

export interface BatchCorrectionState {
    correctionSteps: CorrectionStep[] | null;
    errors: ErrorRecord[] | null;
    preview: ErrorRecord[] | null;
    resubmissionIsOngoing: boolean;
    pendingCorrections: ErrorRecord[];
    ongoingCorrections: ErrorRecord[];
    completedCorrections: ErrorRecord[];
    failedCorrections: FailedResubmission[];
}

const initialBatchCorrectionState: BatchCorrectionState = {
    correctionSteps: [],
    errors: [],
    preview: [],
    resubmissionIsOngoing: false,
    pendingCorrections: [],
    ongoingCorrections: [],
    completedCorrections: [],
    failedCorrections: [],
};

export function batchCorrectionReducer(
    state: BatchCorrectionState = initialBatchCorrectionState,
    action: BatchCorrectionActions): BatchCorrectionState {
    switch (action.type) {
        case BatchCorrectionActionTypes.AddStep:
            return {
                ...state,
                correctionSteps: [...state.correctionSteps, action.payload.step]
            };
        case BatchCorrectionActionTypes.RemoveStep:
            return {
                ...state,
                correctionSteps: state.correctionSteps.filter((c) => c !== action.payload.step)
            };
        case BatchCorrectionActionTypes.LoadErrorsForBatchCorrectionSuccess:
            return {
                ...state,
                errors: action.payload.errors
            };
        case BatchCorrectionActionTypes.GeneratePreviewSuccess:
            return {
                ...state,
                preview: action.payload.preview
            };
        case BatchCorrectionActionTypes.StartBatchResubmission:
            return {
                ...state,
                resubmissionIsOngoing: true,
                pendingCorrections: [...state.preview],
                ongoingCorrections: [],
                completedCorrections: [],
                failedCorrections: []
            };
        case BatchCorrectionActionTypes.ResubmitError: {
            const errorId = action.payload.correction.id;
            return {
                ...state,
                pendingCorrections: state.pendingCorrections.filter(c => c.id !== errorId),
                ongoingCorrections: [...state.ongoingCorrections, action.payload.correction]
            };
        }
        case BatchCorrectionActionTypes.ResubmitErrorSuccess: {
            const errorId = action.payload.correction.id;
            const ongoingCorrections = state.ongoingCorrections.filter(c => c.id !== errorId);
            const completedCorrections = [...state.completedCorrections, action.payload.correction];
            const resubmissionIsOngoing = (ongoingCorrections.length + state.pendingCorrections.length) > 0;

            return {
                ...state,
                ongoingCorrections,
                completedCorrections,
                resubmissionIsOngoing
            };
        }
        case BatchCorrectionActionTypes.ResubmitErrorFailed: {
            const errorId = action.payload.resubmission.correction.id;
            const ongoingCorrections = state.ongoingCorrections.filter(c => c.id !== errorId);
            const failedCorrections = [...state.failedCorrections, action.payload.resubmission];
            const resubmissionIsOngoing = (ongoingCorrections.length + state.pendingCorrections.length) > 0;

            return {
                ...state,
                ongoingCorrections,
                failedCorrections,
                resubmissionIsOngoing
            };
        }
        case BatchCorrectionActionTypes.BatchResubmissionCompleted:
            // Nothing to do here. The flag "resubmissionIsOngoing" is handled in the other cases.
            // After all, it needs to wait for all pending requests to be completed.
            return state;
        default:
            return state;
    }
}
