import { Inject, Injectable } from '@angular/core';
import { DatePeriod } from '../model/DatePeriod';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Identity } from './login.service';

const DATE_PERIOD_KEY = 'DATE_PERIOD';
const ERROR_MESSAGE = "ERROR_MESSAGE";
const CURRENT_IDENTITY = "CURRENT_IDENTITY";

@Injectable({
    providedIn: 'root',
})

export class TECTStateService {
    constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {
    }

    setCurrentIdentity(identity: Identity) {
        this.storage.set(CURRENT_IDENTITY, identity);
    }

    getCurrentIdentity(): Identity {
        return this.storage.get(CURRENT_IDENTITY);
    }

    setErrorMessage(errorMessage: string | null): void {
        if (errorMessage === null)
            this.storage.remove(ERROR_MESSAGE);
        else
            this.storage.set(ERROR_MESSAGE, errorMessage);
    }

    takeErrorMessage(): string | undefined {
        const errorMessage = this.storage.get(ERROR_MESSAGE);
        this.storage.remove(ERROR_MESSAGE);
        return errorMessage;
    }

    setDatePeriod(datePeriod: DatePeriod) {
        this.storage.set(DATE_PERIOD_KEY, datePeriod);
    }

    getDatePeriod(): DatePeriod {
        return this.storage.get(DATE_PERIOD_KEY);
    }
}
