<div *ngIf="topMITFactoryHittersResult.rows.length > 0"
    style="overflow-y: auto; overflow-x: hidden; height: 65vh !important;">
    <mat-chip color="primary" style="background-color: #e4e4e4; color: #fff;">
        <span color="primary" style=" font-size:14px !important;  border-radius: 50px; padding:10px !important;">Showing
            <strong>{{topMITFactoryHittersResult.rows.length}}</strong> of <strong>{{topMITFactoryHittersResult.count |
                number:'1.0'}}</strong>
            records</span>
    </mat-chip>

    <table mat-table [dataSource]="topMITFactoryHittersResult.rows" matSort matSort class="mat-elevation-z8">

        <ng-container matColumnDef="sendingUnit">
            <th mat-header-cell *matHeaderCellDef> Sending Unit </th>
            <td mat-cell *matCellDef="let element">
                {{ element.sendingUnit }}
            </td>
        </ng-container>

        <ng-container matColumnDef="mits">
            <th mat-header-cell *matHeaderCellDef> MITs </th>
            <td mat-cell *matCellDef="let element">
                {{element.mit}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


</div>
