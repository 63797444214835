import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { ExportExcelErrorInflowRequest } from 'src/app/model/dashboard/exportExcelErrorInflowRequest';
import { AppState } from 'src/app/state';
import * as DashboardActions from '../../../state/dashboard/dashboard.actions';
import * as NavigationActions from 'src/app/state/navigation/navigation.actions';
import { ErrorInflowSearchResult } from 'src/app/state/dashboard/dashboard.reducers';
import { ErrorInflowResultRow } from 'src/app/model/dashboard/errorInflowResultRow';

@Component({
    selector: 'app-dashboard-error-inflow',
    templateUrl: './dashboard-error-inflow.component.html',
    styleUrls: ['./dashboard-error-inflow.component.scss']
})
export class DashboardErrorInflowComponent {

    displayedColumns: string[] =
        [
            'period',
            'errors',
            'remainingErrors',
            'percentDone',
        ];

    @Input() errorInflowResult: ErrorInflowSearchResult;
    @Output() exportErrorInflow = new EventEmitter<ExportExcelErrorInflowRequest>();
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private store: Store<AppState>) { }

    exportErrorInflowToExcel() {
        this.exportErrorInflow.emit();
    }

    navigateToDetails(row: ErrorInflowResultRow) {
        this.store.dispatch(new DashboardActions.LoadDetailsErrorInflowAction({ row }));
        this.store.dispatch(new NavigationActions.NavigateToPageAction({ route: ['error-handling'] }));
    }
}
