import { Action } from '@ngrx/store';
import { ErrorRecord } from 'src/app/model/error-record/ErrorRecord';
import { FailedResubmission } from 'src/app/model/error-record/FailedResubmission';
import { CorrectionStep } from '../../model/batch-correction/CorrectionStep';

export enum BatchCorrectionActionTypes {
    AddStep = '[Batch Correct] Add Step',
    RemoveStep = '[Batch Correct] Remove Step',
    LoadErrorsForBatchCorrection = '[Batch Correct] Load Errors',
    LoadErrorsForBatchCorrectionSuccess = '[Batch Correct] Load Errors Success',
    GeneratePreview = '[Batch Correct] Generate Preview',
    GeneratePreviewSuccess = '[Batch Correct] Generate Preview Success',

    // Batch resubmission
    StartBatchResubmission = '[Batch Correct] Start Batch Resubmission',
    ResubmitError = '[Batch Correct] Resubmit Error',
    ResubmitErrorSuccess = '[Batch Correct] Resubmit Error Success',
    ResubmitErrorFailed = '[Batch Correct] Resubmit Error Failed',
    BatchResubmissionCompleted = '[Batch Correct] Batch Resubmission Completed',
}

// Step handling
export class AddStepAction implements Action {
    readonly type = BatchCorrectionActionTypes.AddStep;

    constructor(readonly payload: { step: CorrectionStep }) { }
}

export class RemoveStepAction implements Action {
    readonly type = BatchCorrectionActionTypes.RemoveStep;

    constructor(readonly payload: { step: CorrectionStep }) { }
}


// Loading from backend
export class LoadErrorsForBatchCorrectionAction implements Action {
    readonly type = BatchCorrectionActionTypes.LoadErrorsForBatchCorrection;

    constructor(readonly payload: { ids: number[] }) { }
}

export class LoadErrorsForBatchCorrectionSuccessAction implements Action {
    readonly type = BatchCorrectionActionTypes.LoadErrorsForBatchCorrectionSuccess;

    constructor(readonly payload: { errors: ErrorRecord[] }) { }
}

export class GeneratePreviewAction implements Action {
    readonly type = BatchCorrectionActionTypes.GeneratePreview;
}

export class GeneratePreviewSuccessAction implements Action {
    readonly type = BatchCorrectionActionTypes.GeneratePreviewSuccess;

    constructor(readonly payload: { preview: ErrorRecord[] }) { }
}


// Resubmission
export class StartBatchResubmissionAction implements Action {
    readonly type = BatchCorrectionActionTypes.StartBatchResubmission;
}

export class ResubmitErrorAction implements Action {
    readonly type = BatchCorrectionActionTypes.ResubmitError;

    constructor(readonly payload: { correction: ErrorRecord }) { }
}


export class ResubmitErrorSuccessAction implements Action {
    readonly type = BatchCorrectionActionTypes.ResubmitErrorSuccess;

    constructor(readonly payload: { correction: ErrorRecord }) { }
}

export class ResubmitErrorFailedAction implements Action {
    readonly type = BatchCorrectionActionTypes.ResubmitErrorFailed;

    constructor(readonly payload: { resubmission: FailedResubmission }) { }
}

export class BatchResubmissionCompletedAction implements Action {
    readonly type = BatchCorrectionActionTypes.BatchResubmissionCompleted;
}

export type BatchCorrectionActions =
    AddStepAction |
    RemoveStepAction |
    LoadErrorsForBatchCorrectionAction |
    LoadErrorsForBatchCorrectionSuccessAction |
    GeneratePreviewAction |
    GeneratePreviewSuccessAction |
    StartBatchResubmissionAction |
    ResubmitErrorAction |
    ResubmitErrorSuccessAction |
    ResubmitErrorFailedAction |
    BatchResubmissionCompletedAction;
