import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ErrorDiscovery, ErrorRecord } from 'src/app/model/error-record/ErrorRecord';
import { ErrorCategoryPipe } from 'src/app/pipe/error-category.pipe';
import { Policies } from 'src/constants';
import { uniq, some } from 'lodash';
import { ErrorHeader } from 'src/app/model/error-record/ErrorCall';
import { FormlyGeneratorService } from 'src/app/service/formly-generator.service';
import { LoginService } from 'src/app/service/login.service';

const PAYLOAD_NAME = 'payload';

@Component({
  selector: 'app-error-update-form',
  templateUrl: './error-update-form.component.html',
  styleUrls: ['./error-update-form.component.scss']
})

export class ErrorUpdateFormComponent {

  @Input() set initialData(value: ErrorRecord) {
    this.data = value;
    this.initWithErrorRecord(this.data);
  }
  @Input() errorIsArchived: boolean;
  @Output() updatedError: EventEmitter<ErrorRecord> = new EventEmitter<ErrorRecord>();
  @Output() archiveError: EventEmitter<ErrorRecord> = new EventEmitter<ErrorRecord>();
  @Output() unarchiveError: EventEmitter<ErrorRecord> = new EventEmitter<ErrorRecord>();
  @Output() sendEmail: EventEmitter<ErrorRecord> = new EventEmitter<ErrorRecord>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  data: ErrorRecord;
  // Discoveries. First all of them, and then split up into different
  // types
  discoveries: ErrorDiscovery[];
  headerDiscoveries: ErrorDiscovery[];
  pathDiscoveries: ErrorDiscovery[];
  payloadDiscoveries: ErrorDiscovery[];

  // Error categories, broken out of their discoveries
  errorCategories: string[];

  form = new FormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[];

  constructor(
    private generator: FormlyGeneratorService,
    private errorCategoryPipe: ErrorCategoryPipe,
    private loginService: LoginService,) { }

  ngAfterContentChecked() {
    // The validation errors in the form do not show until
    // the field is touched. We want to show the errors straight away,
    // so we touch all fields right form the start.
    this.form.markAllAsTouched();
  }

  /**
   * Creates the Formly-form, with all prefilled data
   * and validation errors from the ErrorRecord.
   */
  private initWithErrorRecord(data: ErrorRecord) {
    this.errorCategories = uniq(this.data.discoveries.map((d: ErrorDiscovery) => {
      return this.errorCategoryPipe.transform(d.errorCategory);
    })).join(', ');
    this.setDiscoveries(this.data.discoveries);

    // Create the Formly config for the payload. Then add the validators based on the discoveries.
    const payloadFields = this.generator.generateFormlyFields('', PAYLOAD_NAME, this.data.payload.payload);
    this.addValidatorsToPayload(payloadFields);

    this.fields = [
      this.createSubHeader('HTTP Call Details'),
      ...this.createCallFields(),
      this.createSubHeader('HTTP Headers'),
      ...this.headersToFormlyFields(this.data.call.headers),
      this.createSubHeader('Request Payload'),
      payloadFields,
    ];

    this.model = {
      headers: this.headersToFormlyModel(this.data.call.headers),
      payload: this.data.payload.payload,
      endpoint: this.data.call.endpoint,
      method: this.data.call.method
    };
  }

  private setDiscoveries(discoveries: ErrorDiscovery[]) {
    // Split the discoveries up into different categories,
    // Depending on what fields they refer to.
    this.discoveries = discoveries;
    this.headerDiscoveries = this.discoveries.filter(d => {
      return d.location?.headerName;
    });
    this.pathDiscoveries = this.discoveries.filter(d => {
      return d.location?.pathPart;
    });
    this.payloadDiscoveries = this.discoveries.filter(d => {
      return d.location?.payloadFieldPath;
    });
  }

  private addValidatorsToPayload(payloadFields: FormlyFieldConfig) {
    payloadFields.fieldGroup.forEach((field: FormlyFieldConfig) => {
      field.validators = {
        discovery: {
          expression: () => {
            // If there is a matching error discovery for this field (meaning there is an error).
            // Otherwise, return true (meaning there is no error).
            const discoveryExists = some(this.payloadDiscoveries, (d: ErrorDiscovery) =>
              `${PAYLOAD_NAME}.${d.location?.payloadFieldPath}` === field.key
            );

            return !discoveryExists;
          },
          message: (error, formlyField: FormlyFieldConfig) => {
            // Find the discoveries for this field. Then join them together into a comma-separated string.
            const discoveries = this.payloadDiscoveries.filter(d =>
              `${PAYLOAD_NAME}.${d.location?.payloadFieldPath}` === formlyField.key
            );

            return `Error from Tracy: ${discoveries.map(d => {
              return d.errorMessage ? `${d.errorMessage} (${d.errorCode})` : d.errorCode;
            }).join(', ')}`;
          },
        },
      };
    });
  }

  private createSubHeader(text: string): FormlyFieldConfig {
    return {
      className: 'section-label',
      template: `<p><strong>${text}</strong></p>`,
    };
  }

  private createCallFields(): FormlyFieldConfig[] {
    return [{
      key: 'endpoint',
      type: 'input',
      templateOptions: {
        label: 'Endpoint',
        placeholder: 'Endpoint',
        required: true,
      },
      validators: {
        discovery: {
          expression: () => {
            // If there are errors for any pathPart, return false (meaning there is an error).
            // Otherwise, return true (meaning there is no error).
            const discoveryExists = this.pathDiscoveries.length > 0;
            return !discoveryExists;
          },
          message: () => {
            // All path part discoveries need to be displayed together. There is, after all, but one path.
            // Split the path parts up into an array to be able to select the right one.
            const pathParts = this.data.call.endpoint.split('/').filter(part => part !== '');

            // Create a nice error message, one for each discovery
            const formattedDiscoveries = this.pathDiscoveries.map(d => {
              return `Error (part "${pathParts[d.location.pathPart]}"): ${d.errorCode}`;
            });
            return formattedDiscoveries.join('\n');
          },
        },
      },
    }, {
      key: 'method',
      type: 'input',
      templateOptions: {
        label: 'HTTP Method',
        placeholder: 'HTTP Method',
        required: true,
      }
    }];
  }

  private headersToFormlyModel(headers: ErrorHeader[]) {
    const model = [];

    headers.forEach(h => {
      model.push({
        headerName: h.name,
        headerValue: h.value
      });
    });
    return model;
  }

  private headersToFormlyFields(headers: ErrorHeader[]): FormlyFieldConfig[] {
    return headers.map((h: ErrorHeader): FormlyFieldConfig => {
      return {
        key: 'headers',
        type: 'repeat',
        templateOptions: {
          addText: 'Add header',
        },
        fieldArray: {
          fieldGroup: [
            {
              type: 'input',
              key: 'headerName',
              templateOptions: {
                label: 'Header Name',
                required: true
              }
            }, {
              type: 'input',
              key: 'headerValue',
              templateOptions: {
                label: 'Header Value',
                required: true
              },
              validators: {
                discovery: {
                  expression: () => {
                    // If there is a matching error discovery for this header, return false (meaning there is an error).
                    // Otherwise, return true (meaning there is no error).
                    const discoveryExists = some(this.headerDiscoveries, d => d.location?.headerName === h.name);
                    return !discoveryExists;
                  },
                  message: () => {
                    // Find the discoveries for this header. Then join them together into a comma-separated string.
                    const discoveries = this.headerDiscoveries.filter(d => d.location?.headerName === h.name);
                    return `Error from Tracy: ${discoveries.map(d => d.errorCode).join(', ')}`;
                  },
                },
              },
            },
          ]
        }
      };
    });
  }

  public async formlySubmit(model: any) {
    this.updatedError.emit(model);
  }

  sendEmailClicked() {
    this.sendEmail.emit();
  }

  archiveErrorClicked() {
    this.archiveError.emit();
  }

  unarchiveErrorClicked() {
    this.unarchiveError.emit();
  }

  cancelClicked() {
    this.cancel.emit();
  }

  get allowedToUpdate(): boolean {
    return this.loginService.acceptedByPolicy(Policies.CanUpdateErrors);
  }
}
