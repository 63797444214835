import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorSummaryRequest } from 'src/app/model/error-summary/ErrorSummaryRequest';
import { ErrorSummaryResultRow } from 'src/app/model/error-summary/ErrorSummaryResultRow';
import { MatSort } from '@angular/material/sort';
import { AppState } from 'src/app/state';
import { Store } from '@ngrx/store';
import * as ErrorSummaryActions from '../../state/error-summary/error-summary.actions';
import { Observable } from 'rxjs';
import { selectErrorSummaryRequest, selectErrorSummaryResult } from 'src/app/state/error-summary/error-summary.selectors';
import * as PageTitleActions from '../../state/page-title/page-title.actions';

@Component({
  selector: 'app-error-summary',
  templateUrl: './error-summary.component.html',
  styleUrls: ['./error-summary.component.scss']
})

export class ErrorSummaryComponent implements OnInit {

  pageSizeOptions: number[] = [20, 50, 100];

  // NgRx Observables from store
  searchRequest$: Observable<ErrorSummaryRequest>;
  searchResults$: Observable<ErrorSummaryResultRow[]>;

  displayedColumns: string[] =
    [
      'sendingUnit',
      'errorCategory',
      'remainingErrors'
    ];


  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private router: Router,
    private store: Store<AppState>) {
  }

  ngOnInit() {
    this.store.dispatch(new PageTitleActions.SetPageTitleAction({ pageTitle: 'Error Summary' }));

    // NgRx observables from store
    this.searchRequest$ = this.store.select(selectErrorSummaryRequest);
    this.searchResults$ = this.store.select(selectErrorSummaryResult);
  }

  performSearch(event: ErrorSummaryRequest) {
    this.store.dispatch(new ErrorSummaryActions.SetSearchRequestAction({ request: event }));
    this.store.dispatch(new ErrorSummaryActions.LoadResultsAction());
  }

  navigateToDetails(row: ErrorSummaryResultRow) {
    this.store.dispatch(new ErrorSummaryActions.LoadDetailsSearchAction({ row }));
    this.router.navigate(['error-handling']);
  }
}