<!----------------Action bar------------------------------>
<div class="div-panel sticky-top">
    <div class="row">
        <div class="col-md-6">
            <div class="input-group">
                <ng-container *ngTemplateOutlet="canReadAllErrors ? unrestricted : restricted">
                </ng-container>
                <ng-template #unrestricted>
                    <input class="form-control" [(ngModel)]="sendingUnit"
                           style="padding-left: 10px; border-radius: 0.25rem;" type="text" placeholder="Sending Unit"
                           aria-label="Search">
                </ng-template>
                <ng-template #restricted>
                    <mat-form-field>
                        <mat-select placeholder="Sending unit" [(value)]=sendingUnit>
                            <mat-option *ngFor="let unit of userUnits" [value]="unit">
                                {{ unit }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-template>
                <div class="input-group input-group-search-button" (click)=searchErrorSummary()>
                    <div class="input-group-text"><i class="fa fa-search"></i></div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <mat-form-field class="input-with-datepicker">
                <input readonly="true" (dateChange)="fromDateChange($event)" matInput [matDatepicker]="fromdate"
                       placeholder="Period from" [value]="fromDate" [max]="toDate || currentDate">
                <mat-datepicker-toggle matSuffix [for]="fromdate">
                </mat-datepicker-toggle>
                <mat-datepicker #fromdate></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-md-3">
            <mat-form-field class="input-with-datepicker">
                <input readonly="true" (dateChange)="toDateChange($event)" matInput [matDatepicker]="todate"
                       placeholder="Period until" [value]="toDate" [min]="fromDate" [max]="currentDate">
                <mat-datepicker-toggle matSuffix [for]="todate">
                </mat-datepicker-toggle>
                <mat-datepicker #todate></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
</div>
