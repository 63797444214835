import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { SpinnerLoaderService } from '../../service/spinner-loader.service';

@Component({
  selector: 'app-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss']
})
export class SpinnerLoaderComponent implements OnInit {
  // color = 'primary';
  mode = 'indeterminate';
  // value = 50;
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  constructor(private loaderService: SpinnerLoaderService) { }


  ngOnInit() {
  }
}
