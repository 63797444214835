import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IdleMonitorService } from '../service/idle-monitor-service';
import { LoginService } from '../service/login.service';
import { StartupService } from '../service/startup.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
    constructor(
        private startupService: StartupService,
        private router: Router,
        private loginService: LoginService,
        idleMonitorService: IdleMonitorService) {
        loginService.observeLoggedIn(() => {
            this.router.navigate(['/error-summary/']);
            idleMonitorService.start();
        });

        loginService.observeLoggedout(() => {
            idleMonitorService.stop();
            this.router.navigate(['']);
        });
    }

    ngOnInit() {
        this.startupService.setDatePeriodSearch();
        this.startupService.registerLoginObserver();
        this.loginService.setupAuthState();
    }
}
