<div *ngIf="MITInflowResult.rows.length > 0" style="overflow-y: auto; overflow-x: hidden; height: 65vh !important;">
    <mat-chip color="primary" style="background-color: #e4e4e4; color: #fff;">
        <span color="primary" style=" font-size:14px !important;  border-radius: 50px; padding:10px !important;">Showing
            <strong>{{MITInflowResult.rows.length}}</strong> of <strong>{{MITInflowResult.count |
                number:'1.0'}}</strong>
            records</span>
    </mat-chip>

    <table mat-table [dataSource]="MITInflowResult.rows" matSort matSort class="mat-elevation-z8">

        <ng-container matColumnDef="period">
            <th mat-header-cell *matHeaderCellDef> Period </th>
            <td mat-cell *matCellDef="let element">
                {{ element.period }}
            </td>
        </ng-container>

        <ng-container matColumnDef="totalMIT">
            <th mat-header-cell *matHeaderCellDef> Total MIT </th>
            <td mat-cell *matCellDef="let element">
                {{element.totalMIT}}
            </td>
        </ng-container>

        <ng-container matColumnDef="remainingMIT">
            <th mat-header-cell *matHeaderCellDef> Remaining MIT </th>
            <td mat-cell *matCellDef="let element">
                {{element.remainingMIT}}
            </td>
        </ng-container>

        <ng-container matColumnDef="percentHealed">
            <th mat-header-cell *matHeaderCellDef> % Healed </th>
            <td mat-cell *matCellDef="let element">
                {{element.percentHealed | number : '1.2-2'}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
