<form (ngSubmit)="onSubmit()" [formGroup]="stepForm">
    <div formGroupName="commonInputs">
        <div class="form-field-wrapper">
            <mat-form-field>
                <mat-label>Step Type</mat-label>
                <mat-select placeholder="Step Type" formControlName="stepType">
                    <mat-option *ngFor="let stepType of correctionStepTypeOptions" [value]="stepType.key">
                        {{ stepType.value }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="stepType.invalid && stepType.errors.required">
                    This field is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-field-wrapper">
            <mat-form-field>
                <mat-label>Type of Field to Correct</mat-label>
                <mat-select placeholder="Type of Field to Correct" formControlName="correctionFieldType">
                    <mat-option *ngFor="let fieldType of correctionFieldTypes" [value]="fieldType[0]">
                        {{ fieldType[1] }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="correctionFieldType.invalid && correctionFieldType.errors.required">
                    This field is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-field-wrapper" *ngIf="showHeaderField">
            <mat-form-field>
                <mat-label>Field Name</mat-label>
                <mat-select placeholder="Field Name" formControlName="headerFieldName">
                    <mat-option *ngFor="let name of fieldTypeValues.headers" [value]="name">
                        {{ name }}
                    </mat-option>
                </mat-select>
                <mat-error>
                    This field is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-field-wrapper" *ngIf="showPayloadField">
            <mat-form-field>
                <mat-label>Field Name</mat-label>
                <mat-select placeholder="Field Name" formControlName="payloadFieldName">
                    <mat-option *ngFor="let name of fieldTypeValues.payload" [value]="name">
                        {{ name }}
                    </mat-option>
                </mat-select>
                <mat-error>
                    This field is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-field-wrapper" *ngIf="showEndpointPathPart">
            <mat-form-field>
                <mat-label>Endpoint Path Part</mat-label>
                <mat-select placeholder="Endpoint Path Part" formControlName="pathPart">
                    <mat-option *ngFor="let option of fieldTypeValues.pathParts" [value]="option[0]">
                        {{ option[1] }}
                    </mat-option>
                </mat-select>
                <mat-error>
                    This field is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div formGroupName="addCharacterInputs" *ngIf="showAddCharacterInputs">
        <p><strong>Add Characters</strong></p>
        <mat-error *ngIf="incompleteBatchStep">
            All fields in this section are <strong>required</strong>
        </mat-error>

        <div class="form-field-wrapper">
            <mat-form-field>
                <mat-label>Characters to Add</mat-label>
                <input matInput formControlName="charactersToAdd">
            </mat-form-field>
        </div>

        <div class="form-field-wrapper">
            <mat-form-field>
                <mat-label>Add charaters to the...</mat-label>
                <mat-select placeholder="Add charaters to the..." formControlName="addCharactersAt">
                    <mat-option *ngFor="let modificationPosition of modificationPositionOptions"
                        [value]="modificationPosition.key">
                        {{ modificationPosition.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div formGroupName="removeCharacterInputs" *ngIf="showRemoveCharacterInputs">
        <p><strong>Remove Characters</strong></p>
        <mat-error *ngIf="incompleteBatchStep">
            All fields in this section are <strong>required</strong>
        </mat-error>

        <div class="form-field-wrapper">
            <mat-form-field>
                <mat-label>Remove charaters from the...</mat-label>
                <mat-select placeholder="Remove charaters from the..." formControlName="removeCharactersAt">
                    <mat-option *ngFor="let modificationPosition of modificationPositionOptions"
                        [value]="modificationPosition.key">
                        {{ modificationPosition.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="form-field-wrapper">
            <mat-form-field>
                <mat-label>Number of Characters to Remove</mat-label>
                <input matInput type="number" min="1" formControlName="charactersToRemove">
            </mat-form-field>
        </div>
    </div>

    <div formGroupName="replaceCharacterInputs" *ngIf="showReplaceCharacterInputs">
        <p><strong>Replace All of These Characters</strong></p>
        <mat-error *ngIf="incompleteBatchStep">
            All fields in this section are <strong>required</strong>
        </mat-error>

        <div class="replace-character-fieldset" formArrayName="charactersToReplace"
            *ngFor="let field of charactersToReplace.controls; let i = index;">
            <div class="form-field-wrapper">
                <mat-form-field>
                    <input matInput [formControlName]="i" placeholder="Example: I">
                </mat-form-field>
            </div>
            <button type="button" color="accent" mat-icon-button (click)="removeCharacterToReplace(i)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>

        <button type="button" mat-raised-button mat-button (click)="addCharacterToReplace()"
            class="add-replacechar-button">
            <mat-icon>add</mat-icon> Add...
        </button>

        <p><strong>With...</strong></p>

        <div class="form-field-wrapper">
            <mat-form-field>
                <input matInput formControlName="charactersToAdd" placeholder="Example: 1">
            </mat-form-field>
        </div>
    </div>

    <div formGroupName="copyOtherFieldInputs" *ngIf="showCopyOtherFieldInputs">
        <p><strong>Copy Other Field Value</strong></p>
        <mat-error *ngIf="incompleteBatchStep">
            All fields in this section are <strong>required</strong>
        </mat-error>

        <div class="form-field-wrapper">
            <mat-form-field>
                <mat-label>Field to Copy</mat-label>
                <mat-select placeholder="Field to Copy" formControlName="replacementFieldName">
                    <mat-option *ngFor="let name of fieldTypeValues.payload" [value]="name">
                        {{ name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div formGroupName="setToSameValueInputs" *ngIf="showSetToSameValueInputs">
        <p><strong>Set to Specific Value</strong></p>
        <mat-error *ngIf="incompleteBatchStep">
            All fields in this section are <strong>required</strong>
        </mat-error>

        <div class="form-field-wrapper">
            <mat-form-field>
                <mat-label>New Field Value</mat-label>
                <input matInput formControlName="charactersToAdd">
            </mat-form-field>
        </div>
    </div>

    <div class="buttons">
        <button type="button" mat-button mat-raised-button (click)="onDiscardClicked()">Discard</button>
        <button type="submit" color="primary" mat-button mat-raised-button [disabled]="submitDisabled">
            Add step</button>
    </div>
</form>
