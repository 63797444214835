import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorRecordSearchRequest } from 'src/app/model/error-search/ErrorRecordSearchRequest';
import { ErrorCategoryPipe } from 'src/app/pipe/error-category.pipe';
import { ErrorFilter } from '../error-details.component';
import { ErrorFilterComponent, ErrorFilterSelection } from '../error-filter/error-filter.component';
import { EventCodePipe } from 'src/app/pipe/event-code.pipe';
import { ErrorStatusPipe } from 'src/app/pipe/error-status.pipe';
import { LoginService } from 'src/app/service/login.service';
import { Policies } from 'src/constants';

@Component({
    selector: 'app-error-details-search',
    templateUrl: './error-details-search.component.html',
    styleUrls: ['./error-details-search.component.scss']
})
export class ErrorDetailsSearchComponent implements OnInit {

    @Input() set initialSearchRequest(value: ErrorRecordSearchRequest) {
        // We must spread the initial search request into a new object.
        // The search request comes directly from the store, and as such has
        // readonly properties. It can not be modified (remember, immutability!).
        this.errorRecordSearchRequest = {
            ...value
        } as ErrorRecordSearchRequest;

        this.searchValue = this.errorRecordSearchRequest.searchText ?? '';

        // Refresh the chips showing the current search parameters
        this.refreshErrorFilter();
    }

    @Output() performSearch = new EventEmitter<ErrorRecordSearchRequest>();

    searchValue = '';
    errorFilterArray: ErrorFilter[] = [];
    public errorRecordSearchRequest: ErrorRecordSearchRequest;
    public userUnits?: string[];
    public readonly canReadAllErrors: boolean;

    constructor(
        private loginService: LoginService,
        private dialog: MatDialog,
        private errorCategoryPipe: ErrorCategoryPipe,
        private errorStatusPipe: ErrorStatusPipe,
        private eventCodePipe: EventCodePipe,
        private snackBar: MatSnackBar) {

        this.canReadAllErrors = this.loginService.acceptedByPolicy(Policies.CanReadAllErrors);
    }

    ngOnInit(): void {
        this.userUnits = this.loginService.currentIdentity.units;
    }

    searchErrorRecords() {
        if (!this.canReadAllErrors && !this.errorRecordSearchRequest.sendingUnit) {
            this.snackBar.open('A sending unit must first be selected.', 'OK');
        } else {
            this.errorRecordSearchRequest.searchText = this.searchValue?.toUpperCase();
            this.errorRecordSearchRequest.pageIndex = 0;
            this.performSearch.emit(this.errorRecordSearchRequest);
        }
    }

    showErrorFilterDialog() {
        const currentErrorRecordSearchRequest = this.errorRecordSearchRequest;
        const dialogRef = this.dialog.open(ErrorFilterComponent, {
            minHeight: '70%',
            maxHeight: '80%',
            maxWidth: '60%',
            minWidth: '50%',
            data: currentErrorRecordSearchRequest
        });
        dialogRef.afterClosed().subscribe((response: ErrorFilterSelection) => {
            if (response) {
                this.errorRecordSearchRequest = {
                    ...this.errorRecordSearchRequest,
                    ...response
                };

                this.refreshErrorFilter();
                this.searchErrorRecords();
            }
        });
    }

    public refreshErrorFilter() {
        this.errorFilterArray = [];

        if (this.errorRecordSearchRequest.unitReference) {
            this.errorFilterArray.push({
                name: 'unitReference', value: `Referenced Unit: ${this.errorRecordSearchRequest.unitReference}`
            });
        }

        if (this.errorRecordSearchRequest.sendingUnit) {
            this.errorFilterArray.push({
                name: 'sendingUnit', value: `Sending unit: ${this.errorRecordSearchRequest.sendingUnit}`
            });
        }

        if (this.errorRecordSearchRequest.errorCode) {
            this.errorFilterArray.push({
                name: 'errorCode', value: `Discovery code: ${this.errorRecordSearchRequest.errorCode}`
            });
        }

        if (this.errorRecordSearchRequest.fromDate) {
            this.errorFilterArray.push({
                name: 'fromDate', value: `From: ${this.errorRecordSearchRequest.fromDate}`
            });
        }

        if (this.errorRecordSearchRequest.toDate) {
            this.errorFilterArray.push({
                name: 'toDate', value: `To: ${this.errorRecordSearchRequest.toDate}`
            });
        }

        if (this.errorRecordSearchRequest.status) {
            this.errorFilterArray.push({
                name: 'status', value: `Status: ${this.errorStatusPipe.transform(this.errorRecordSearchRequest.status)}`
            });
        }

        if (this.errorRecordSearchRequest.errorCategory) {
            this.errorFilterArray.push({
                name: 'errorCategory', value: `Category: ${this.errorCategoryPipe.transform(this.errorRecordSearchRequest.errorCategory)}`
            });
        }

        if (this.errorRecordSearchRequest.eventCode) {
            this.errorFilterArray.push({
                name: 'eventCode', value: `Event code: ${this.eventCodePipe.transform(this.errorRecordSearchRequest.eventCode)}`
            });
        }
    }

    removeFromErrorFilter(filterParam: ErrorFilter) {
        const index = this.errorFilterArray.findIndex(x => x.name === filterParam.name);

        if (index >= 0) {
            this.errorFilterArray.splice(index, 1);
            this.errorRecordSearchRequest[filterParam.name] = null;
        }
    }

    removeFromSearch(searchParam) {
        this.removeFromErrorFilter(searchParam);
        this.searchErrorRecords();
    }
}
