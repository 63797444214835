import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TopErrorsSearchResult } from 'src/app/state/dashboard/dashboard.reducers';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
    selector: 'app-error-inflow-bar-chart',
    templateUrl: './error-inflow-bar-chart.component.html',
    styleUrls: ['./error-inflow-bar-chart.component.scss']
})
export class ErrorInflowBarChartComponent implements OnInit {
    constructor() { }

    public barChartOptions: ChartOptions = {
        scales: {
            xAxes: [
                {
                    id: "bar-x-axis",
                    type: 'category',
                }
            ],
            yAxes: [{
                id: "bar-y-axis1",
                stacked: false,
                ticks: {
                    beginAtZero: true
                }
            }],
        },
        tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
                label: function (tooltipItems, data) {
                    console.log(data)
                    console.log(tooltipItems.index.valueOf())
                    console.log(tooltipItems.datasetIndex)
                    return "Inflow: " + data.datasets[0].data[tooltipItems.index.valueOf()] + " Remaining: " + data.datasets[1].data[tooltipItems.index.valueOf()] + " Percent Done: " + data.datasets[2].data[tooltipItems.index.valueOf()];
                }
            }
        },
        legend: {
            labels: {
                filter: function (item, chart) {
                    // Logic to remove a particular legend item goes here
                    return !item.text.includes('Percent Done');
                }
            }
        },
        responsive: true,
        maintainAspectRatio: true
    };

    @Input() barChartData: any[];
    @Input() barChartLabels: string[];
    @Input() barChartRemaining: any[];

    public barChartType = 'bar';
    public barChartLegend = false;
    public barChartColors: Array<any> = [
        { // first color
            backgroundColor: 'rgba(255, 99, 132,0.6)',
            borderColor: 'rgba(255, 99, 132,1.0)',
            hoverBackgroundColor: 'rgba(255, 99, 132, 0.8)',
            hoverBorderColor: 'rgba(255, 99, 132, 1)'
        },
        { // second color
            backgroundColor: 'rgba(255, 206, 86 ,0.6)',
            borderColor: 'rgba(255, 206, 86,1.0)',
            hoverBackgroundColor: 'rgba(255, 206, 86, 0.8)',
            hoverBorderColor: 'rgba(255, 206, 86, 1)'
        }];

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
    }
}
