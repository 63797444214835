import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from '../service/lookup.service';

@Pipe({
    name: 'eventCode'
})

export class EventCodePipe implements PipeTransform {

    constructor(private lookupService: LookupService) { }

    transform(value: string | string[], ...args: unknown[]): string {
        if (Array.isArray(value)) {
            return value.map(s => this.lookupService.translateEventCode(s)).join(', ');
        }
        return this.lookupService.translateEventCode(value);
    }
}
