<div class="filter-row">
  <div class="filter-section date-filter">
    <mat-form-field class="small-input-width">
      <input (dateChange)="periodFromDateChange($event)" readonly="true" matInput [matDatepicker]="periodfrom"
        placeholder="Period from" [value]="request.fromDate" (dateChange)="periodFromDateChange($event)">
      <mat-datepicker-toggle matSuffix [for]="periodfrom"></mat-datepicker-toggle>
      <mat-datepicker #periodfrom></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="small-input-width">
      <input readonly="true" matInput [matDatepicker]="perioduntil" [value]="request.toDate"
        (dateChange)="periodUntilDateChange($event)" placeholder="Period until">
      <mat-datepicker-toggle matSuffix [for]="perioduntil"></mat-datepicker-toggle>
      <mat-datepicker #perioduntil></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="filter-section group-filter">
    <div style="margin-bottom:5px;"><strong>Group by</strong></div>
    <div>
      <mat-checkbox [(ngModel)]="request.groupByMonth">Month
      </mat-checkbox>
    </div>
  </div>

  <div class="filter-section buttons">
    <button class="export-button" style="background-color: #47bac1; color:#fff;" mat-button mat-raised-button
      (click)="downloadExcel()">Export</button>

    <button style="background-color: #47bac1; color:#fff;" mat-button mat-raised-button
      (click)="getReport()">Search</button>
  </div>

</div>