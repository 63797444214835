import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSummaryRequest } from 'src/app/model/error-summary/ErrorSummaryRequest';
import { LoginService } from 'src/app/service/login.service';
import { Policies } from 'src/constants';

@Component({
    selector: 'app-error-summary-search',
    templateUrl: './error-summary-search.component.html',
    styleUrls: ['./error-summary-search.component.scss']
})
export class ErrorSummarySearchComponent implements OnInit {

    errorSummaryRequest: ErrorSummaryRequest;
    public errorTypeForm: FormGroup;

    public sendingUnit = '';
    public fromDate: string;
    public toDate: string;
    public currentDate: string;
    public userUnits?: string[];
    public readonly canReadAllErrors: boolean;

    @Input() set initialSearchRequest(value: ErrorSummaryRequest) {
        // We must spread the initial search request into a new object.
        // The search request comes directly from the store, and as such has
        // readonly properties. It can not be modified (remember, immutability!).
        this.errorSummaryRequest = {
            ...value
        } as ErrorSummaryRequest;

        this.sendingUnit = this.errorSummaryRequest.sendingUnit ?? '';
        this.fromDate = this.errorSummaryRequest.fromDate;
        this.toDate = this.errorSummaryRequest.toDate;
    }

    @Output() performSearch = new EventEmitter<ErrorSummaryRequest>();

    constructor(
        private formBuilder: FormBuilder,
        private dateAdapter: DateAdapter<Date>,
        private loginService: LoginService,
        private snackBar: MatSnackBar) {

        this.errorTypeForm = this.formBuilder.group({
            sendingUnitControl: [this.sendingUnit],
        });

        this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
        this.dateAdapter.setLocale('se-SE');
        this.canReadAllErrors = this.loginService.acceptedByPolicy(Policies.CanReadAllErrors);
    }

    ngOnInit(): void {
        this.userUnits = this.loginService.currentIdentity.units;
    }

    toDateChange(event) {
        this.toDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
    }

    fromDateChange(event) {
        this.fromDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
    }

    searchErrorSummary() {
        if (!this.canReadAllErrors && !this.sendingUnit) {
            this.snackBar.open('A sending unit must first be selected.', 'OK');
        } else {
            this.errorSummaryRequest.sendingUnit = this.sendingUnit?.toUpperCase();
            this.errorSummaryRequest.fromDate = this.fromDate;
            this.errorSummaryRequest.toDate = this.toDate;
            this.performSearch.emit(this.errorSummaryRequest);
        }
    }
}
