import * as _ from 'lodash';
import { addDays, format } from 'date-fns';
import { MITInflowResultRow } from 'src/app/model/missing-in-tracy/MITInflowResultRow';
import { MITActions, MITActionTypes } from './missing-in-tracy.actions';
import { TopMITFactoryHittersResultRow } from 'src/app/model/missing-in-tracy/topMITFactoryHittersResultRow';
import { MITSearchRequest } from 'src/app/model/missing-in-tracy/MITSearchRequest';

export interface MITState {
    searchRequest: MITSearchRequest | null;
    MITInflowResult: MITInflowSearchResult | null;
    topMITFactoryHittersResult: TopMITFactoryHittersSearchResult | null;
}

export interface MITInflowSearchResult {
    rows: MITInflowResultRow[] | null;
    count: number;
}

export interface TopMITFactoryHittersSearchResult {
    rows: TopMITFactoryHittersResultRow[] | null;
    count: number;
}

const initialMITState: MITState = {
    searchRequest: {
        fromDate: format(addDays(new Date(), -1), 'yyyy-MM-dd'),
        toDate: format(new Date(), 'yyyy-MM-dd'),
        dateGrouping: 'Day',
        eventType: '',
        sendingUnit: '',
        productNumber: '',
        revisionState: '',
        errorCategory: '',
        pageSize: 100,
    },
    MITInflowResult: null,
    topMITFactoryHittersResult: null
};

export function MITReducer(state: MITState = initialMITState, action: MITActions): MITState {
    switch (action.type) {
        case MITActionTypes.SetSearchRequests:
            return {
                ...state,
                searchRequest: action.payload.request,
            };
        case MITActionTypes.LoadMITInflowResultsSuccess:
            return {
                ...state,
                MITInflowResult: {
                    rows: action.payload.rows,
                    count: action.payload.count ?? state.MITInflowResult?.count,
                }
            };
        case MITActionTypes.LoadTopMITFactoryHittersResultsSuccess:
            return {
                ...state,
                topMITFactoryHittersResult: {
                    rows: action.payload.rows,
                    count: action.payload.count ?? state.topMITFactoryHittersResult?.count,
                }
            };
        default:
            return state;
    }
}
