import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { forkJoin, of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { ErrorRecordSearchRequest } from "src/app/model/error-search/ErrorRecordSearchRequest";
import { ApiDataService } from "src/app/service/api-data.service";
import { AppState } from "..";
import { formatDate } from '@angular/common';
import { ErrorNotificationAction } from "../notification/notification.actions";
import {
    DashboardActionTypes,
    ExportErrorInflowToExcelAction,
    ExportErrorInflowToExcelSuccessAction,
    ExportTopErrorsToExcelAction,
    ExportTopErrorsToExcelSuccessAction,
    LoadDetailsErrorInflowAction,
    LoadErrorInflowResultsAction,
    LoadErrorInflowResultsSuccessAction,
    LoadTopErrorsResultsAction,
    LoadTopErrorsResultsSuccessAction,
    LoadDetailsTopErrorsAction,
    LoadTopFactoryHittersResultsAction,
    LoadTopFactoryHittersResultsSuccessAction,
    LoadDetailsSearchTopFactoryHittersAction,
    ExportTopFactoryHittersToExcelAction,
    ExportTopFactoryHittersToExcelSuccessAction
} from "./dashboard.actions";
import {
    selectDashboardSearchRequest,
    selectErrorInflowRequest,
    selectExportErrorInflowToExcelRequest,
    selectExportTopErrorsToExcelRequest,
    selectExportTopFactoryHittersToExcelRequest,
    selectTopErrorsRequest,
    selectTopFactoryHittersRequest
} from "./dashboard.selectors";
import * as ErrorDetailsActions from '../error-details/error-details.actions';
import { ErrorCount } from "src/app/model/error-search/ErrorCount";

@Injectable()
export class DashboardEffects {

    @Effect()
    loadTopErrorsResult$ = this.actions$
        .pipe(
            ofType<LoadTopErrorsResultsAction>(DashboardActionTypes.LoadTopErrorsResults),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                return forkJoin({
                    searchResult: this.apiDataService.searchDashboardTopErrors(selectTopErrorsRequest(storeState)),
                    countResult: action.payload.loadCount ?
                        this.apiDataService.topErrorCount(selectTopErrorsRequest(storeState)) :
                        of({ count: null } as ErrorCount)
                })
                    .pipe(
                        map(({ searchResult, countResult }) => {
                            return new LoadTopErrorsResultsSuccessAction({ rows: searchResult, count: countResult.count });
                        }),
                        catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to load errors: ${error.message}` })))
                    )
            })
        );

    /**
     * When a LoadDetailsSearchAction is dispatched, create an error details request with parameters
     * that match the row in the action. Dispatch it to the store, then start the error details search
     * by dispatching an DashboardActionTypes.LoadResultsAction.
     */
    @Effect()
    searchDetailsTopErrors$ = this.actions$
        .pipe(
            ofType<LoadDetailsTopErrorsAction>(DashboardActionTypes.LoadDetailsSearchTopErrors),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                const request = selectDashboardSearchRequest(storeState);
                const clickedRow = action.payload.row;

                const detailsSearchRequest = new ErrorRecordSearchRequest();
                detailsSearchRequest.errorCategory = clickedRow.errorCategory;
                detailsSearchRequest.sendingUnit = request.sendingUnit;
                detailsSearchRequest.eventCode = request.eventType;
                detailsSearchRequest.toDate = request.toDate;
                detailsSearchRequest.fromDate = request.fromDate;
                detailsSearchRequest.searchText = request.productNumber;
                detailsSearchRequest.revisionState = request.revisionState
                detailsSearchRequest.status = 'New';

                return [
                    new ErrorDetailsActions.SetSearchRequestAction({ request: detailsSearchRequest }),
                    new ErrorDetailsActions.LoadResultsAction({ loadCount: true })
                ];
            })
        );

    @Effect()
    searchDetailsErrorInflow$ = this.actions$
        .pipe(
            ofType<LoadDetailsErrorInflowAction>(DashboardActionTypes.LoadDetailsSearchErrorInflow),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                const request = selectDashboardSearchRequest(storeState);
                const clickedRow = action.payload.row;

                const detailsSearchRequest = new ErrorRecordSearchRequest();
                detailsSearchRequest.sendingUnit = request.sendingUnit;
                detailsSearchRequest.eventCode = request.eventType;
                detailsSearchRequest.toDate = formatDate(clickedRow.endOfPeriod, 'yyyy-MM-dd', 'en');
                detailsSearchRequest.fromDate = formatDate(clickedRow.startOfPeriod, 'yyyy-MM-dd', 'en');
                detailsSearchRequest.errorCategory = request.errorCategory;
                detailsSearchRequest.searchText = request.productNumber;
                detailsSearchRequest.revisionState = request.revisionState;
                detailsSearchRequest.status = 'New';

                return [
                    new ErrorDetailsActions.SetSearchRequestAction({ request: detailsSearchRequest }),
                    new ErrorDetailsActions.LoadResultsAction({ loadCount: true })
                ];
            })
        );

    @Effect()
    exportTopErrorsToExcel$ = this.actions$
        .pipe(
            ofType<ExportTopErrorsToExcelAction>(DashboardActionTypes.ExportTopErrorsToExcel),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => this.apiDataService.exportDashboardTopErrorsToExcel(selectExportTopErrorsToExcelRequest(storeState))
                .pipe(
                    map(() =>
                        new ExportTopErrorsToExcelSuccessAction()),
                    catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Unable to export top errors. HTTP response code: ${error.status}` })))
                )
            )
        );

    @Effect()
    exportErrorInflowToExcel$ = this.actions$
        .pipe(
            ofType<ExportErrorInflowToExcelAction>(DashboardActionTypes.ExportErrorInflowToExcel),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => this.apiDataService.exportDashboardErrorInflowToExcel(selectExportErrorInflowToExcelRequest(storeState))
                .pipe(
                    map(() => new ExportErrorInflowToExcelSuccessAction()),
                    catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Unable to export error inflow. HTTP response code: ${error.status}` })))
                )
            )
        );

    @Effect()
    loadErrorInflowResults$ = this.actions$
        .pipe(
            ofType<LoadErrorInflowResultsAction>(DashboardActionTypes.LoadErrorInflowResults),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                return forkJoin({
                    searchResult: this.apiDataService.searchDashboardErrorInflow(selectErrorInflowRequest(storeState)),
                    countResult: action.payload.loadCount ?
                        this.apiDataService.errorInflowCount(selectErrorInflowRequest(storeState)) :
                        of({ count: null } as ErrorCount)
                })
                    .pipe(
                        map(({ searchResult, countResult }) => {
                            return new LoadErrorInflowResultsSuccessAction({ rows: searchResult, count: countResult.count });
                        }),
                        catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to load errors: ${error.messade}` })))
                    )
            })
        );

    @Effect()
    loadTopFactoryHittersResult$ = this.actions$
        .pipe(
            ofType<LoadTopFactoryHittersResultsAction>(DashboardActionTypes.LoadTopFactoryHittersResults),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                return forkJoin({
                    searchResult: this.apiDataService.searchDashboardTopFactoryHitters(selectTopFactoryHittersRequest(storeState)),
                    countResult: action.payload.loadCount ?
                        this.apiDataService.topFactoryHittersCount(selectTopFactoryHittersRequest(storeState)) :
                        of({ count: null } as ErrorCount)
                })
                    .pipe(
                        map(({ searchResult, countResult }) => {
                            return new LoadTopFactoryHittersResultsSuccessAction({ rows: searchResult, count: countResult.count });
                        }),
                        catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to load errors: ${error.message}` })))
                    )
            })
        );

    @Effect()
    searchDetailsTopFactoryHitters$ = this.actions$
        .pipe(
            ofType<LoadDetailsSearchTopFactoryHittersAction>(DashboardActionTypes.LoadDetailsSearchTopFactoryHitters),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                const request = selectDashboardSearchRequest(storeState);
                const clickedRow = action.payload.row;

                const detailsSearchRequest = new ErrorRecordSearchRequest();
                detailsSearchRequest.sendingUnit = clickedRow.sendingUnit;
                detailsSearchRequest.eventCode = request.eventType;
                detailsSearchRequest.toDate = request.toDate;
                detailsSearchRequest.fromDate = request.fromDate;

                return [
                    new ErrorDetailsActions.SetSearchRequestAction({ request: detailsSearchRequest }),
                    new ErrorDetailsActions.LoadResultsAction({ loadCount: true })
                ];
            })
        );

    @Effect()
    exportTopFactoryHittersToExcel$ = this.actions$
        .pipe(
            ofType<ExportTopFactoryHittersToExcelAction>(DashboardActionTypes.ExportTopFactoryHittersToExcel),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => this.apiDataService.exportDashboardTopFactoryHittersToExcel(selectExportTopFactoryHittersToExcelRequest(storeState))
                .pipe(
                    map(() =>
                        new ExportTopFactoryHittersToExcelSuccessAction()),
                    catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Unable to export top errors. HTTP response code: ${error.status}` })))
                )
            )
        );

    constructor(
        private actions$: Actions,
        private apiDataService: ApiDataService,
        private store$: Store<AppState>) { }
}
