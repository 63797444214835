import { Component, Input, OnInit, SimpleChanges } from '@angular/core'; import { TopErrorsSearchResult } from 'src/app/state/dashboard/dashboard.reducers';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';

@Component({
    selector: 'app-top-errors-bar-chart',
    templateUrl: './top-errors-bar-chart.component.html',
    styleUrls: ['./top-errors-bar-chart.component.scss']
})
export class TopErrorsBarChartComponent implements OnInit {
    constructor() { }

    public barChartOptions: ChartOptions = {
        scales: {
            xAxes: [
                {
                    id: "bar-x-axis",
                    type: 'category',
                }
            ],
            yAxes: [{
                id: "bar-y-axis1",
                stacked: false,
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        responsive: true,
        maintainAspectRatio: true
    };

    @Input() barChartData: any[];
    @Input() barChartLabels: string[];

    public barChartType = 'bar';
    public barChartLegend = true;

    public barChartColors: Array<any> = [
        { // first color
            backgroundColor: 'rgba(255, 99, 132,0.6)',
            borderColor: 'rgba(255, 99, 132,1.0)',
            hoverBackgroundColor: 'rgba(255, 99, 132, 0.8)',
            hoverBorderColor: 'rgba(255, 99, 132, 1)'
        },
        { // second color
            backgroundColor: 'rgba(255, 206, 86 ,0.6)',
            borderColor: 'rgba(255, 206, 86,1.0)',
            hoverBackgroundColor: 'rgba(255, 206, 86, 0.8)',
            hoverBorderColor: 'rgba(255, 206, 86, 1)'
        }];

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
    }
}
