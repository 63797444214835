import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from '../service/lookup.service';
import { ErrorInflowSearchResult, TopErrorsSearchResult } from '../state/dashboard/dashboard.reducers';

@Pipe({
    name: 'errorInflowResultChart'
})

export class ErrorInflowResultChartPipe implements PipeTransform {

    constructor() { }

    transform(result: ErrorInflowSearchResult, ...args: any): any[] {
        let barChartData = [];
        let dataInflow = []
        let dataRemaining = [];
        let dataPercentDone = [];
        for (let row of result.rows) {
            dataInflow.push(row.errors);
            dataRemaining.push(row.remainingErrors);
            dataPercentDone.push((Math.round(row.percentDone * 100) / 100) + "%");
        }
        barChartData.push({ data: dataInflow, label: "Inflow" });
        barChartData.push({ data: dataRemaining, label: "Remaining" });
        barChartData.push({ data: dataPercentDone, label: "Percent Done", hidden: true }); // Data used in the tooltip therefore hidden
        return barChartData
    }
}
