import { Component, OnInit } from '@angular/core';
import { LegacyErrorSearchRequest } from 'src/app/model/legacy-errors/LegacyErrorSearchRequest';
import { LegacyErrorSearchResultRow } from 'src/app/model/legacy-errors/LegacyErrorSearchResultRow';
import { MatTableDataSource } from '@angular/material/table';
import { ApiDataService } from 'src/app/service/api-data.service';
import { DateAdapter } from '@angular/material/core';
import { NotificationService } from 'src/app/service/notification.service';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as PageTitleActions from '../../state/page-title/page-title.actions';

@Component({
  selector: 'app-legacy-error-search',
  templateUrl: './legacy-error-search.component.html',
  styleUrls: ['./legacy-error-search.component.scss']
})
export class LegacyErrorSearchComponent implements OnInit {

  request: LegacyErrorSearchRequest;
  searchResult: LegacyErrorSearchResultRow[] = [];
  dataSource = new MatTableDataSource<LegacyErrorSearchResultRow>(this.searchResult);
  dataTableVisibility = false;

  displayedColumns = [
    'serNo',
    'recTp',
    'crStamp',
    'unitSnd',
    'msgId',
    'chStamp',
    'crUser',
    'chUser'
  ];

  constructor(
    private apiDataService: ApiDataService,
    private dateAdapter: DateAdapter<Date>,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>) {

    this.dateAdapter.setLocale('se-SE');
    this.request = new LegacyErrorSearchRequest();
  }

  ngOnInit(): void {
    this.store.dispatch(new PageTitleActions.SetPageTitleAction({ pageTitle: 'Legacy Errors' }));
  }

  periodFromDateChange(event) {
    this.request.crStampFrom = formatDate(event.value, 'yyyy-MM-dd', 'en');
  }
  periodUntilDateChange(event) {
    this.request.crStampTo = formatDate(event.value, 'yyyy-MM-dd', 'en');
  }

  performSearch() {
    this.apiDataService.searchLegacyErrors(this.request).subscribe((response: LegacyErrorSearchResultRow[]) => {
      this.searchResult = response;
      this.dataSource.data = this.searchResult;
      this.dataTableVisibility = true;
      this.notifyIfEmpty();
    }, (error) => {
      this.notificationService.showError('An unknown error occurred');
    });
  }

  private notifyIfEmpty() {
    if (this.searchResult.length === 0) {
      this.notificationService.showSuccess('No errors matched your search.');
    }
  }

  onRowClicked(currentRow: LegacyErrorSearchResultRow) {
    this.router.navigate([currentRow.id], { relativeTo: this.route });
  }
}
