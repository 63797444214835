import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardFilterSelection } from 'src/app/model/dashboard/dashboardFilterSelection';
import { DashboardSearchRequest } from 'src/app/model/dashboard/dashboardSearchRequest';
import { LookupService } from 'src/app/service/lookup.service';

@Component({
    selector: 'app-dashboard-filter',
    templateUrl: './dashboard-filter.component.html',
    styleUrls: ['./dashboard-filter.component.scss']
})
export class DashboardFilterComponent implements OnInit {

    public filter: DashboardFilterSelection;
    public eventTypes = [];
    public errorCategories = [];
    public processSteps = [];

    constructor(public dialogRef: MatDialogRef<DashboardFilterComponent>,
        private lookupService: LookupService,
        @Inject(MAT_DIALOG_DATA) public data: DashboardSearchRequest) {
        this.filter = data;
    }

    ngOnInit(): void {
        this.eventTypes = this.lookupService.getEventCodes();
        this.errorCategories = this.lookupService.getErrorCategories();
    }

    searchErrors() {
        this.dialogRef.close(this.filter);
    }

    close() {
        this.dialogRef.close('');
    }

    eventTypeChanged(event) {
        this.filter.eventType = event.value;
    }

    errorCategoryChanged(event) {
        this.filter.errorCategory = event.value;
    }
}
