import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as DashboardActions from '../../../state/dashboard/dashboard.actions';
import { ExportExcelTopErrorsRequest } from 'src/app/model/dashboard/exportExcelTopErrorsRequest';
import { TopErrorInflowResultRow } from 'src/app/model/dashboard/topErrorInflowResultRow';
import * as NavigationActions from 'src/app/state/navigation/navigation.actions';
import { TopErrorsSearchResult } from 'src/app/state/dashboard/dashboard.reducers';

@Component({
  selector: 'app-dashboard-top-errors',
  templateUrl: './dashboard-top-errors.component.html',
  styleUrls: ['./dashboard-top-errors.component.scss']
})

export class DashboardTopErrorsComponent implements OnInit {

  displayedColumns: string[] =
    [
      'errorCategory',
      'errors',
      'remainingErrors',
    ];

  @Input() topErrorsResult: TopErrorsSearchResult;
  @Output() exportTopErrors = new EventEmitter<ExportExcelTopErrorsRequest>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private store: Store<AppState>) {
  }

  ngOnInit(): void {
  }

  navigateToDetails(row: TopErrorInflowResultRow) {
    this.store.dispatch(new DashboardActions.LoadDetailsTopErrorsAction({ row }));
    this.store.dispatch(new NavigationActions.NavigateToPageAction({ route: ['error-handling'] }));
  }

  exportTopErrorsToExcel() {
    this.exportTopErrors.emit();
  }
}
