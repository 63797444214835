<div id="customOverlay" onclick="hideoverlay()"></div>
<div class="wrapper d-flex" *ngIf="isAuthenticated">
  <!-- -----------------Sidebar section----------------- -->
  <div class="sidebar" id="sidebarCloseOnClick">
    <div class="sidebar-menu">
      <mat-list>
        <app-side-nav-menu></app-side-nav-menu>
      </mat-list>
    </div>
  </div>

  <!----------------- Content section --------------->
  <div class="content">

    <!--------------- Header section --------------->
    <nav class="navbar navbar-expand-lg toolbar">
      <div class="container-fluid">
        <div class="navbar-header">
          <div *ngIf="pageTitle$ | async as pageTitle">{{pageTitle}}</div>
        </div>
        <div class="nav navbar-nav navbar-right">
          <span class="user navbar-text">{{username}}</span>
          <button type="button" (click)="logout()" class="btn logout-btn"> Logout</button>
        </div>
      </div>
    </nav>

    <!--------------- Main content section --------------->
    <main>
      <div class="main-content">
        <router-outlet></router-outlet>
      </div>
    </main>

  </div>
</div>