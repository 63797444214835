import { Injectable } from '@angular/core';
import streamSaver from 'streamsaver';
import { SpinnerLoaderService } from './spinner-loader.service';
import { TECTStateService } from './tect-state.service';
//Firefox does not support WritableStream so
//a polyfill is required to provide implementation for it
//and allow it to try all options of
//downloading the file.
import { WritableStream } from "web-streams-polyfill/ponyfill";

const CsvMediaType = "text/csv";

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE"
}

@Injectable({
  providedIn: 'root'
})
export class FileDownloaderService {

  constructor(
    private stateService: TECTStateService,
    private spinnerLoaderService: SpinnerLoaderService) {
    streamSaver.mitm = "/"; // We're hosting the mitm SW!
  }

  private encodeWithQueryParameters(url: string, params: Map<string, string> | any): string {
    let arr: string[] = [];

    if (params instanceof Map) {
      params.forEach((value: string | string[], key: string) => {
        arr = this.appendToQueryArray(key, value, arr);
      });
    }
    else {
      Object.keys(params).forEach((key: string) => {
        const value = params[key];
        arr = this.appendToQueryArray(key, value, arr);
      });
    }
    return url + "?" + arr.join("&");
  }

  private appendToQueryArray(key: string, value: any, arr: string[]): string[] {
    if (value) {
      if (Array.isArray(value)) {
        arr = arr.concat(value.map((v: string, index: number) => {
          return `${encodeURIComponent(key)}[${index}]=${encodeURIComponent(v)}`;
        }));
      } else {
        arr.push(`${encodeURIComponent(key)}=${value ? encodeURIComponent(value) : ""}`);
      }
    }
    return arr;
  }

  async downloadStream(filename: string, url: string, params?: Map<string, string> | any): Promise<any> {
    let headers: any = {
      "Content-Type": CsvMediaType,
      'Cache-Control': 'no-cache'
    };

    this.spinnerLoaderService.incrementLoadCount();
    var response = await fetch(
      (params ? this.encodeWithQueryParameters(url, params) : url),
      {
        method: Method.GET,
        headers: headers,
        credentials: "include"
      }
    );
    this.spinnerLoaderService.decrementLoadCount();

    if (!response.ok) {
      return Promise.reject(response);
    }

    if (response.status === 204 /* no content */) {
      return (null as any) as Response; // Or undefined??
    }

    // Open up a writeable stream to the file
    if (!window.WritableStream) {
      streamSaver.WritableStream = WritableStream;
      window.WritableStream = WritableStream;
    }
    let fileStream = streamSaver.createWriteStream(filename ?? 'TECT.csv');

    // Firefox does not support pipeTo
    if (response.body?.pipeTo) {
      response.body.pipeTo(fileStream);
      return;
    }

    // Last resort, do it manually
    const writer = fileStream.getWriter();
    const reader = response?.body?.getReader();
    const pump = (): any => reader?.read()
      .then(res => res.done
        ? writer.close()
        : writer.write(res.value).then(pump));

    pump();
  }
}
