import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import * as _ from 'lodash';
import { ErrorSummaryState } from './error-summary.reducers';


const featureSelector = (state: AppState) => state.errorSummary;

export const selectErrorSummaryRequest = createSelector(
    featureSelector,
    (state: ErrorSummaryState) => state.request
);

export const selectErrorSummaryResult = createSelector(
    featureSelector,
    (state: ErrorSummaryState) => state.results
);
