import {
    ActionReducerMap,
    MetaReducer
} from '@ngrx/store';
import environment from '../../environments/static';
import { ErrorDetailsState, errorDetailsReducer } from './error-details/error-details.reducers';
import { errorSummaryReducer, ErrorSummaryState } from './error-summary/error-summary.reducers';
import { pageTitleReducer, PageTitleState } from './page-title/page-title.reducers';
import { batchCorrectionReducer, BatchCorrectionState } from './batch-correction/batch-correction.reducers';
import { errorUpdateReducer, ErrorUpdateState } from './error-update/error-update.reducers';
import { dashboardReducer, DashboardState } from './dashboard/dashboard.reducers';
import { MITReducer, MITState } from './missing-in-tracy/missing-in-tracy.reducers';


export interface AppState {
    errorDetails: ErrorDetailsState;
    errorSummary: ErrorSummaryState;
    pageTitle: PageTitleState;
    batchCorrection: BatchCorrectionState;
    errorUpdate: ErrorUpdateState;
    dashboard: DashboardState;
    MIT: MITState;
}

export const reducers: ActionReducerMap<AppState> = {
    errorDetails: errorDetailsReducer,
    errorSummary: errorSummaryReducer,
    pageTitle: pageTitleReducer,
    batchCorrection: batchCorrectionReducer,
    errorUpdate: errorUpdateReducer,
    dashboard: dashboardReducer,
    MIT: MITReducer,
};

export const metaReducers: MetaReducer<AppState>[] = environment.compiled ? [] : [];
