import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { LookupService } from 'src/app/service/lookup.service';
import { ErrorRecordSearchRequest } from 'src/app/model/error-search/ErrorRecordSearchRequest';
import { LoginService } from 'src/app/service/login.service';
import { Policies } from 'src/constants';

export interface ErrorFilterSelection {
    unitReference: string;
    sendingUnit: string;
    fromDate: string;
    toDate: string;
    status: string;
    errorCode: string;
    errorCategory: string;
    eventCode: string;
}

@Component({
    selector: 'app-error-filter',
    templateUrl: './error-filter.component.html',
    styleUrls: ['./error-filter.component.scss']
})

export class ErrorFilterComponent implements OnInit {

    filter: ErrorFilterSelection;
    public statuses: string[] = [];
    public eventCodes = [];
    public errorCategories: string[] = [];
    public currentDate: string;
    public readonly canReadAllErrors: boolean;

    constructor(
        private loginService: LoginService,
        public dialogRef: MatDialogRef<ErrorFilterComponent>,
        private lookupService: LookupService,
        @Inject(MAT_DIALOG_DATA) public data: ErrorRecordSearchRequest) {

        this.filter = data;
        this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
        this.canReadAllErrors = this.loginService.acceptedByPolicy(Policies.CanReadAllErrors);
    }

    ngOnInit(): void {
        this.errorCategories = this.lookupService.getErrorCategories();
        this.statuses = this.lookupService.getErrorStatuses();
        this.eventCodes = this.lookupService.getEventCodes();
    }

    doErrorSearch() {
        this.dialogRef.close(this.filter);
    }

    close() {
        this.dialogRef.close('');
    }

    periodFromDateChange(event) {
        this.filter.fromDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
    }

    periodToDateChange(event) {
        this.filter.toDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
    }

    statusChanged(event) {
        this.filter.status = event.value;
    }

    categoryChanged(event: string) {
        this.filter.errorCategory = event;
    }

    eventCodeChanged(event) {
        this.filter.eventCode = event.value;
    }
}

