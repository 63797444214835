import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { SpinnerLoaderService } from '../service/spinner-loader.service';
import { LoginService } from '../service/login.service';
import { ApiUriService } from '../service/api-uri.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(public loaderService: SpinnerLoaderService, private loginService: LoginService, private apiUriService: ApiUriService) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const addToLoadCount = req.url !== this.apiUriService.renewCookieUrl && req.url !== this.apiUriService.checkCookieUrl;

        if (addToLoadCount)
            this.loaderService.incrementLoadCount();

        const newRequest = req.clone({
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache' // We don't want any caching on any request!
            }),
            withCredentials: true // We want to pass on cookies!
        })

        return next.handle(newRequest)
            .pipe(
                tap(
                    evt => {
                        if (evt instanceof HttpResponse) {
                            this.loaderService.decrementLoadCount();
                        }
                    },
                    err => {
                        if (err.status === 401) {
                            this.loginService.logout();
                            this.loaderService.resetLoader();
                        } else {
                            if (addToLoadCount)
                                this.loaderService.decrementLoadCount();
                        }
                    }),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    if ([401, 403].indexOf(error.status) !== -1) {
                        this.loginService.logout();
                    }
                    return throwError(error);
                })
            );
    }
}
