import { createSelector } from "@ngrx/store";
import { MITInflowSearchRequest } from "src/app/model/missing-in-tracy/MITInflowSearchRequest";
import { AppState } from "..";
import { MITState } from "./missing-in-tracy.reducers";
import { TopMITFactoryHittersSearchRequest } from "src/app/model/missing-in-tracy/topMITFactoryHittersSearchRequest";

const featureSelector = (state: AppState) => state.MIT;

export const selectMITSearchRequest = createSelector(
    featureSelector,
    (state: MITState) => state.searchRequest
);

export const selectMITInflowResult = createSelector(
    featureSelector,
    (state: MITState) => state.MITInflowResult
);

export const selectMITInflowRequest = createSelector(
    selectMITSearchRequest,
    request => {
        return {
            fromDate: request.fromDate,
            toDate: request.toDate,
            dateGrouping: request.dateGrouping,
            pageSize: request.pageSize
        } as MITInflowSearchRequest;
    }
);

export const selectTopMITFactoryHittersResult = createSelector(
    featureSelector,
    (state: MITState) => state.topMITFactoryHittersResult
);

export const selectTopMITFactoryHittersRequest = createSelector(
    selectMITSearchRequest,
    request => {
        return {
            fromDate: request.fromDate,
            toDate: request.toDate,
            pageSize: request.pageSize,
        } as TopMITFactoryHittersSearchRequest;
    }
);
