import { Injectable } from '@angular/core';
import environmentStatic from 'src/environments/static';

@Injectable({
    providedIn: 'root',
})
export class ApiUriService {

    apiBaseUrl = environmentStatic.apiBaseUrl;

    // Cookie Auth
    checkCookieUrl = environmentStatic.tracyCheckCookieUrl;
    renewCookieUrl = environmentStatic.tracyRenewCookieUrl;

    // Error records
    getErrorRecordUrl = this.apiBaseUrl + 'error-record/';
    getMultipleErrorRecordsUrl = this.apiBaseUrl + 'error-record/';
    searchErrorRecordUrl = this.apiBaseUrl + 'error-record/search';
    errorCountUrl = this.apiBaseUrl + 'error-record/error-record-count';
    errorSummaryUrl = this.apiBaseUrl + 'error-record/summary';
    exportToExcelUrl = this.apiBaseUrl + 'error-record/file/csv';
    archiveErrorUrl = this.apiBaseUrl + 'error-record/archive-error';
    archiveAllErrorsUrl = this.apiBaseUrl + 'error-record/archive-errors/';

    // Legacy errors
    searchLegacyErrorsUrl = this.apiBaseUrl + 'legacy-error';
    getLegacyErrorUrl = this.apiBaseUrl + 'legacy-error/';

    // Algorithm Assisted Correction
    searchInvalidSerialNumberUrl = this.apiBaseUrl + 'algorithm-assisted-correction/invalid-serial-number';
    countInvalidSerialNumberUrl = this.apiBaseUrl + 'algorithm-assisted-correction/invalid-serial-number-count';

    // Batch Correction of errors
    batchCorrectionPreviewUrl = this.apiBaseUrl + 'batch-correction/preview';

    // Statistics
    duplicateReportUrl = this.apiBaseUrl + 'statistics/duplicates';
    ddownloadDuplicateReportUrl = this.apiBaseUrl + 'statistics/duplicates/file/csv';
    mostCommonErrorReportUrl = this.apiBaseUrl + 'statistics/most-common-error';
    downloadCommonErrorReportUrl = this.apiBaseUrl + 'statistics/most-common-error/file/csv';

    // Lookups
    errorCategoriesUrl = this.apiBaseUrl + 'lookup/error-category';
    errorStatusesUrl = this.apiBaseUrl + 'lookup/error-status';
    eventCodesUrl = this.apiBaseUrl + 'lookup/event-code';
    policiesUrl = this.apiBaseUrl + 'lookup/policies';
    loadConfigFromBackendUrl = this.apiBaseUrl + "lookup/frontend-configuration";
    dateGroupingOptionsUrl = this.apiBaseUrl + 'lookup/date-options';

    // Dashboard
    dashboardTopErrorsUrl = this.apiBaseUrl + 'error-inflow/top-errors';
    dashboardExportExcelTopErrorsUrl = this.apiBaseUrl + 'error-inflow/top-error-inflow/file/csv';
    dashboardExportExcelErrorInflowUrl = this.apiBaseUrl + 'error-inflow/file/csv';
    dashboardErrorInflowUrl = this.apiBaseUrl + 'error-inflow';
    topErrorsCountUrl = this.apiBaseUrl + 'error-inflow/top-errors-count';
    errorInflowCountUrl = this.apiBaseUrl + 'error-inflow/error-inflow-count';
    dashboardTopFactoryHittersUrl = this.apiBaseUrl + 'error-inflow/top-factory-hitters';
    topFactoryHittersCountUrl = this.apiBaseUrl + 'error-inflow/top-factory-hitters-count';
    dashboardExportExcelTopFactoryHittersUrl = this.apiBaseUrl + 'error-inflow/top-factory-hitters/file/csv';

    // Missing in Tracy
    MITInflowUrl = this.apiBaseUrl + 'MIT/MIT-inflow';
    MITInflowCountUrl = this.apiBaseUrl + 'MIT/MIT-inflow-count';
    TopMITFactoryHittersUrl = this.apiBaseUrl + 'MIT/top-MIT-factory-hitters';
    TopMITFactoryHittersCountUrl = this.apiBaseUrl + 'MIT/top-MIT-factory-hitters-count';


    constructor() { }

    public resubmitErrorUrl(id: number) {
        return this.apiBaseUrl + 'error-record/' + encodeURIComponent(id) + '/resubmission';
    }

    public unarchiveErrorUrl(id: number) {
        return this.apiBaseUrl + 'error-feedback/' + encodeURIComponent(id) + '/unarchive-error';
    }

    public preformattedFeedbackUrl(id: number) {
        return this.apiBaseUrl + 'notification/preformatted-feedback/' + encodeURIComponent(id);
    }
}
