import { ErrorRecord } from "src/app/model/error-record/ErrorRecord";
import { ErrorUpdateActions, ErrorUpdateActionTypes } from "./error-update.actions";

export interface ErrorUpdateState {
    error: ErrorRecord | null;
}

const initialErrorUpdateState: ErrorUpdateState = {
    error: null
}

export function errorUpdateReducer(state: ErrorUpdateState = initialErrorUpdateState, action: ErrorUpdateActions): ErrorUpdateState {
    switch (action.type) {
        case ErrorUpdateActionTypes.LoadErrorSuccess:
            return {
                ...state,
                error: action.payload.errorRecord,
            };
        case ErrorUpdateActionTypes.UpdateErrorSuccess:
            return {
                ...state,
                error: null
            };
        case ErrorUpdateActionTypes.ArchiveErrorSuccess:
            return {
                ...state,
                error: null
            };
        case ErrorUpdateActionTypes.UnarchiveErrorSuccess:
            return {
                ...state,
                error: null
            };
        case ErrorUpdateActionTypes.UpdateErrorFailed:
            if (action.payload.resubmission.error.status === 400) {
                const newError = action.payload.resubmission.error.error as ErrorRecord;
                return {
                    ...state,
                    error: newError
                }
            }
            return state;
        default:
            return state;
    }
}