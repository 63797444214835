import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { TECTStateService } from 'src/app/service/tect-state.service';
import { LoginService } from 'src/app/service/login.service';
import environment from '../../../environments/static';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    deployed: boolean;

    constructor(private formBuilder: FormBuilder, private stateService: TECTStateService,
        private dateAdapter: DateAdapter<Date>, private loginService: LoginService) {
        this.dateAdapter.setLocale('se-SE');
        this.deployed = environment.compiled;
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required]],
        });
    }

    public onSubmit() {
        const formData = this.loginForm.getRawValue();
        this.loginService.performLogin(formData.username);
    }
}
