<p *ngIf="previewReady == false">Creating preview for batch correction...</p>

<div *ngIf="previewReady == true">
    <div style="overflow-y: auto; overflow-x: hidden; height: 65vh !important;">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

            <ng-container matColumnDef="before">
                <th mat-header-cell *matHeaderCellDef> Before
                </th>
                <td mat-cell *matCellDef="let element">
                    <caption>Endpoint</caption>
                    <pre>{{element.endpointBefore}}</pre>
                    <caption>Headers</caption>
                    <pre>{{element.headersBefore | json}}</pre>
                    <caption>Payload</caption>
                    <pre>{{element.payloadBefore | json}}</pre>
                </td>
            </ng-container>

            <ng-container matColumnDef="after">
                <th mat-header-cell *matHeaderCellDef> After
                </th>
                <td mat-cell *matCellDef="let element">
                    <caption>Endpoint</caption>
                    <pre>{{element.endpointAfter}}</pre>
                    <caption>Headers</caption>
                    <pre>{{element.headersAfter | json}}</pre>
                    <caption>Payload</caption>
                    <pre>{{element.payloadAfter | json}}</pre>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
