<div class="div-panel sticky-top">
    <div class="row">
        <div class="col-md-8">
            <div class="input-group">
                <input class="form-control col-md-6" [(ngModel)]="searchValue"
                       style="padding-left: 10px; padding-right: 10px; border-radius: 0.25rem;" type="text"
                       placeholder="Product/Serial Number" aria-label="Search">
                <ng-container *ngIf="!canReadAllErrors">
                    <div class="input-group" style="text-align: left; padding-left: 10px; padding-right: 10px;">
                        <mat-form-field>
                            <mat-select placeholder="Sending unit" [(value)]=errorRecordSearchRequest.sendingUnit>
                                <mat-option *ngFor="let unit of userUnits" [value]="unit">
                                    {{ unit }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
                <div class="input-group input-group-search" (click)=searchErrorRecords()>
                    <div class="input-group-text"><i class="fa fa-search"></i></div>
                </div>
            </div>
        </div>

        <div class="col-md-2">
            <mat-chip-list #chipList>
                <mat-chip style="font-size: 12px; background-color:#47bac1; color:#fff;"
                          matTooltip="{{errorFilter.description}}" *ngFor="let errorFilter of errorFilterArray">
                    {{errorFilter.value}}
                    <i matChipRemove style="color:#fff;" (click)="removeFromSearch(errorFilter)"
                       class="fa fa-times-circle"></i>
                </mat-chip>
            </mat-chip-list>
        </div>

        <div class="col-md-2" style="text-align: right; cursor: pointer !important;">
            <label (click)=showErrorFilterDialog() style="cursor: pointer !important;">
                <i class="fa fa-filter" style="font-size: 20px; margin-top: 10px;"></i> Filter
            </label>
        </div>
    </div>
</div>
