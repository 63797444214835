import { Action } from "@ngrx/store";
import { TopErrorInflowResultRow } from "src/app/model/dashboard/topErrorInflowResultRow";
import { DashboardSearchRequest } from "src/app/model/dashboard/dashboardSearchRequest";
import { ErrorInflowResultRow } from "src/app/model/dashboard/errorInflowResultRow";
import { TopFactoryHittersResultRow } from "src/app/model/dashboard/topFactoryHittersResultRow";

export enum DashboardActionTypes {
    LoadDetailsSearchTopErrors = '[Dashboard] Load Details Search Top Errors',
    LoadDetailsSearchErrorInflow = '[Dashboard] Load Details Search Error Inflow',
    LoadTopErrorsResults = '[Dashboard] Load Top Errors Results',
    LoadTopErrorsResultsSuccess = '[Dashboard] Load Top Errors Results Success',
    ExportErrorInflowToExcel = '[Dashboard] Export Error Inflow To Excel',
    ExportErrorInflowToExcelSuccess = '[Dashboard] Export Error Inflow To Excel Success',
    ExportTopErrorsToExcel = '[Dashboard] Export Top Errors To Excel',
    ExportTopErrorsToExcelSuccess = '[Dashboard] Export Top Errors To Excel Success',
    LoadErrorInflowResults = '[Dashboard] Load Error Inflow Results',
    LoadErrorInflowResultsSuccess = '[Dashboard] Load Error Inflow Results Success',
    LoadTopFactoryHittersResults = '[Dashboard] Load Top Factory Hitters Results',
    LoadTopFactoryHittersResultsSuccess = '[Dashboard] Load Top Factory Hitters Results Success',
    LoadDetailsSearchTopFactoryHitters = '[Dashboard] Load Details Search Top Factory Hitters',
    ExportTopFactoryHittersToExcel = '[Dashboard] Export Top Factory Hitters To Excel',
    ExportTopFactoryHittersToExcelSuccess = '[Dashboard] Export Top Factory Hitters To Excel Success',
    SetSearchRequests = '[Dashboard] Set Search Requests',
}

export class SetSearchRequestsAction implements Action {
    readonly type = DashboardActionTypes.SetSearchRequests;

    constructor(readonly payload: { request: DashboardSearchRequest }) { }
}

export class LoadTopErrorsResultsAction implements Action {
    readonly type = DashboardActionTypes.LoadTopErrorsResults;

    constructor(readonly payload: { loadCount: boolean }) { }
}

export class LoadTopErrorsResultsSuccessAction implements Action {
    readonly type = DashboardActionTypes.LoadTopErrorsResultsSuccess;

    constructor(readonly payload: {
        rows: TopErrorInflowResultRow[],
        count: number | null
    }) { }
}

export class ExportTopErrorsToExcelAction implements Action {
    readonly type = DashboardActionTypes.ExportTopErrorsToExcel;
}

export class ExportErrorInflowToExcelAction implements Action {
    readonly type = DashboardActionTypes.ExportErrorInflowToExcel;
}

export class ExportErrorInflowToExcelSuccessAction implements Action {
    readonly type = DashboardActionTypes.ExportErrorInflowToExcelSuccess;
}

export class ExportTopErrorsToExcelSuccessAction implements Action {
    readonly type = DashboardActionTypes.ExportTopErrorsToExcelSuccess;
}

export class LoadErrorInflowResultsAction implements Action {
    readonly type = DashboardActionTypes.LoadErrorInflowResults;

    constructor(readonly payload: { loadCount: boolean }) { }
}

export class LoadErrorInflowResultsSuccessAction implements Action {
    readonly type = DashboardActionTypes.LoadErrorInflowResultsSuccess;

    constructor(readonly payload: {
        rows: ErrorInflowResultRow[],
        count: number | null
    }) { }
}

export class LoadDetailsTopErrorsAction implements Action {
    readonly type = DashboardActionTypes.LoadDetailsSearchTopErrors;
    constructor(readonly payload: { row: TopErrorInflowResultRow }) { }
}

export class LoadDetailsErrorInflowAction implements Action {
    readonly type = DashboardActionTypes.LoadDetailsSearchErrorInflow;

    constructor(readonly payload: { row: ErrorInflowResultRow }) { }
}

export class LoadTopFactoryHittersResultsAction implements Action {
    readonly type = DashboardActionTypes.LoadTopFactoryHittersResults;

    constructor(readonly payload: { loadCount: boolean }) { }
}

export class LoadTopFactoryHittersResultsSuccessAction implements Action {
    readonly type = DashboardActionTypes.LoadTopFactoryHittersResultsSuccess;

    constructor(readonly payload: {
        rows: TopFactoryHittersResultRow[],
        count: number | null
    }) { }
}

export class LoadDetailsSearchTopFactoryHittersAction implements Action {
    readonly type = DashboardActionTypes.LoadDetailsSearchTopFactoryHitters;

    constructor(readonly payload: { row: TopFactoryHittersResultRow }) { }
}

export class ExportTopFactoryHittersToExcelAction implements Action {
    readonly type = DashboardActionTypes.ExportTopFactoryHittersToExcel;
}

export class ExportTopFactoryHittersToExcelSuccessAction implements Action {
    readonly type = DashboardActionTypes.ExportTopFactoryHittersToExcelSuccess;
}

export type DashboardActions =
    LoadDetailsTopErrorsAction |
    LoadDetailsErrorInflowAction |
    LoadTopErrorsResultsAction |
    LoadTopErrorsResultsSuccessAction |
    ExportErrorInflowToExcelAction |
    ExportErrorInflowToExcelSuccessAction |
    ExportTopErrorsToExcelAction |
    ExportTopErrorsToExcelSuccessAction |
    LoadErrorInflowResultsAction |
    LoadErrorInflowResultsSuccessAction |
    SetSearchRequestsAction |
    ExportTopErrorsToExcelSuccessAction |
    LoadTopFactoryHittersResultsAction |
    LoadTopFactoryHittersResultsSuccessAction |
    ExportTopFactoryHittersToExcelAction |
    ExportTopFactoryHittersToExcelSuccessAction |
    LoadDetailsSearchTopFactoryHittersAction;
