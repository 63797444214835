export function getObjectKeys(o: Object): string[] {
    if (isObject(o)) {
        return extractFromObject(o, '');
    }

    return [];
}

function extractFromObject(o: Object, prefix: string): string[] {
    let keys = [];

    Object.keys(o).forEach((key: string) => {
        const val = o[key];

        const isObj = isObject(val);
        const isArr = isArray(val);
        const isStr = isString(val);

        if (isArr) {
            extractFromArray(val, `${prefix}${key}`).
                forEach(nestedKey => keys.push(nestedKey));
        } else if (isObj && !isStr) {
            extractFromObject(val, `${prefix}${key}.`).
                forEach(nestedKey => keys.push(nestedKey));
        } else {
            keys.push(`${prefix}${key}`);
        }
    });

    return keys;
}

function extractFromArray(a: any[], prefix: string) {
    let keys = [];

    for (let i: number = 0; i < a.length; i++) {
        const val = a[i];

        const isObj = isObject(val);
        const isArr = isArray(val);
        const isStr = isString(val);

        if (isArr) {
            extractFromArray(val, `${prefix}[${i}]`).
                forEach(nestedKey => keys.push(nestedKey));
        } else if (isObj && !isStr) {
            extractFromObject(val, `${prefix}[${i}].`).
                forEach(nestedKey => keys.push(nestedKey));
        } else {
            keys.push(`${prefix}[${i}]`);
        }
    }

    return keys;
}

function isString(o: any) {
    return (typeof o === 'string' || o instanceof String);
}

function isObject(o: any): boolean {
    return Object.keys(o).length > 0;
}

function isArray(o: any): boolean {
    return Array.isArray(o);
}