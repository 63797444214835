import { Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { ErrorInflowSearchResult } from 'src/app/state/dashboard/dashboard.reducers';
import { MITInflowSearchResult } from 'src/app/state/missing-in-tracy/missing-in-tracy.reducers';
import { getInitialEmptyState } from 'src/test-utils/store';

@Component({
    selector: 'app-MIT-inflow',
    templateUrl: './missing-in-tracy-inflow.component.html',
    styleUrls: ['./missing-in-tracy-inflow.component.scss']
})
export class MITInflowComponent {

    displayedColumns: string[] =
        [
            'period',
            'totalMIT',
            'remainingMIT',
            'percentHealed',
        ];

    @Input() MITInflowResult: MITInflowSearchResult;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private store: Store<AppState>) { }
}
