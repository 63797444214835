import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors, FormArray } from '@angular/forms';
import { CorrectionStepTypeNames, CorrectionStepType } from 'src/app/model/batch-correction/CorrectionStepType';
import { CorrectionStep } from 'src/app/model/batch-correction/CorrectionStep';
import { ModificationPositionNames, ModificationPosition } from 'src/app/model/batch-correction/ModificationPostition';
import { CorrectionFieldType } from 'src/app/model/batch-correction/CorrectionFieldType';

const HEADER_FIELD = 'headerField';
const PAYLOAD_FIELD = 'payloadField';
const PATH_PART = 'pathPath';

@Component({
    selector: 'app-batch-process-step-form',
    templateUrl: './batch-process-step-form.component.html',
    styleUrls: ['./batch-process-step-form.component.scss'],
})

export class BatchProcessStepFormComponent {

    @Input() fieldTypeValues: CorrectionFieldType;
    @Output() newStepDefined: EventEmitter<CorrectionStep> = new EventEmitter<CorrectionStep>();
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    correctionFieldTypes: [string, string][] = [
        [HEADER_FIELD, 'Header Field'],
        [PAYLOAD_FIELD, 'Payload Field'],
        [PATH_PART, 'Endpoint path']
    ];

    private hasSubmitted = false;

    correctionStepTypeOptions = Array.from(CorrectionStepTypeNames).map(
        ([key, value]) => ({ key: CorrectionStepType[key], value })
    );

    modificationPositionOptions = Array.from(ModificationPositionNames).map(
        ([key, value]) => ({ key: ModificationPosition[key], value })
    );

    stepForm = new FormGroup({
        // Common for all steps
        commonInputs: new FormGroup({
            stepType: new FormControl('', [Validators.required]),
            correctionFieldType: new FormControl('', [Validators.required]),
            headerFieldName: new FormControl(''),
            payloadFieldName: new FormControl(''),
            pathPart: new FormControl('')
        }, { validators: addFieldTypeValidator }),

        addCharacterInputs: new FormGroup({
            charactersToAdd: new FormControl(''),
            addCharactersAt: new FormControl('')
        }),

        removeCharacterInputs: new FormGroup({
            removeCharactersAt: new FormControl(''),
            charactersToRemove: new FormControl(1),
        }),

        replaceCharacterInputs: new FormGroup({
            charactersToReplace: new FormArray([
                new FormControl('')
            ]),
            charactersToAdd: new FormControl(''),
        }),

        copyOtherFieldInputs: new FormGroup({
            replacementFieldName: new FormControl('')
        }),

        setToSameValueInputs: new FormGroup({
            charactersToAdd: new FormControl('')
        }),

    }, { validators: addBatchStepValidator });

    constructor() { }

    onSubmit() {
        this.hasSubmitted = true;
        if (this.stepForm.valid) {
            const step = this.buildModelFromForm(this.stepForm.value);
            this.newStepDefined.emit(step);
        }
    }

    onDiscardClicked() {
        this.cancel.emit();
    }

    addCharacterToReplace() {
        this.charactersToReplace.push(new FormControl(''));
    }

    removeCharacterToReplace(index: number) {
        this.charactersToReplace.removeAt(index);
    }

    private isStepType(type: CorrectionStepType): boolean {
        return this.stepForm.value.commonInputs.stepType === CorrectionStepType[type];
    }

    private get commonInputsReady(): boolean {
        const commonInputs = this.stepForm.get('commonInputs');

        const basicsSelected = (
            commonInputs.get('stepType').value &&
            commonInputs.get('correctionFieldType').value
        );

        if (basicsSelected) {
            if (this.showHeaderField) {
                return !!(commonInputs.get('headerFieldName').value);
            }
            if (this.showPayloadField) {
                return !!(commonInputs.get('payloadFieldName').value);
            }
            if (this.showEndpointPathPart) {
                return numberSelectHasValue(commonInputs.get('pathPart').value);
            }
        }

        return false;
    }

    private buildModelFromForm(formValues: any): CorrectionStep {
        let step = {
            stepType: formValues.commonInputs.stepType
        } as CorrectionStep;

        // Only add pathpart or payload field name. Not both, that will cause the backend to break.
        if (this.showPayloadField) {
            step.payloadFieldName = formValues.commonInputs.payloadFieldName;
        } else if (this.showEndpointPathPart) {
            step.pathPart = formValues.commonInputs.pathPart;
        } else if (this.showHeaderField) {
            step.headerFieldName = formValues.commonInputs.headerFieldName;
        }

        switch (step.stepType) {
            case CorrectionStepType[CorrectionStepType.AddCharacters]:
                step = {
                    ...step,
                    ...formValues.addCharacterInputs,
                };
                break;
            case CorrectionStepType[CorrectionStepType.RemoveCharacters]:
                step = {
                    ...step,
                    ...formValues.removeCharacterInputs,
                };
                break;
            case CorrectionStepType[CorrectionStepType.ReplaceCharacters]:
                step = {
                    ...step,
                    ...formValues.replaceCharacterInputs,
                };
                break;
            case CorrectionStepType[CorrectionStepType.CopyOtherField]:
                step = {
                    ...step,
                    ...formValues.copyOtherFieldInputs,
                };
                break;
            case CorrectionStepType[CorrectionStepType.SetToSameValue]:
                step = {
                    ...step,
                    ...formValues.setToSameValueInputs,
                };
                break;
        }
        return step;
    }

    get showHeaderField(): boolean {
        return this.stepForm.value.commonInputs.correctionFieldType === HEADER_FIELD;
    }

    get showPayloadField(): boolean {
        return this.stepForm.value.commonInputs.correctionFieldType === PAYLOAD_FIELD;
    }

    get showEndpointPathPart(): boolean {
        return this.stepForm.value.commonInputs.correctionFieldType === PATH_PART;
    }

    get showAddCharacterInputs(): boolean {
        return this.isStepType(CorrectionStepType.AddCharacters) && this.commonInputsReady;
    }

    get showRemoveCharacterInputs(): boolean {
        return this.isStepType(CorrectionStepType.RemoveCharacters) && this.commonInputsReady;
    }

    get showReplaceCharacterInputs(): boolean {
        return this.isStepType(CorrectionStepType.ReplaceCharacters) && this.commonInputsReady;
    }

    get showCopyOtherFieldInputs(): boolean {
        return this.isStepType(CorrectionStepType.CopyOtherField) && this.commonInputsReady;
    }

    get showSetToSameValueInputs(): boolean {
        return this.isStepType(CorrectionStepType.SetToSameValue) && this.commonInputsReady;
    }

    get submitDisabled(): boolean {
        return this.hasSubmitted && !this.stepForm.valid;
    }

    get incompleteBatchStep(): boolean {
        return this.stepForm.errors?.incompleteBatchStep && this.hasSubmitted;
    }

    get incompleteFieldType(): boolean {
        return this.stepForm.get('commonInputs').errors?.incompleteFieldType && this.hasSubmitted;
    }

    /**
     * Getters for fields. This is a bit of boilerplate, but it cleans up the template code
     * by a lot.
     */
    get stepType(): AbstractControl {
        return this.stepForm.get('commonInputs').get('stepType');
    }

    get correctionFieldType(): AbstractControl {
        return this.stepForm.get('commonInputs').get('correctionFieldType');
    }

    get headerFieldName(): AbstractControl {
        return this.stepForm.get('commonInputs').get('headerFieldName');
    }

    get payloadFieldName(): AbstractControl {
        return this.stepForm.get('commonInputs').get('payloadFieldName');
    }

    get pathPart(): AbstractControl {
        return this.stepForm.get('commonInputs').get('pathPart');
    }

    get charactersToReplace(): FormArray {
        return this.stepForm.get('replaceCharacterInputs').get('charactersToReplace') as FormArray;
    }
}

/*
 * Depending on which step type is selected, different fields are required.
 * This needs to be defined on the top level of the form.
 */
const addFieldTypeValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const type = control.get('correctionFieldType').value;
    let field: AbstractControl = null;
    let otherField: AbstractControl = null;
    let invalid = false;

    if (type === PATH_PART) {
        field = control.get('pathPart');
        otherField = control.get('payloadFieldName');
        invalid = !numberSelectHasValue(field?.value);
    } else if (type === PAYLOAD_FIELD) {
        field = control.get('payloadFieldName');
        otherField = control.get('pathPart');
        invalid = field?.value === null;
    }

    const error = invalid ? { incompleteFieldType: true } : null;
    field?.setErrors(error);
    otherField?.setErrors(null);
    return error;
};

/*
 * Depending on which step type is selected, different fields are required.
 * This needs to be defined on the top level of the form.
 */
const addBatchStepValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const type = control.get('commonInputs').get('stepType').value;
    let valid = false;

    switch (type) {
        case CorrectionStepType[CorrectionStepType.AddCharacters]:
            valid =
                control.get('addCharacterInputs').get('charactersToAdd').value &&
                control.get('addCharacterInputs').get('addCharactersAt').value;
            break;
        case CorrectionStepType[CorrectionStepType.RemoveCharacters]:
            valid =
                control.get('removeCharacterInputs').get('removeCharactersAt').value &&
                control.get('removeCharacterInputs').get('charactersToRemove').value > 0;
            break;
        case CorrectionStepType[CorrectionStepType.ReplaceCharacters]:
            const formArray = control.get('replaceCharacterInputs').get('charactersToReplace') as FormArray;
            const values: string[] = formArray.controls.map(c => c.value);
            valid =
                !values.includes('') &&
                control.get('replaceCharacterInputs').get('charactersToAdd').value;
            break;
        case CorrectionStepType[CorrectionStepType.CopyOtherField]:
            valid =
                control.get('copyOtherFieldInputs').get('replacementFieldName').value;
            break;
        case CorrectionStepType[CorrectionStepType.SetToSameValue]:
            valid =
                control.get('setToSameValueInputs').get('charactersToAdd').value;
            break;
    }

    return !valid ? { incompleteBatchStep: true } : null;
};

const numberSelectHasValue = (value: any): boolean => {
    return value !== null && value !== '';
};
