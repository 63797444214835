
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiDataService } from '../service/api-data.service';
import { Observable } from 'rxjs';
import { LegacyError } from '../model/legacy-errors/LegacyError';

@Injectable({ providedIn: 'root' })
export class LegacyErrorResolver implements Resolve<LegacyError> {
    constructor(private apiDataService: ApiDataService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.paramMap.get('id');
        return this.apiDataService.getLegacyError(+id);
    }
}