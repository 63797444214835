<app-spinner-loader></app-spinner-loader>

<div class="div-panel sticky-top">
  <app-duplicate-filter (searchClicked)="performSearch($event)" (downloadClicked)="downloadExcel($event)">
  </app-duplicate-filter>
</div>

<div class="row" *ngIf="duplicateReport.length > 0">
  <div class="col-sm-12">

    <div style="margin-top:-2px; overflow-y: auto; overflow-x: hidden; height: 65vh !important;">
      <table mat-table id="report-table" matSort (matSortChange)="onSortData($event)" [dataSource]="dataSource" matSort
        class="mat-elevation-z8">

        <ng-container matColumnDef="year" *ngIf="showingGroupedReport">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Year</th>
          <td mat-cell *matCellDef="let element"> {{element.year}} </td>
        </ng-container>

        <ng-container matColumnDef="month" *ngIf="showingGroupedReport">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Month</th>
          <td mat-cell *matCellDef="let element"> {{element.month}} </td>
        </ng-container>

        <ng-container matColumnDef="sendingUnit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sending Unit</th>
          <td mat-cell *matCellDef="let element"> {{element.sendingUnit}} </td>
        </ng-container>

        <ng-container matColumnDef="count">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Duplicates</th>
          <td mat-cell *matCellDef="let element">{{element.count}} </td>
        </ng-container>

        <tr mat-header-row class="centre" *matHeaderRowDef="statisticTableHeader; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: statisticTableHeader;"></tr>
      </table>
    </div>
  </div>
</div>