import { Component, Input, OnInit, SimpleChanges } from '@angular/core'; import { TopErrorsSearchResult } from 'src/app/state/dashboard/dashboard.reducers';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';

@Component({
    selector: 'app-MIT-top-factory-hitters-bar-chart',
    templateUrl: './missing-in-tracy-top-factory-hitters-bar-chart.component.html',
    styleUrls: ['./missing-in-tracy-top-factory-hitters-bar-chart.component.scss']
})
export class TopMITFactoryHittersBarChartComponent implements OnInit {
    constructor() { }

    public barChartOptions: ChartOptions = {
        scales: {
            yAxes: [{
                id: "bar-y-axis1",
                stacked: false,
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        responsive: true,
        maintainAspectRatio: true
    };

    @Input() barChartData: any[];
    @Input() barChartLabels: string[];

    public barChartType = 'bar';
    public barChartLegend = false;

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
    }
}
