import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from '../service/lookup.service';
import { TopErrorsSearchResult } from '../state/dashboard/dashboard.reducers';

@Pipe({
    name: 'topErrorResultLabelsChart'
})

export class TopErrorResultLabelsChartPipe implements PipeTransform {

    constructor(private lookupService: LookupService) { }

    transform(result: TopErrorsSearchResult, ...args: any): string[] {
        let barChartLabels = [];
        for (let row of result.rows) {
            barChartLabels.push(this.lookupService.translateErrorCategory(row.errorCategory))
        }
        return barChartLabels
    }
}
