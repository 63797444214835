import { Component, OnInit, ViewChild } from '@angular/core';
import { Policies } from 'src/constants';
import { ErrorSearchResultRow } from 'src/app/model/error-search/ErrorSearchResultRow';
import { ErrorRecordSearchRequest } from 'src/app/model/error-search/ErrorRecordSearchRequest';
import { LoginService } from 'src/app/service/login.service';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as ErrorDetailsActions from '../../state/error-details/error-details.actions';
import * as PageTitleActions from '../../state/page-title/page-title.actions';
import { ErrorDetailsSearchResult } from 'src/app/state/error-details/error-details.reducers';
import { Observable } from 'rxjs';
import {
  selectErrorDetailsRequest,
  selectErrorDetailsResult,
  selectErrorDetailsPageIndex,
  selectErrorDetailsPageSize,
  selectErrorDetailsSelectedRows,
  selectErrorDetailsAllOnScreenSelected,
  selectErrorDetailsAllRowsSelected,
  selectErrorDetailsCanBatchCorrect,
  selectErrorDetailsCanSelectAll,
  selectErrorDetailsSelectedCount
} from 'src/app/state/error-details/error-details.selectors';

@Component({
  selector: 'app-error-list',
  templateUrl: './error-details.component.html',
  styleUrls: ['./error-details.component.scss']
})

export class ErrorDetailsComponent implements OnInit {

  pageSizeOptions: number[] = [20, 50, 100, 500, 2000];

  displayedColumns: string[] =
    [
      'archiveCheckbox',
      'serialNumberReferences',
      'productNumberReferences',
      'events',
      'sendingUnit',
      'errorCategories',
      'creationDate',
    ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // NgRx Observables from store
  searchRequest$: Observable<ErrorRecordSearchRequest>;
  searchResults$: Observable<ErrorDetailsSearchResult>;
  pageIndex$: Observable<number>;
  pageSize$: Observable<number>;
  selectedRows$: Observable<boolean[]>;
  allOnScreenSelected$: Observable<boolean>;
  allRowsSelected$: Observable<boolean>;
  canBatchCorrect$: Observable<boolean>;
  canSelectAll$: Observable<boolean>;
  selectedCount$: Observable<number>;

  constructor(
    private loginService: LoginService,
    private store: Store<AppState>) {

  }

  ngOnInit() {

    this.store.dispatch(new PageTitleActions.SetPageTitleAction({ pageTitle: 'Error Details' }));

    // NgRx observables from store
    this.searchRequest$ = this.store.select(selectErrorDetailsRequest);
    this.searchResults$ = this.store.select(selectErrorDetailsResult);
    this.pageIndex$ = this.store.select(selectErrorDetailsPageIndex);
    this.pageSize$ = this.store.select(selectErrorDetailsPageSize);
    this.selectedRows$ = this.store.select(selectErrorDetailsSelectedRows);
    this.allOnScreenSelected$ = this.store.select(selectErrorDetailsAllOnScreenSelected);
    this.allRowsSelected$ = this.store.select(selectErrorDetailsAllRowsSelected);
    this.canBatchCorrect$ = this.store.select(selectErrorDetailsCanBatchCorrect);
    this.canSelectAll$ = this.store.select(selectErrorDetailsCanSelectAll);
    this.selectedCount$ = this.store.select(selectErrorDetailsSelectedCount);

    if (!this.loginService.acceptedByPolicy(Policies.CanUpdateErrors)) {
      this.displayedColumns.shift();
    }
  }

  performSearch(event: ErrorRecordSearchRequest) {
    // Set the search request in the store. Then dispatch actions to perform the
    // search.
    this.store.dispatch(new ErrorDetailsActions.SetSearchRequestAction({ request: event }));
    this.store.dispatch(new ErrorDetailsActions.LoadResultsAction({ loadCount: true }));
  }

  onErrorRowClicked(currentRow: ErrorSearchResultRow) {
    this.store.dispatch(new ErrorDetailsActions.UpdateErrorAction({ id: currentRow.id }));
  }

  exportErrorsToExcel() {
    this.store.dispatch(new ErrorDetailsActions.ExportToExcelAction());
  }

  tableRowChecked(index: number) {
    this.store.dispatch(new ErrorDetailsActions.ToggleRowSelectionAction({ index }));
  }

  selectAllCheckBoxes() {
    this.store.dispatch(new ErrorDetailsActions.SelectAllRowsAction());
  }

  toggleAllOnScreenSelected() {
    this.store.dispatch(new ErrorDetailsActions.ToggleSelectAllVisibleAction());
  }

  archiveSelectedErrors() {
    this.store.dispatch(new ErrorDetailsActions.ArchiveErrorsAction());
  }

  batchCorrectCheckedErrors() {
    this.store.dispatch(new ErrorDetailsActions.StartBatchCorrectionAction());
  }

  loadMoreRows(event?: PageEvent) {
    this.store.dispatch(
      new ErrorDetailsActions.PaginateAction({
        pageIndex: event.pageIndex,
        pageSize: event.pageSize
      })
    );
    this.store.dispatch(new ErrorDetailsActions.LoadResultsAction({ loadCount: false }));

    return event;
  }

  get allowedToBatchCorrect(): boolean {
    return this.loginService.acceptedByPolicy(Policies.CanDoBatchCorrection);
  }
}

export interface ErrorFilter {
  name: string;
  value: string;
  description?: string;
}
