import { Action } from '@ngrx/store';

export enum PageTitleActionTypes {
    PageTitle = '[PageTitle] Page Title',
}

export class SetPageTitleAction implements Action {
    readonly type = PageTitleActionTypes.PageTitle;
    constructor(readonly payload: { pageTitle: string }) { }
}

export type PageTitleActions = SetPageTitleAction;
