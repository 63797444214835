import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NavigateToPageAction, NavigationActionTypes } from './navigation.actions';



@Injectable()
export class NavigationEffects {

  @Effect({ dispatch: false })
  navigateToPage$ = this.actions$
    .pipe(
      ofType<NavigateToPageAction>(NavigationActionTypes.NavigateToPage),
      mergeMap(action => {
        this.router.navigate(action.payload.route);
        return of({})
      })
    );

  constructor(
    private actions$: Actions,
    private router: Router
  ) { }
}
