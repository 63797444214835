import { formatDate } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DashboardFilterSelection } from 'src/app/model/dashboard/dashboardFilterSelection';
import { DashboardSearchRequest } from 'src/app/model/dashboard/dashboardSearchRequest';
import { ErrorCategoryPipe } from 'src/app/pipe/error-category.pipe';
import { EventCodePipe } from 'src/app/pipe/event-code.pipe';
import { LookupService } from 'src/app/service/lookup.service';
import { ErrorFilter } from '../../error-details/error-details.component';
import { DashboardFilterComponent } from '../dashboard-filter/dashboard-filter.component';

@Component({
    selector: 'app-dashboard-search',
    templateUrl: './dashboard-search.component.html',
    styleUrls: ['./dashboard-search.component.scss']
})
export class DashboardSearchComponent implements OnInit {

    @Input() set initialSearchRequest(value: DashboardSearchRequest) {
        this.searchRequest = {
            ...value
        } as DashboardSearchRequest;

        this.refreshErrorFilter();
    }

    @Output() performSearch = new EventEmitter<DashboardSearchRequest>();

    public currentDate: string;
    public dateGroupingOptions = [];
    public searchRequest: DashboardSearchRequest;
    public filterArray: ErrorFilter[] = [];

    constructor(private lookupService: LookupService,
        private dialog: MatDialog,
        private errorCategoryPipe: ErrorCategoryPipe,
        private eventCodePipe: EventCodePipe) {
        this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }

    ngOnInit(): void {
        this.dateGroupingOptions = this.lookupService.getDateGroupingOptions();
    }

    toDateChange(event) {
        this.searchRequest.toDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
    }

    fromDateChange(event) {
        this.searchRequest.fromDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
    }

    dateGroupingOptionChanged(event) {
        this.searchRequest.dateGrouping = event.value;
    }

    searchErrors() {
        this.performSearch.emit(this.searchRequest);
    }

    showFilterDialog() {
        const currentSearchRequest = this.searchRequest;
        const dialogRef = this.dialog.open(DashboardFilterComponent, {
            minHeight: '70%',
            maxHeight: '80%',
            maxWidth: '60%',
            minWidth: '20%',
            data: currentSearchRequest
        });

        dialogRef.afterClosed().subscribe((response: DashboardFilterSelection) => {
            if (response) {
                this.searchRequest = {
                    ...this.searchRequest,
                    ...response
                };

                this.refreshErrorFilter();
                this.searchErrors();
            }
        });

    }

    public refreshErrorFilter() {
        this.filterArray = [];

        if (this.searchRequest.eventType) {
            this.filterArray.push({
                name: 'eventType', value: `Event code: ${this.eventCodePipe.transform(this.searchRequest.eventType)}`
            });
        }

        if (this.searchRequest.sendingUnit) {
            this.filterArray.push({
                name: 'sendingUnit', value: `Sending unit: ${this.searchRequest.sendingUnit}`
            });
        }

        if (this.searchRequest.errorCategory) {
            this.filterArray.push({
                name: 'errorCategory', value: `Error category: ${this.errorCategoryPipe.transform(this.searchRequest.errorCategory)}`
            });
        }

        if (this.searchRequest.productNumber) {
            this.filterArray.push({
                name: 'productNumber', value: `Product number: ${this.searchRequest.productNumber}`
            });
        }

        if (this.searchRequest.revisionState) {
            this.filterArray.push({
                name: 'revisionState', value: `Revision state: ${this.searchRequest.revisionState}`
            });
        }

        if (this.searchRequest.fromDate) {
            this.filterArray.push({
                name: 'fromDate', value: `From: ${this.searchRequest.fromDate}`
            });
        }

        if (this.searchRequest.toDate) {
            this.filterArray.push({
                name: 'toDate', value: `To: ${this.searchRequest.toDate}`
            });
        }
    }

    removeFromErrorFilter(filterParam: ErrorFilter) {
        const index = this.filterArray.findIndex(x => x.name === filterParam.name);

        if (index >= 0) {
            this.filterArray.splice(index, 1);
            this.searchRequest[filterParam.name] = null;
        }
    }

    removeFromSearch(searchParam) {
        this.removeFromErrorFilter(searchParam);
        this.searchErrors();
    }
}
