import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { ArchiveErrorRequest } from "src/app/model/error-record/ArchiveErrorRequest";
import { ErrorRecord } from "src/app/model/error-record/ErrorRecord";
import { ApiDataService } from "src/app/service/api-data.service";
import { AppState } from "..";
import { LoadResultsAction } from "../error-details/error-details.actions";
import { NavigateToPageAction } from "../navigation/navigation.actions";
import { ErrorNotificationAction } from "../notification/notification.actions";
import {
    ArchiveErrorAction,
    ArchiveErrorSuccessAction,
    ErrorUpdateActionTypes,
    LoadErrorAction,
    LoadErrorSuccessAction,
    SendEmailAction,
    SendEmailSuccessAction,
    UnarchiveErrorAction,
    UnarchiveErrorSuccessAction,
    UpdateErrorAction,
    UpdateErrorFailedAction,
    UpdateErrorSuccessAction
} from "./error-update.actions";
import { selectError } from "./error-update.selectors";

const rxAddChildren = /^\/api\/public\/v1\/individual\/([^\/]+)\/children$/
const rxRemoveChildren = /^\/api\/public\/v1\/individual\/([^\/]+)\/children\/to-decouple$/

function patchPayload(errorRecord: ErrorRecord, patcher: (payload: any) => any): ErrorRecord {
    const realPayload = {
        ...errorRecord.payload.payload,
        ...patcher(errorRecord.payload.payload)
    }

    const payload = {
        ...errorRecord.payload,
        payload: {
            ...realPayload
        }
    }

    return {
        ...errorRecord,
        payload: payload
    };
}

function patchAndHack(errorRecord: ErrorRecord) {
    if (!errorRecord.call || !errorRecord.payload || !errorRecord.payload.payload)
        return errorRecord;

    if (errorRecord.call.method === "POST" && errorRecord.call.endpoint === "/api/public/v1/individual" || // B-record
        errorRecord.call.method === "POST" && rxAddChildren.test(errorRecord.call.endpoint) || // SA-record
        errorRecord.call.method === "DELETE" && rxRemoveChildren.test(errorRecord.call.endpoint)) /* SD-record */ {
        // Make sure we remove all "blank" child serial numbers
        return patchPayload(errorRecord, payload => (
            {
                ...payload,
                childSerialNumbers: payload.childSerialNumbers?.map(csn => csn?.trim()).filter(csn => !!csn)
            }));
    }

    return errorRecord;
}


@Injectable()
export class ErrorUpdateEffects {
    @Effect()
    loadError$ = this.actions$
        .pipe(
            ofType<LoadErrorAction>(ErrorUpdateActionTypes.LoadError),
            mergeMap(action => this.apiDataService.getErrorRecord(action.payload.id)
                .pipe(
                    mergeMap(result => [
                        new NavigateToPageAction({ route: [`error-handling/${result.id}`] }),
                        new LoadErrorSuccessAction({ errorRecord: result })
                    ]),
                    catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to load error: ${error.message}` })))
                ))
        );

    @Effect()
    updateError$ = this.actions$
        .pipe(
            ofType<UpdateErrorAction>(ErrorUpdateActionTypes.UpdateError),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                const errorRecord = patchAndHack(selectError(storeState));

                return this.apiDataService.resubmitError(errorRecord.id, action.payload.resubmissionRequest)
                    .pipe(
                        mergeMap(() => [
                            new UpdateErrorSuccessAction(),
                            new LoadResultsAction({ loadCount: true })
                        ]),
                        catchError((error) => of(new UpdateErrorFailedAction({ resubmission: { error, correction: error.error as ErrorRecord } })))
                    );
            })
        );

    @Effect()
    handleError$ = this.actions$
        .pipe(
            ofType<UpdateErrorFailedAction>(ErrorUpdateActionTypes.UpdateErrorFailed),
            mergeMap(() => of(new ErrorNotificationAction({ errorMessage: 'Tracy rejected the resubmission. Correct the errors and try again.' })))
        );

    @Effect()
    archiveError$ = this.actions$
        .pipe(
            ofType<ArchiveErrorAction>(ErrorUpdateActionTypes.ArchiveError),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => {
                const errorRecord = selectError(storeState);
                const request: ArchiveErrorRequest = {
                    errorIds: [errorRecord.id]
                };
                return this.apiDataService.archiveError(request)
                    .pipe(
                        mergeMap(() => {
                            return [
                                new ArchiveErrorSuccessAction(),
                                new LoadResultsAction({ loadCount: true })
                            ];
                        }),
                        catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to archive error: ${error.message}` })))
                    );
            })
        );

    @Effect()
    unarchiveError$ = this.actions$
        .pipe(
            ofType<UnarchiveErrorAction>(ErrorUpdateActionTypes.UnarchiveError),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => {
                const errorRecord = selectError(storeState);
                return this.apiDataService.unarchiveError(errorRecord.id)
                    .pipe(
                        mergeMap(() => [
                            new UnarchiveErrorSuccessAction(),
                            new LoadResultsAction({ loadCount: true })
                        ]),
                        catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to unarchive error: ${error.message}` })))
                    );
            })
        );

    @Effect()
    sendEmail$ = this.actions$
        .pipe(
            ofType<SendEmailAction>(ErrorUpdateActionTypes.SendEmail),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => {
                const errorRecord = selectError(storeState);
                return this.apiDataService.sendEmail(errorRecord.id)
                    .pipe(
                        mergeMap(() => [
                            new SendEmailSuccessAction(),
                            new LoadResultsAction({ loadCount: true })
                        ]),
                        catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to send email: ${error.message}` })))
                    );
            })
        );

    @Effect({ dispatch: false })
    navigateBack$ = this.actions$
        .pipe(
            ofType(ErrorUpdateActionTypes.ArchiveErrorSuccess,
                ErrorUpdateActionTypes.UpdateErrorSuccess,
                ErrorUpdateActionTypes.UnarchiveErrorSuccess,
                ErrorUpdateActionTypes.SendEmailSuccess),
            map(() => new NavigateToPageAction({ route: ['error-handling'] }))
        );

    constructor(
        private actions$: Actions,
        private apiDataService: ApiDataService,
        private store$: Store<AppState>) { }
}
