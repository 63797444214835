import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class SpinnerLoaderService {
    isLoading = new Subject<boolean>();
    show() {
        this.isLoading.next(true);
    }
    hide() {
        this.isLoading.next(false);
    }

    private loadCount = 0;

    public incrementLoadCount() {
        if (this.loadCount++ === 0) {
            this.show();
        }
    }

    public decrementLoadCount() {
        if (--this.loadCount <= 0) {
            this.resetLoader();
        }
    }

    public resetLoader() {
        this.loadCount = 0;
        this.hide();
    }
}
