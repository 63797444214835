import { Pipe, PipeTransform } from '@angular/core';
import { TopFactoryHittersSearchResult } from '../state/dashboard/dashboard.reducers';

@Pipe({
    name: 'topFactoryHittersResultChart'
})

export class TopFactoryHittersResultChartPipe implements PipeTransform {

    constructor() { }

    transform(result: TopFactoryHittersSearchResult, ...args: any): any[] {
        let barChartData = [];
        let dataInflow = []
        for (let row of result.rows) {
            dataInflow.push(row.errors);
        }
        barChartData.push({ data: dataInflow, label: "Errors" });
        return barChartData
    }
}
