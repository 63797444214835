import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ErrorRecordSearchRequest } from 'src/app/model/error-search/ErrorRecordSearchRequest';
import { ApiDataService } from 'src/app/service/api-data.service';
import { LookupService } from 'src/app/service/lookup.service';
import { AppState } from '..';
import * as ErrorDetailsActions from '../error-details/error-details.actions';
import { ErrorNotificationAction } from '../notification/notification.actions';
import { ErrorSummaryActionTypes, LoadResultsAction, LoadResultsSuccessAction, LoadDetailsSearchAction } from './error-summary.actions';
import { selectErrorSummaryRequest } from './error-summary.selectors';



@Injectable()
export class ErrorSummaryEffects {

  /**
   * When a LoadResultsAction is dispatched, call the API to get data from backend
   */
  @Effect()
  loadSummary$ = this.actions$
    .pipe(
      ofType<LoadResultsAction>(ErrorSummaryActionTypes.LoadResults),
      withLatestFrom(this.store$),
      mergeMap(([, storeState]) => this.apiDataService.searchErrorSummary(selectErrorSummaryRequest(storeState))
        .pipe(
          map((searchResult) => {
            return new LoadResultsSuccessAction({ results: searchResult })
          }),
          catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to load summary: ${error.message}` })))
        )
      ),
    );

  /**
   * When a LoadDetailsSearchAction is dispatched, create an error details request with parameters
   * that match the row in the action. Dispatch it to the store, then start the error details search
   * by dispatching an ErrorDetailsActions.LoadResultsAction.
   */
  @Effect()
  searchDetails$ = this.actions$
    .pipe(
      ofType<LoadDetailsSearchAction>(ErrorSummaryActionTypes.LoadDetailsSearch),
      withLatestFrom(this.store$),
      mergeMap(([action, storeState]) => {
        const request = selectErrorSummaryRequest(storeState);
        const clickedRow = action.payload.row;

        const detailsSearchRequest = new ErrorRecordSearchRequest();
        detailsSearchRequest.errorCategory = clickedRow.errorCategory;
        detailsSearchRequest.sendingUnit = clickedRow.sendingUnit;
        detailsSearchRequest.status = this.lookupService.getErrorStatuses()[0]; // TODO: Next step: Move the lookups out of their own service, and into the store
        detailsSearchRequest.toDate = request.toDate;
        detailsSearchRequest.fromDate = request.fromDate;

        return [
          new ErrorDetailsActions.SetSearchRequestAction({ request: detailsSearchRequest }),
          new ErrorDetailsActions.LoadResultsAction({ loadCount: true })
        ];
      })
    );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private apiDataService: ApiDataService,
    private lookupService: LookupService
  ) { }

}
