import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from '../service/lookup.service';
import { TopErrorsSearchResult } from '../state/dashboard/dashboard.reducers';

@Pipe({
    name: 'topErrorResultChart'
})

export class TopErrorResultChartPipe implements PipeTransform {

    constructor(private lookupService: LookupService) { }

    transform(result: TopErrorsSearchResult, ...args: any): any[] {
        let barChartData = [];
        let dataInflow = []
        let dataRemaining = [];
        for (let row of result.rows) {
            dataInflow.push(row.errors);
            dataRemaining.push(row.remainingErrors);
        }
        barChartData.push({ data: dataInflow, label: "Inflow" });
        barChartData.push({ data: dataRemaining, label: "Remaining" });
        return barChartData
    }
}
