<div *ngIf="searchRequest$ | async as request">
    <app-mit-search [initialSearchRequest]="request" (performSearch)="performSearch($event)">
    </app-mit-search>
</div>

<mat-tab-group>
    <mat-tab label="MIT Inflow">
        <div *ngIf="MITInflowResult$ | async as MITInflowResult">
            <div *ngIf="MITInflowResult.rows.length != 0">
                <app-MIT-inflow-bar-chart [barChartData]="MITInflowResult| MITInflowResultChart"
                    [barChartLabels]="MITInflowResult| MITInflowResultLabels"></app-MIT-inflow-bar-chart>
            </div>
            <app-MIT-inflow [MITInflowResult]="MITInflowResult">
            </app-MIT-inflow>
            <div class="empty-result-message" *ngIf="MITInflowResult.rows.length == 0">
                <div style="margin-left: 40% !important;">
                    <i class="fa fa-search " style="font-size: 16.2rem; color: #d0dde7; font-weight: 900;"></i>
                    <p style="margin-top: 5%; width: 180px; font-size: 20px; font-weight: 100;">
                        There are no MIT records which match your search.
                    </p>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="Top MIT Factory Hitters">
        <div *ngIf="topMITFactoryHittersResult$ | async as topMITFactoryHittersResult">
            <div *ngIf="topMITFactoryHittersResult.rows.length != 0">
                <app-MIT-top-factory-hitters-bar-chart
                    [barChartData]="topMITFactoryHittersResult|topMITFactoryHittersResultChart"
                    [barChartLabels]="topMITFactoryHittersResult|topMITFactoryHittersResultLabelsChart">
                </app-MIT-top-factory-hitters-bar-chart>
            </div>
            <app-MIT-top-factory-hitters [topMITFactoryHittersResult]="topMITFactoryHittersResult">
            </app-MIT-top-factory-hitters>
            <div class="empty-result-message" *ngIf="topMITFactoryHittersResult.rows.length == 0">
                <div style="margin-left: 40% !important;">
                    <i class="fa fa-search " style="font-size: 16.2rem; color: #d0dde7; font-weight: 900;"></i>
                    <p style="margin-top: 5%; width: 180px; font-size: 20px; font-weight: 100;">
                        There are no MIT records which match your search.
                    </p>
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>