// An enum
export enum CorrectionStepType {
    AddCharacters,
    RemoveCharacters,
    ReplaceCharacters,
    CopyOtherField,
    SetToSameValue,
}

// A map with enum value -> string
export const CorrectionStepTypeNames: Map<CorrectionStepType, string> = new Map<CorrectionStepType, string>(
    [
        [CorrectionStepType.AddCharacters, 'Add Characters'],
        [CorrectionStepType.RemoveCharacters, 'Remove Characters'],
        [CorrectionStepType.ReplaceCharacters, 'Replace Characters'],
        [CorrectionStepType.CopyOtherField, 'Copy Other Field Value'],
        [CorrectionStepType.SetToSameValue, 'Set to Specific Value']
    ]
);

// A map with string -> string
export const CorrectionStepTypeOptions: Map<string, string> = new Map<string, string>(
    Array.from(CorrectionStepTypeNames).map(([key, value]) => ([key.toString(), value]))
);