import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { TECTStateService } from './tect-state.service';
import { ApiDataService } from './api-data.service';
import { evaluatePolicy } from 'src/constants';
import environmentStatic from "../../environments/static";

export interface Identity {
    username: string;
    roles: string[];
    units?: string[];
}

export type ExternalLoginInProgressCallback = (finished: boolean) => void;
export type LoginCallback = (identity: Identity) => void;
export type LogoutCallback = () => void;

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    private loginObservers = new Array<LoginCallback>();
    private logoutObservers = new Array<LogoutCallback>();

    constructor(
        private stateService: TECTStateService,
        private apiDataService: ApiDataService) { }

    async setupAuthState(): Promise<void> {
        const cookieResult = await this.apiDataService.checkCookie();

        if (cookieResult.success) {
            const identity = {
                username: cookieResult.username,
                roles: cookieResult.roles,
                units: cookieResult.units
            };
            this.stateService.setErrorMessage(null);
            this.stateService.setCurrentIdentity(identity);
            this.notifyLoginObservers(identity);
        } else {
            this.stateService.setCurrentIdentity(null);
            if (environmentStatic.tracyFrontend === null) {
                // This should only occur in debug/local builds!
                this.stateService.setErrorMessage("[DEV] Please log in");
            } else {
                // Cookie did not check out, so let's redirect to Tracy!
                window.location.href = environmentStatic.tracyFrontend;
            }
        }
    }

    async renewToken(): Promise<void> {
        const response = await this.apiDataService.renewToken();
        const identity = {
            username: response.username,
            roles: response.roles,
            units: response.units
        };

        this.stateService.setCurrentIdentity(identity);
    }

    observeLoggedIn(callback: LoginCallback) {
        this.loginObservers.push(callback);
    }

    observeLoggedout(callback: LogoutCallback) {
        this.logoutObservers.push(callback);
    }

    private notifyLoginObservers(identity: Identity) {
        for (const observer of this.loginObservers) {
            observer(identity);
        }
    }

    private notifyLogoutObservers() {
        for (const observer of this.logoutObservers) {
            observer();
        }
    }

    // This will perform a login for TECT, but only in dev mode!
    performLogin(username: string): void {
        browserPost(webApiUrl('authentication/web-user'), {
            username: username,
            replyUrl: replyUrlForAuthentication()
        });
    }

    logout(): void {
        this.notifyLogoutObservers();
        browserPost(environmentStatic.tracyLogoutUrl, {});
    }

    acceptedByPolicy(policy: string[]): boolean {
        return evaluatePolicy(this.currentIdentity, policy);
    }

    get currentIdentity(): Identity | null {
        return this.stateService.getCurrentIdentity();
    }
}

function browserPost(path: string | URL, params: any): void {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', path.toString());

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', params[key]);

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
}

function webApiUrl(path: string): string {
    while (path.length && path.startsWith('/')) {
        path = path.substring(1);
    }

    return environmentStatic.apiBaseUrl + path;
}

function replyUrlForAuthentication(): string {
    // Construct reply URL based on current URL.
    const currentUrl = new URL(window.location.href);
    return `${currentUrl.protocol}//${currentUrl.hostname}${(currentUrl.port ? ':' + currentUrl.port : '')}/`;
}
