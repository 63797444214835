<div>
    <div class="nav-header">
        <span>TECT</span><img src="assets/img/tect-logo.png">
    </div>
    <div class="nav-list-div">
        <div id="accordion" class="accordion">

            <div *ngIf="canReadErrorsInOwnUnits">
                <a class="heading link-margin" routerLinkActive="active" [routerLink]="['/error-summary']"
                    data-toggle="tooltip" data-placement="right">
                    Error Summary</a>
            </div>

            <div *ngIf="canReadErrorsInOwnUnits">
                <a class="heading link-margin" routerLinkActive="active" [routerLink]="['/error-handling']"
                    data-toggle="tooltip" data-placement="right">
                    Error Details</a>
            </div>

            <div *ngIf="canReadLegacyErrors">
                <a class="heading link-margin" routerLinkActive="active" [routerLink]="['/legacy-errors']"
                    data-toggle="tooltip" data-placement="right">
                    Legacy Errors</a>
            </div>

            <!-------------------- Nav sidebar------------------------ -->
            <div *ngIf="canReadStatistics">
                <a class="toggle-heading collapsed" data-toggle="collapse" href="#statistics">Statistics</a>
                <div id="statistics" class="collapse" data-parent="#accordion">
                    <ul>
                        <li class="dropdown-list" routerLinkActive="active" [routerLink]="['/statistics', 'dashboard']">
                            Dashboard
                        </li>
                        <li class="dropdown-list" routerLinkActive="active"
                            [routerLink]="['/statistics', 'missing-in-tracy']">
                            Missing in Tracy
                        </li>
                        <li class="dropdown-list" routerLinkActive="active"
                            [routerLink]="['/statistics', 'duplicates']">Duplicate
                            errors</li>
                        <li class="dropdown-list" routerLinkActive="active"
                            [routerLink]="['/statistics', 'most-common-error']">
                            Most
                            common errors</li>
                    </ul>
                </div>
            </div>

            <div *ngIf="canDoAlgorithmAssistedCorrection">
                <a class="toggle-heading collapsed" data-toggle="collapse" href="#assisted-correction">Assisted
                    Correction</a>
                <div id="assisted-correction" class="collapse" data-parent="#accordion">
                    <ul>
                        <li class="dropdown-list" routerLinkActive="active"
                            [routerLink]="['/algorithm-assisted', 'invalid-serial-number']">
                            Invalid Serial Numbers
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
