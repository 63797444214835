import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './service/auth-guard.service';
import { Policies } from 'src/constants';
import { AppLayoutComponent } from './components/layout/app-layout/app-layout.component';
import { AppDashboardComponent } from './components/layout/app-dashboard/app-dashboard.component';
import { ErrorSummaryComponent } from './components/error-summary/error-summary.component';
import { DuplicateStatisticsComponent } from './components/statistics/duplicate-statistics/duplicate-statistics.component';
import { MostCommonErrorComponent } from './components/statistics/most-common-error/most-common-error.component';
import { InvalidSerialNumberSearchComponent } from './components/algorithm-assisted/invalid-serial-number-search/invalid-serial-number-search.component';
import { LegacyErrorSearchComponent } from './components/legacy-error-search/legacy-error-search.component';
import { LegacyErrorDisplayComponent } from './components/legacy-error-display/legacy-error-display.component';
import { LegacyErrorResolver } from './resolver/legacy-error-resolver';
import { BatchProcessStepperComponent } from './components/batch-process/batch-process-stepper/batch-process-stepper.component';
import { ErrorUpdateComponent } from './components/error-update/error-update.component';
import { ErrorDetailsComponent } from './components/error-details/error-details.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MITComponent } from './components/missing-in-tracy/missing-in-tracy.component';

const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent
    },
    {
        path: '',
        component: AppDashboardComponent,
        data: {
            policy: Policies.AllowAll
        },
        children: [
            {
                path: 'error-summary',
                component: ErrorSummaryComponent,
                data: {
                    policy: Policies.CanReadErrorsInOwnUnits
                },
                canActivate: [AuthGuardService]
            },
            {
                path: 'error-handling',
                component: ErrorDetailsComponent,
                data: {
                    policy: Policies.CanReadErrorsInOwnUnits
                },
                canActivate: [AuthGuardService]
            },
            {
                path: 'error-handling/batch-correct',
                component: BatchProcessStepperComponent,
                data: {
                    policy: Policies.CanDoBatchCorrection
                },
                canActivate: [AuthGuardService]
            },
            {
                path: 'error-handling/:id',
                component: ErrorUpdateComponent,
                data: {
                    policy: Policies.CanReadErrorsInOwnUnits
                },
                canActivate: [AuthGuardService]
            },
            {
                path: 'statistics/dashboard',
                component: DashboardComponent,
                data: {
                    policy: Policies.CanReadStatistics
                },
                canActivate: [AuthGuardService]
            },
            {
                path: 'statistics/missing-in-tracy',
                component: MITComponent,
                data: {
                    policy: Policies.CanReadStatistics
                },
                canActivate: [AuthGuardService]
            },
            {
                path: 'statistics/duplicates',
                component: DuplicateStatisticsComponent,
                data: {
                    policy: Policies.CanReadStatistics
                },
                canActivate: [AuthGuardService]
            },
            {
                path: 'statistics/most-common-error',
                component: MostCommonErrorComponent,
                data: {
                    policy: Policies.CanReadStatistics
                },
                canActivate: [AuthGuardService]
            },
            {
                path: 'legacy-errors',
                component: LegacyErrorSearchComponent,
                data: {
                    policy: Policies.CanReadLegacyErrors
                },
                canActivate: [AuthGuardService]
            },
            {
                path: 'legacy-errors/:id',
                component: LegacyErrorDisplayComponent,
                data: {
                    policy: Policies.CanReadLegacyErrors
                },
                canActivate: [AuthGuardService],
                resolve: {
                    legacyError: LegacyErrorResolver
                }
            },
            {
                path: 'algorithm-assisted/invalid-serial-number',
                component: InvalidSerialNumberSearchComponent,
                data: {
                    policy: Policies.CanDoAlgorithmAssistedCorrection
                },
                canActivate: [AuthGuardService]
            }
        ],
        canActivate: [AuthGuardService]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
