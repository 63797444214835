import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { evaluatePolicy } from 'src/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private loginService: LoginService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    if (!this.loginService.currentIdentity) {
      this.router.navigate(['']);
      return false;
    }

    // This is where we check the policies
    if (!this.loginService.acceptedByPolicy(next.data.policy)) {
      // TODO: use this.loginService.acceptedByPolicy in view models when buttons are to be
      // TODO: hidden or disabled based on policy.
      return false;
    }

    return true;
  }
}
