export enum ModificationPosition {
    StartOfString,
    EndOfString
}

export const ModificationPositionNames: Map<ModificationPosition, string> = new Map<ModificationPosition, string>(
    [
        [ModificationPosition.StartOfString, 'Start of the field'],
        [ModificationPosition.EndOfString, 'End of the field'],
    ]
);