import { Action } from '@ngrx/store';
import { ErrorRecordSearchRequest } from 'src/app/model/error-search/ErrorRecordSearchRequest';
import { ErrorSearchResultRow } from 'src/app/model/error-search/ErrorSearchResultRow';


export enum ErrorDetailsActionTypes {
  // Search result
  LoadResults = '[Error Details] Load Results',
  LoadResultsSuccess = '[Error Details] Load Results Success',
  Paginate = '[Error Details] Paginate',

  // Archiving
  ArchiveErrors = '[Error Details] Archive Errors',
  ArchiveErrorsSuccess = '[Error Details] Archive Errors Success',
  ArchiveAllErrors = '[Error Details] Archive All Errors',
  ArchiveAllErrorsSuccess = '[Error Details] Archive All Errors Success',

  // Selection Handling
  ToggleRowSelection = '[Error Details] Toggle Row Selection',
  ToggleSelectAllVisible = '[Error Details] ToggleSelectAllVisible',
  SelectAllRows = '[Error Details] Select All Rows',
  ClearSelection = '[Error Details] Clear Selection',

  // Export
  ExportToExcel = '[Error Details] Export To Excel',
  ExportToExcelSuccess = '[Error Details] Export To Excel Success',

  // Miscellaneous
  SetSearchRequest = '[Error Details] Set Search Request',
  StartBatchCorrection = '[Error Details] Start Batch Correction',
  UpdateError = '[Error Details] Update Error',
}

// Search result
export class LoadResultsAction implements Action {
  readonly type = ErrorDetailsActionTypes.LoadResults;

  constructor(readonly payload: { loadCount: boolean }) { }
}

export class LoadResultsSuccessAction implements Action {
  readonly type = ErrorDetailsActionTypes.LoadResultsSuccess;

  constructor(readonly payload: {
    rows: ErrorSearchResultRow[],
    count: number | null
  }
  ) { }
}

export class PaginateAction implements Action {
  readonly type = ErrorDetailsActionTypes.Paginate;

  constructor(readonly payload: {
    pageSize: number,
    pageIndex: number
  }) { }
}


// Archiving
export class ArchiveErrorsAction implements Action {
  readonly type = ErrorDetailsActionTypes.ArchiveErrors;
}

export class ArchiveErrorsSuccessAction implements Action {
  readonly type = ErrorDetailsActionTypes.ArchiveErrorsSuccess;
}

export class ArchiveAllErrorsAction implements Action {
  readonly type = ErrorDetailsActionTypes.ArchiveAllErrors;
}

export class ArchiveAllErrorsSuccessAction implements Action {
  readonly type = ErrorDetailsActionTypes.ArchiveAllErrorsSuccess;
}


// Selection Handling
export class ToggleRowSelectionAction implements Action {
  readonly type = ErrorDetailsActionTypes.ToggleRowSelection;

  constructor(readonly payload: { index: number }) { }
}

export class ToggleSelectAllVisibleAction implements Action {
  readonly type = ErrorDetailsActionTypes.ToggleSelectAllVisible;
}

export class SelectAllRowsAction implements Action {
  readonly type = ErrorDetailsActionTypes.SelectAllRows;
}

export class ClearSelectionAction implements Action {
  readonly type = ErrorDetailsActionTypes.ClearSelection;
}

// Export
export class ExportToExcelAction implements Action {
  readonly type = ErrorDetailsActionTypes.ExportToExcel;
}

export class ExportToExcelSuccessAction implements Action {
  readonly type = ErrorDetailsActionTypes.ExportToExcelSuccess;
}

// Miscellaneous
export class SetSearchRequestAction implements Action {
  readonly type = ErrorDetailsActionTypes.SetSearchRequest;

  constructor(readonly payload: { request: ErrorRecordSearchRequest }) { }
}

export class StartBatchCorrectionAction implements Action {
  readonly type = ErrorDetailsActionTypes.StartBatchCorrection;
}

export class UpdateErrorAction implements Action {
  readonly type = ErrorDetailsActionTypes.UpdateError;

  constructor(readonly payload: { id: number }) { }
}

export type ErrorDetailsActions =
  LoadResultsAction |
  LoadResultsSuccessAction |
  PaginateAction |
  ArchiveErrorsAction |
  ArchiveErrorsSuccessAction |
  ArchiveAllErrorsAction |
  ArchiveAllErrorsSuccessAction |
  ToggleRowSelectionAction |
  ToggleSelectAllVisibleAction |
  SelectAllRowsAction |
  ClearSelectionAction |
  SetSearchRequestAction |
  ExportToExcelAction |
  StartBatchCorrectionAction |
  UpdateErrorAction;
