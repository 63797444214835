import { Injectable } from '@angular/core';
import { EnumeratedValue } from '../model/lookup/EnumeratedValue';

@Injectable({
    providedIn: 'root'
})
export class LookupService {

    private errorCategories: EnumeratedValue<string>[] = [];
    private errorStatuses: EnumeratedValue<string>[] = [];
    private eventCodes: EnumeratedValue<string>[] = [];
    private dateGroupingOptions: EnumeratedValue<string>[] = [];
    private policies: EnumeratedValue<string>[] = [];

    constructor() { }

    public setErrorCategories(categories: EnumeratedValue<string>[]) {
        this.errorCategories = categories;
    }

    public translateErrorCategory(key: string): string {
        const category = this.errorCategories.find(c => c.key === key);
        return category?.name ?? 'Unknown';
    }

    public getErrorCategories(): string[] {
        return this.errorCategories.map(c => c.key);
    }

    public setErrorStatuses(statuses: EnumeratedValue<string>[]) {
        this.errorStatuses = statuses;
    }

    public getErrorStatuses(): string[] {
        return this.errorStatuses.map(c => c.key);
    }

    public translateErrorStatus(key: string): string {
        const status = this.errorStatuses.find(c => c.key === key);
        return status?.name ?? 'Unknown';
    }

    public setEventCodes(eventCodes: EnumeratedValue<string>[]) {
        this.eventCodes = eventCodes;
    }

    public getEventCodes(): string[] {
        return this.eventCodes.map(c => c.key);
    }

    public translateEventCode(key: string): string {
        const eventCode = this.eventCodes.find(c => c.key === key);
        return eventCode?.name ?? 'Unknown';
    }

    public setDateGroupingOptions(groupingOptions: EnumeratedValue<string>[]) {
        this.dateGroupingOptions = groupingOptions;
    }

    public getDateGroupingOptions(): string[] {
        return this.dateGroupingOptions.map(o => o.key);
    }

    public setPolicies(policies: EnumeratedValue<string>[]) {
        this.policies = policies;
    }

    public getPolicies(): string[] {
        return this.policies.map(c => c.key);
    }
}
