import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-display-field',
  templateUrl: './display-field.component.html',
  styleUrls: ['./display-field.component.scss']
})
export class DisplayFieldComponent implements OnInit {

  @Input() header: string;
  @Input() value: string;

  public cleanedValue: string = "-";
  constructor() { }

  ngOnInit(): void {
    if (this.value) {
      this.cleanedValue = this.value;
    }
  }
}
