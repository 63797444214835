import { Action } from '@ngrx/store';
import { ErrorSummaryRequest } from 'src/app/model/error-summary/ErrorSummaryRequest';
import { ErrorSummaryResultRow } from 'src/app/model/error-summary/ErrorSummaryResultRow';


export enum ErrorSummaryActionTypes {
    // Search result
    LoadResults = '[Error Summary] Load Results',
    LoadResultsSuccess = '[Error Summary] Load Results Success',

    // Miscellaneous
    SetSearchRequest = '[Error Summary] Set Search Request',
    LoadDetailsSearch = '[Error Summary] Load Details Search'
}

// Search result
export class LoadResultsAction implements Action {
    readonly type = ErrorSummaryActionTypes.LoadResults;

    constructor() { }
}

export class LoadResultsSuccessAction implements Action {
    readonly type = ErrorSummaryActionTypes.LoadResultsSuccess;

    constructor(readonly payload: {
        results: ErrorSummaryResultRow[],
    }) { }
}

// Miscellaneous
export class SetSearchRequestAction implements Action {
    readonly type = ErrorSummaryActionTypes.SetSearchRequest;

    constructor(readonly payload: { request: ErrorSummaryRequest }) { }
}

export class LoadDetailsSearchAction implements Action {
    readonly type = ErrorSummaryActionTypes.LoadDetailsSearch;

    constructor(readonly payload: { row: ErrorSummaryResultRow }) { }
}

export type ErrorSummaryActions =
    LoadResultsAction |
    LoadResultsSuccessAction |
    SetSearchRequestAction |
    LoadDetailsSearchAction;
