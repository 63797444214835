import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NotificationService } from 'src/app/service/notification.service';
import { ErrorNotificationAction, NotificationActionTypes } from './notification.actions';



@Injectable()
export class NotificationEffects {

  @Effect({ dispatch: false })
  errorNotification$ = this.actions$
    .pipe(
      ofType<ErrorNotificationAction>(NotificationActionTypes.ErrorNotification),
      mergeMap(action => {
        this.notificationService.showError(action.payload.errorMessage);
        return of({})
      })
    );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService
  ) { }
}
