<div *ngIf="searchRequest$ | async as searchRequest">
  <app-error-summary-search [initialSearchRequest]="searchRequest" (performSearch)="performSearch($event)">
  </app-error-summary-search>
</div>

<!---------------------------------- Error Summary Table -------------------------------------------->

<div *ngIf="searchResults$ | async as searchResults">

  <div *ngIf="searchResults.length > 0" style="overflow-y: auto; overflow-x: hidden; height: 65vh !important;">

    <table mat-table [dataSource]="searchResults" matSort class="mat-elevation-z8">

      <!-- Sending Unit -->
      <ng-container matColumnDef="sendingUnit">
        <th mat-header-cell *matHeaderCellDef> Sending Unit </th>
        <td mat-cell *matCellDef="let element">
          {{element.sendingUnit}}
        </td>
      </ng-container>

      <!-- Error category -->
      <ng-container matColumnDef="errorCategory">
        <th mat-header-cell *matHeaderCellDef> Error Category </th>
        <td mat-cell *matCellDef="let element">
          {{ element.errorCategory | errorCategory }}
        </td>
      </ng-container>

      <!-- Sending Unit -->
      <ng-container matColumnDef="remainingErrors">
        <th mat-header-cell *matHeaderCellDef> Remaining Errors </th>
        <td mat-cell *matCellDef="let element">
          {{element.remainingErrors}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToDetails(row)"></tr>
    </table>
  </div>

  <!----------------------------------Show error message when there are no records to display----------------->
  <div class="empty-result-message" *ngIf="searchResults.length == 0">
    <div style="margin-left: 40% !important;">
      <i class="fa fa-search " style="font-size: 16.2rem; color: #d0dde7; font-weight: 900;"></i>
      <p style="margin-top: 5%; width: 180px; font-size: 20px; font-weight: 100;">
        There are no results which match your search.
      </p>
    </div>
  </div>
</div>