import { ErrorRecordSearchRequest } from '../../model/error-search/ErrorRecordSearchRequest';
import { ErrorSearchResultRow } from '../../model/error-search/ErrorSearchResultRow';
import { ErrorDetailsActions, ErrorDetailsActionTypes } from './error-details.actions';
import * as _ from 'lodash';
import { addMonths, format } from 'date-fns';

// --- STATE STRUCTURE ---

export interface ErrorDetailsState {
    request: ErrorRecordSearchRequest | null;
    results: ErrorDetailsSearchResult | null;
    rowSelection: RowSelection;
}

export interface ErrorDetailsSearchResult {
    rows: ErrorSearchResultRow[] | null;
    count: number;
}

export interface RowSelection {
    selectedRows: boolean[];
    allOnScreenSelected: boolean;
    allRowsSelected: boolean;
}

const initialErrorDetailsState: ErrorDetailsState = {
    request: {
        searchText: '',
        errorCode: '',
        unitReference: '',
        pageIndex: 0,
        pageSize: 20,
        fromDate: format(addMonths(new Date(), -1), 'yyyy-MM-dd'),
        toDate: format(new Date(), 'yyyy-MM-dd'),
        sendingUnit: '',
        status: 'New', // TODO: This is ugly. The front end should not need to know about this. How to handle?
        errorCategory: '',
        eventCode: '',
        revisionState: '',
    } as ErrorRecordSearchRequest,
    results: null,
    rowSelection: {
        selectedRows: [],
        allOnScreenSelected: false,
        allRowsSelected: false
    }
};

// --- REDUCER ---

export function errorDetailsReducer(state: ErrorDetailsState = initialErrorDetailsState, action: ErrorDetailsActions): ErrorDetailsState {
    switch (action.type) {
        case ErrorDetailsActionTypes.LoadResultsSuccess:
            return {
                ...state,
                results: {
                    rows: action.payload.rows,
                    count: action.payload.count ?? state.results?.count
                },
                rowSelection: {
                    selectedRows: action.payload.rows?.map(() => false),
                    allOnScreenSelected: false,
                    allRowsSelected: false
                }
            };

        case ErrorDetailsActionTypes.SetSearchRequest:
            return {
                ...state,
                request: action.payload.request
            };

        case ErrorDetailsActionTypes.ToggleRowSelection: {
            // Spread the state
            const newState = {
                ...state,
                rowSelection: {
                    ...state.rowSelection,
                    allRowsSelected: false,
                    selectedRows: state.rowSelection.selectedRows.map((value, index) => {
                        return index === action.payload.index ? !value : value
                    })
                }
            };
            // Set allOnScreenSelected to true if all rows are true.
            newState.rowSelection.allOnScreenSelected = _.every(newState.rowSelection.selectedRows, value => value === true);
            return newState;
        }

        case ErrorDetailsActionTypes.ToggleSelectAllVisible: {
            const newValue = !state.rowSelection.allOnScreenSelected;
            return {
                ...state,
                rowSelection: {
                    ...state.rowSelection,
                    allOnScreenSelected: !state.rowSelection.allOnScreenSelected,
                    selectedRows: state.results?.rows?.map(() => newValue),
                }
            };
        }

        case ErrorDetailsActionTypes.SelectAllRows:
            return {
                ...state,
                rowSelection: {
                    ...state.rowSelection,
                    selectedRows: state.results?.rows?.map(() => true),
                    allOnScreenSelected: true,
                    allRowsSelected: true
                }
            };

        case ErrorDetailsActionTypes.ClearSelection:
            return {
                ...state,
                rowSelection: {
                    ...state.rowSelection,
                    selectedRows: state.results?.rows?.map(() => false),
                    allOnScreenSelected: false,
                    allRowsSelected: false
                }
            };

        case ErrorDetailsActionTypes.Paginate: {
            const newState = {
                ...state,
                request: {
                    ...state.request,
                    pageSize: action.payload.pageSize,
                    pageIndex: action.payload.pageIndex
                },

            };

            return newState;
        }

        // The remaining actions trigger side effects, but they do not directly modify the state.
        default:
            return state;
    }
}
