import * as _ from 'lodash';
import { addMonths, format } from 'date-fns';
import { ErrorSummaryRequest } from 'src/app/model/error-summary/ErrorSummaryRequest';
import { ErrorSummaryResultRow } from 'src/app/model/error-summary/ErrorSummaryResultRow';
import { ErrorSummaryActions, ErrorSummaryActionTypes } from './error-summary.actions';

// --- STATE STRUCTURE ---

export interface ErrorSummaryState {
    request: ErrorSummaryRequest | null;
    results: ErrorSummaryResultRow[] | null;
}

const initialErrorSummaryState: ErrorSummaryState = {
    request: {
        sendingUnit: '',
        pageSize: 100,
        fromDate: format(addMonths(new Date(), -1), 'yyyy-MM-dd'),
        toDate: format(new Date(), 'yyyy-MM-dd'),
    } as ErrorSummaryRequest,
    results: null,
};

// --- REDUCER ---

export function errorSummaryReducer(state: ErrorSummaryState = initialErrorSummaryState, action: ErrorSummaryActions): ErrorSummaryState {
    switch (action.type) {
        case ErrorSummaryActionTypes.LoadResultsSuccess:
            return {
                ...state,
                results: action.payload.results
            };

        case ErrorSummaryActionTypes.SetSearchRequest:
            return {
                ...state,
                request: action.payload.request
            }

        // The remaining actions trigger side effects, but they do not directly modify the state.
        default:
            return state;
    }
}
