import { Action } from '@ngrx/store';

export enum NotificationActionTypes {
  ErrorNotification = '[Notification] Error Notification',

}

export class ErrorNotificationAction implements Action {
  readonly type = NotificationActionTypes.ErrorNotification;

  constructor(readonly payload: { errorMessage: string }) { }
}


export type NotificationActions =
  ErrorNotificationAction;
