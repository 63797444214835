import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as PageTitleActions from '../../state/page-title/page-title.actions';
import * as DashboardActions from '../../state/dashboard/dashboard.actions';
import { Observable } from 'rxjs';
import {
    selectErrorInflowResult,
    selectTopErrorsResult,
    selectDashboardSearchRequest,
    selectTopFactoryHittersResult
} from 'src/app/state/dashboard/dashboard.selectors';
import { DashboardSearchRequest } from 'src/app/model/dashboard/dashboardSearchRequest';
import {
    ErrorInflowSearchResult,
    TopErrorsSearchResult,
    TopFactoryHittersSearchResult
} from 'src/app/state/dashboard/dashboard.reducers';
import { TopErrorResultChartPipe } from 'src/app/pipe/top-error-result.pipe';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

    topErrorsResult$: Observable<TopErrorsSearchResult>;
    errorInflowResult$: Observable<ErrorInflowSearchResult>;
    topFactoryHittersResult$: Observable<TopFactoryHittersSearchResult>;
    searchRequest$: Observable<DashboardSearchRequest>;

    constructor(
        private store: Store<AppState>,
        private topErrorResultChartPipe: TopErrorResultChartPipe
    ) { }

    ngOnInit(): void {
        this.store.dispatch(new PageTitleActions.SetPageTitleAction({ pageTitle: 'Dashboard' }));

        this.topErrorsResult$ = this.store.select(selectTopErrorsResult);
        this.errorInflowResult$ = this.store.select(selectErrorInflowResult);
        this.topFactoryHittersResult$ = this.store.select(selectTopFactoryHittersResult);
        this.searchRequest$ = this.store.select(selectDashboardSearchRequest);
    }

    performSearch(event: DashboardSearchRequest) {
        this.store.dispatch(new DashboardActions.SetSearchRequestsAction({ request: event }));
        this.store.dispatch(new DashboardActions.LoadErrorInflowResultsAction({ loadCount: true }));
        this.store.dispatch(new DashboardActions.LoadTopErrorsResultsAction({ loadCount: true }));
        this.store.dispatch(new DashboardActions.LoadTopFactoryHittersResultsAction({ loadCount: true }));
    }

    exportTopErrorsToExcel() {
        this.store.dispatch(new DashboardActions.ExportTopErrorsToExcelAction());
    }

    exportErrorInflowToExcel() {
        this.store.dispatch(new DashboardActions.ExportErrorInflowToExcelAction());
    }

    exportTopFactoryHittersToExcel() {
        this.store.dispatch(new DashboardActions.ExportTopFactoryHittersToExcelAction());
    }
}
