import { createSelector } from '@ngrx/store';
import { ExportExcelRequest } from 'src/app/model/error-download/ExportExcelRequest';
import { ArchiveAllSearchRequest } from 'src/app/model/error-record/ArchiveAllSearchRequest';
import { ArchiveErrorRequest } from 'src/app/model/error-record/ArchiveErrorRequest';
import { ErrorCountRequest } from 'src/app/model/error-search/ErrorCountRequest';
import { ErrorSearchResultRow } from 'src/app/model/error-search/ErrorSearchResultRow';
import { AppState } from '..';
import * as _ from 'lodash';
import { ErrorDetailsState } from './error-details.reducers';


const featureSelector = (state: AppState) => state.errorDetails;

export const selectErrorDetailsRequest = createSelector(
    featureSelector,
    (state: ErrorDetailsState) => state.request
);

export const selectErrorDetailsResult = createSelector(
    featureSelector,
    (state: ErrorDetailsState) => state.results
);

export const selectErrorDetailsRowSelection = createSelector(
    featureSelector,
    (state: ErrorDetailsState) => state.rowSelection
);

// Chained selectors for pagination
export const selectErrorDetailsPageIndex = createSelector(
    selectErrorDetailsRequest, request => request.pageIndex
);

export const selectErrorDetailsPageSize = createSelector(
    selectErrorDetailsRequest, request => request.pageSize
);


// The following selectors deal with row selection. They are all chained.
export const selectErrorDetailsAllRowsSelected = createSelector(
    selectErrorDetailsRowSelection,
    state => state.allRowsSelected
);

export const selectErrorDetailsAllOnScreenSelected = createSelector(
    selectErrorDetailsRowSelection,
    state => state.allOnScreenSelected
);

export const selectErrorDetailsSelectedRows = createSelector(
    selectErrorDetailsRowSelection,
    state => state.selectedRows
);

export const selectErrorDetailsSelectedCount = createSelector(
    selectErrorDetailsRowSelection,
    selectErrorDetailsResult,
    (selection, result): number => {
        if (selection.allRowsSelected) {
            return result.count;
        }
        return selection.selectedRows.filter(value => value === true).length;
    }
);

export const selectErrorDetailsCanSelectAll = createSelector(
    selectErrorDetailsRowSelection,
    state => state.allOnScreenSelected && !state.allRowsSelected
);

export const selectErrorDetailsSelectedIds = createSelector(
    selectErrorDetailsSelectedRows,
    selectErrorDetailsResult,
    (selection, result): number[] => {
        const selectedIndices: number[] = _.keys(_.pickBy(selection, value => value === true)).map(i => Number(i));

        const selectedErrors: ErrorSearchResultRow[] = result.rows.filter((value: ErrorSearchResultRow, index: number) =>
            _.includes(selectedIndices, index)
        );

        return selectedErrors.map(e => e.id);
    }
);

export const selectErrorDetailsCanBatchCorrect = createSelector(
    selectErrorDetailsSelectedRows,
    selectErrorDetailsAllRowsSelected,
    selectErrorDetailsResult,
    (selection, allRowsSelected, result): boolean => {
        // We can't batch correct if all rows (even off-screen) are selected.
        if (allRowsSelected) {
            return false;
        }

        // We can't batch correct unless at least two rows are selected.
        const selectedIndices: number[] = _.keys(_.pickBy(selection, value => value === true)).map(i => Number(i));
        if (selectedIndices.length <= 1) {
            return false;
        }

        // Find all the selected rows.
        const selectedErrors: ErrorSearchResultRow[] = result.rows.filter((value: ErrorSearchResultRow, index: number) =>
            _.includes(selectedIndices, index)
        );

        var structureChangeEvents: ErrorSearchResultRow[] = [];

        selectedErrors.forEach(function (error) {
            if (error.events.includes("StructureChanges") || error.events.includes("Structure Change"))
                structureChangeEvents.push(error);
        });

        if (structureChangeEvents.length > 0)
            return false;

        const events = _.uniq(selectedErrors.map(e => e.events?.join('') ?? []));
        return events.length === 1;
    }
);

// Selectors can be chained. These selectors takes the search request, and returns the result
// as a different type of (derived) request. This allows memoization of the result
// (caching) as well as reducing the need for two different (but very similar) type of
// requests.
export const selectErrorDetailsCountRequest = createSelector(
    selectErrorDetailsRequest,
    request => {
        return {
            searchText: request.searchText ?? null,
            errorCode: request.errorCode ?? null,
            unitReference: request.unitReference ?? null,
            fromDate: request.fromDate ?? null,
            toDate: request.toDate ?? null,
            sendingUnit: request.sendingUnit ?? null,
            status: request.status ?? null,
            errorCategory: request.errorCategory ?? null,
            eventCode: request.eventCode ?? null,
        } as ErrorCountRequest
    }
);

export const selectArchiveAllErrorsRequest = createSelector(
    selectErrorDetailsRequest,
    request => {
        return {
            searchText: request.searchText,
            errorCode: request.errorCode,
            unitReference: request.unitReference,
            fromDate: request.fromDate,
            toDate: request.toDate,
            sendingUnit: request.sendingUnit,
            status: request.status,
            errorCategory: request.errorCategory,
            eventCode: request.eventCode
        } as ArchiveAllSearchRequest
    }
);

export const selectArchiveRequest = createSelector(
    selectErrorDetailsSelectedIds,
    (selectedIds => {
        return { errorIds: selectedIds } as ArchiveErrorRequest;
    })
);

export const selectExportToExcelRequest = createSelector(
    selectErrorDetailsRequest,
    request => {
        return {
            searchText: request.searchText,
            errorCode: request.errorCode,
            unitReference: request.unitReference,
            fromDate: request.fromDate,
            toDate: request.toDate,
            sendingUnit: request.sendingUnit,
            status: request.status,
            errorCategory: request.errorCategory,
            eventCode: request.eventCode
        } as ExportExcelRequest
    }
);
