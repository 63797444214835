import { Component, OnInit } from '@angular/core';
import { ErrorRecord } from 'src/app/model/error-record/ErrorRecord';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as PageTitleActions from '../../state/page-title/page-title.actions';
import * as ErrorUpdateActions from '../../state/error-update/error-update.actions';
import * as NavigationActions from 'src/app/state/navigation/navigation.actions';
import { Observable } from 'rxjs';
import { selectError, selectErrorIsArchived } from 'src/app/state/error-update/error-update.selectors';
import { ErrorHeader } from 'src/app/model/error-record/ErrorCall';
import { ErrorResubmissionRequest } from 'src/app/model/error-record/ErrorResubmissionRequest';


@Component({
  selector: 'app-error-update',
  templateUrl: './error-update.component.html',
  styleUrls: ['./error-update.component.scss']
})

export class ErrorUpdateComponent implements OnInit {

  statusArchived: boolean;

  // NgRx Observable from store
  error$: Observable<ErrorRecord>;
  errorIsArchived$: Observable<boolean>;

  constructor(private store: Store<AppState>,) { }

  ngOnInit(): void {

    this.store.dispatch(new PageTitleActions.SetPageTitleAction({ pageTitle: 'Error Details' }));

    // NgRx Observable from store
    this.error$ = this.store.select(selectError);
    this.errorIsArchived$ = this.store.select(selectErrorIsArchived);
  }

  updateError(model: any) {
    const headers: ErrorHeader[] = [];

    model.headers.forEach(header => {
      headers.push({
        name: header.headerName,
        value: header.headerValue
      });
    });

    const updateRequest: ErrorResubmissionRequest = {
      payload: { payload: model.payload },
      call: {
        method: model.method,
        endpoint: model.endpoint,
        headers
      }
    };

    this.store.dispatch(new ErrorUpdateActions.UpdateErrorAction({ resubmissionRequest: updateRequest }));
  }

  public archiveError() {
    this.store.dispatch(new ErrorUpdateActions.ArchiveErrorAction());
  }

  public unarchiveError() {
    this.store.dispatch(new ErrorUpdateActions.UnarchiveErrorAction());
  }

  public sendEmail() {
    this.store.dispatch(new ErrorUpdateActions.SendEmailAction());
  }

  public goBack() {
    this.store.dispatch(new NavigationActions.NavigateToPageAction({ route: ['error-handling'] }));
  }
}
