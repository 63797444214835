import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { TelemetryClient } from '../lib/telemetry-client';
import { ApiDataService } from './api-data.service';

// Code stolen from https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    private readonly telemetryClient: TelemetryClient;

    constructor(loginService: LoginService, apiService: ApiDataService) {
        this.telemetryClient = new TelemetryClient();

        loginService.observeLoggedIn(identity => {
            this.telemetryClient.setAuthenticatedUserContext(identity.username, identity.username, true);
        });

        apiService.loadConfigFromBackend()
            .then(appConfig => {
                this.telemetryClient.initialize(appConfig);
            });
    }

    logPageView(name?: string, url?: string) { // option to call manually
        this.telemetryClient.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.telemetryClient.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.telemetryClient.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        this.telemetryClient.trackException({ exception: exception, severityLevel: severityLevel });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.telemetryClient.trackTrace({ message: message }, properties);
    }
}
