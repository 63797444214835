import { PageTitleActions, PageTitleActionTypes } from './page-title.actions';

export interface PageTitleState {
    pageTitle: string;
}

const initialPageTitleState: PageTitleState = { pageTitle: '' };

export function pageTitleReducer(state: PageTitleState = initialPageTitleState, action: PageTitleActions): PageTitleState {
    switch (action.type) {
        case PageTitleActionTypes.PageTitle:
            return {
                ...state,
                pageTitle: action.payload.pageTitle,
            };
        default:
            return state;
    }
}
