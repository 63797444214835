import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { AppDashboardComponent } from './components/layout/app-dashboard/app-dashboard.component';
import { ErrorDetailsComponent } from './components/error-details/error-details.component';
import { ErrorSummaryComponent } from './components/error-summary/error-summary.component';
import { AppLayoutComponent } from './components/layout/app-layout/app-layout.component';
import { SideNavMenuComponent } from './components/side-nav-menu/side-nav-menu.component';
import { DatePipe } from '@angular/common';
import { SpinnerLoaderComponent } from './components/spinner-loader/spinner-loader.component';
import { SpinnerLoaderService } from './service/spinner-loader.service';
import { Interceptor } from './interceptor/interceptor';
import { ApiDataService } from './service/api-data.service';
import { NotificationService } from './service/notification.service';
import { TECTStateService } from './service/tect-state.service';
import { ChartsModule } from 'ng2-charts';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { DuplicateFilterComponent } from './components/statistics/duplicate-filter/duplicate-filter.component';
import { DuplicateStatisticsComponent } from './components/statistics/duplicate-statistics/duplicate-statistics.component';
import { MostCommonErrorComponent } from './components/statistics/most-common-error/most-common-error.component';
import { MostCommonErrorFilterComponent } from './components/statistics/most-common-error-filter/most-common-error-filter.component';
import { ErrorCategoryPipe } from './pipe/error-category.pipe';
import { TopErrorResultChartPipe } from './pipe/top-error-result.pipe';
import { RepeatSectionComponent } from './components/formly/repeat-section/repeat-section.component';
import { InvalidSerialNumberSearchComponent } from './components/algorithm-assisted/invalid-serial-number-search/invalid-serial-number-search.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper'
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LegacyErrorSearchComponent } from './components/legacy-error-search/legacy-error-search.component';
import { LegacyErrorDisplayComponent } from './components/legacy-error-display/legacy-error-display.component';
import { DisplayFieldComponent } from './components/generic/display-field/display-field.component';
import { BatchProcessStepperComponent } from './components/batch-process/batch-process-stepper/batch-process-stepper.component';
import { BatchProcessStepDisplayComponent } from './components/batch-process/batch-process-step-display/batch-process-step-display.component';
import { BatchProcessStepFormComponent } from './components/batch-process/batch-process-step-form/batch-process-step-form.component';
import { BatchProcessPreviewComponent } from './components/batch-process/batch-process-preview/batch-process-preview.component';
import { BatchProcessCompletionComponent } from './components/batch-process/batch-process-completion/batch-process-completion.component';
import { ErrorUpdateComponent } from './components/error-update/error-update.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './state';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import environment from '../environments/static';
import { EffectsModule } from '@ngrx/effects';
import { ErrorDetailsEffects } from './state/error-details/error-details.effects';
import { ErrorDetailsSearchComponent } from './components/error-details/error-details-search/error-details-search.component';
import { ErrorCategorySelectComponent } from './components/error-details/error-filter/error-category-select/error-category-select.component';
import { ErrorFilterComponent } from './components/error-details/error-filter/error-filter.component';
import { NotificationEffects } from './state/notification/notification.effects';
import { EventCodePipe } from './pipe/event-code.pipe';
import { ErrorStatusPipe } from './pipe/error-status.pipe';
import { ErrorHandlerService } from './service/error-handler.service';
import { ErrorSummaryEffects } from './state/error-summary/error-summary.effects';
import { ErrorSummarySearchComponent } from './components/error-summary/error-summary-search/error-summary-search.component';
import { BatchCorrectionEffects } from './state/batch-correction/batch-correction.effects';
import { ErrorUpdateEffects } from './state/error-update/error-update.effects';
import { ErrorUpdateFormComponent } from './components/error-update/error-update-form/error-update-form.component';
import { NavigationEffects } from './state/navigation/navigation.effects';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardTopFactoryHittersComponent } from './components/dashboard/dashboard-top-factory-hitters/dashboard-top-factory-hitters/dashboard-top-factory-hitters.component';
import { MITFilterComponent } from './components/missing-in-tracy/missing-in-tracy-filter/missing-in-tracy-filter.component';
import { MITSearchComponent } from './components/missing-in-tracy/missing-in-tracy-search/missing-in-tracy-search.component';
import { MITInflowComponent } from './components/missing-in-tracy/missing-in-tracy-inflow/missing-in-tracy-inflow.component';
import { MITComponent } from './components/missing-in-tracy/missing-in-tracy.component';
import { TopFactoryHittersBarChartComponent } from './components/dashboard/dashboard-top-factory-hitters-bar-chart/top-factory-hitters-bar-chart.component';
import { TopFactoryHittersResultLabelsChartPipe } from './pipe/top-factory-hitters-result-labels.pipe';
import { TopFactoryHittersResultChartPipe } from './pipe/top-factory-hitters-result.pipe';
import { ErrorInflowResultChartPipe } from './pipe/error-inflow-chart.pipe';
import { ErrorInflowResultLabelsPipe } from './pipe/error-inflow-labels.pipe';
import { ErrorInflowBarChartComponent } from './components/dashboard/error-inflow-bar-chart/error-inflow-bar-chart.component';
import { TopErrorResultLabelsChartPipe } from './pipe/top-error-result-labels.pipe';
import { TopErrorsBarChartComponent } from './components/dashboard/top-errors-bar-chart/top-errors-bar-chart.component';
import { MITEffects } from './state/missing-in-tracy/missing-in-tracy.effects';
import { TopMITFactoryHittersResultChartPipe } from './pipe/missing-in-tracy/missing-in-tracy-top-factory-hitters-result.pipe';
import { TopMITFactoryHittersResultLabelsChartPipe } from './pipe/missing-in-tracy/missing-in-tracy-top-factory-hitters-result-labels.pipe';
import { TopMITFactoryHittersBarChartComponent } from './components/missing-in-tracy/missing-in-tracy-top-factory-hitters-bar-chart/missing-in-tracy-top-factory-hitters-bar-chart.component';
import { TopMITFactoryHittersComponent } from './components/missing-in-tracy/missing-in-tracy-top-factory-hitters/missing-in-tracy-top-factory-hitters.component';
import { MITInflowBarChartComponent } from './components/missing-in-tracy/missing-in-tracy-inflow-bar-chart/missing-in-tracy-inflow-bar-chart.component';
import { MITInflowResultChartPipe } from './pipe/missing-in-tracy/missing-in-tracy-inflow-chart.pipe';
import { MITInflowResultLabelsPipe } from './pipe/missing-in-tracy/missing-in-tracy-inflow-labels.pipe';
import { DashboardSearchComponent } from './components/dashboard/dashboard-search/dashboard-search.component';
import { DashboardFilterComponent } from './components/dashboard/dashboard-filter/dashboard-filter.component';
import { DashboardTopErrorsComponent } from './components/dashboard/dashboard-top-errors/dashboard-top-errors.component';
import { DashboardErrorInflowComponent } from './components/dashboard/dashboard-error-inflow/dashboard-error-inflow.component';
import { DashboardEffects } from './state/dashboard/dashboard.effects';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        AppDashboardComponent,
        ErrorDetailsComponent,
        ErrorSummaryComponent,
        AppLayoutComponent,
        SideNavMenuComponent,
        ErrorFilterComponent,
        MITFilterComponent,
        SpinnerLoaderComponent,
        DuplicateFilterComponent,
        DuplicateStatisticsComponent,
        MostCommonErrorComponent,
        MostCommonErrorFilterComponent,
        ErrorCategoryPipe,
        EventCodePipe,
        ErrorStatusPipe,
        RepeatSectionComponent,
        InvalidSerialNumberSearchComponent,
        LegacyErrorSearchComponent,
        LegacyErrorDisplayComponent,
        DisplayFieldComponent,
        BatchProcessStepperComponent,
        BatchProcessStepDisplayComponent,
        BatchProcessStepFormComponent,
        BatchProcessPreviewComponent,
        BatchProcessCompletionComponent,
        ErrorCategorySelectComponent,
        ErrorUpdateComponent,
        ErrorDetailsSearchComponent,
        ErrorSummarySearchComponent,
        ErrorUpdateFormComponent,
        DashboardComponent,
        DashboardSearchComponent,
        DashboardFilterComponent,
        DashboardTopErrorsComponent,
        DashboardErrorInflowComponent,
        DashboardTopFactoryHittersComponent,
        MITComponent,
        MITInflowComponent,
        MITSearchComponent,
        ErrorInflowBarChartComponent,
        TopErrorsBarChartComponent,
        TopMITFactoryHittersComponent,
        ErrorInflowBarChartComponent,
        MITInflowBarChartComponent,
        TopErrorsBarChartComponent,
        TopMITFactoryHittersBarChartComponent,
        TopFactoryHittersBarChartComponent,
        TopFactoryHittersResultChartPipe,
        TopFactoryHittersResultLabelsChartPipe,
        ErrorInflowResultChartPipe,
        ErrorInflowResultLabelsPipe,
        MITInflowResultChartPipe,
        MITInflowResultLabelsPipe,
        TopErrorResultChartPipe,
        TopErrorResultLabelsChartPipe,
        TopMITFactoryHittersResultChartPipe,
        TopMITFactoryHittersResultLabelsChartPipe,
    ],
    imports: [
        MatSnackBarModule,
        MatBadgeModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        MatMenuModule,
        MatTableModule,
        MatTabsModule,
        MatSidenavModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatSelectModule,
        MatStepperModule,
        MatPaginatorModule,
        MatOptionModule,
        ChartsModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatChipsModule,
        MatListModule,
        MatToolbarModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSortModule,
        MatCheckboxModule,
        FormlyModule.forRoot({
            types: [
                { name: 'repeat', component: RepeatSectionComponent },
            ]
        }),
        FormlyMaterialModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        environment.compiled ? [] : StoreDevtoolsModule.instrument(),
        EffectsModule.forRoot([
            BatchCorrectionEffects,
            ErrorDetailsEffects,
            NotificationEffects,
            ErrorSummaryEffects,
            ErrorUpdateEffects,
            NavigationEffects,
            DashboardEffects,
            MITEffects
        ]),
    ],
    providers: [
        DatePipe,
        ErrorCategoryPipe,
        EventCodePipe,
        ErrorStatusPipe,
        TopFactoryHittersResultChartPipe,
        TopFactoryHittersResultLabelsChartPipe,
        ErrorInflowResultChartPipe,
        ErrorInflowResultLabelsPipe,
        MITInflowResultChartPipe,
        MITInflowResultLabelsPipe,
        TopErrorResultChartPipe,
        TopErrorResultLabelsChartPipe,
        TopMITFactoryHittersResultChartPipe,
        TopMITFactoryHittersResultLabelsChartPipe,
        HttpClientModule,
        MatBadgeModule,
        SpinnerLoaderService,
        ApiDataService,
        TECTStateService,
        NotificationService,
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService
        },
        MatTableModule
        , {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true
        }],
    bootstrap: [AppComponent]
})
export class AppModule { }
