import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import DuplicateReportRequest from 'src/app/model/statistics/DuplicateReportRequest';
import { NotificationService } from 'src/app/service/notification.service';
import DuplicateReportResultRow from 'src/app/model/statistics/DuplicateReportResultRow';
import { ApiDataService } from 'src/app/service/api-data.service';
import { MatSort, Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as PageTitleActions from '../../../state/page-title/page-title.actions';

@Component({
  selector: 'app-duplicate-statistics',
  templateUrl: './duplicate-statistics.component.html',
  styleUrls: ['./duplicate-statistics.component.scss']
})
export class DuplicateStatisticsComponent implements OnInit {

  duplicateReport: DuplicateReportResultRow[] = [];
  dataSource = new MatTableDataSource<DuplicateReportResultRow>(this.duplicateReport);
  showingGroupedReport = false;

  statisticTableHeader: string[] = [];

  @ViewChild(MatSort) set content(sort: MatSort) { this.dataSource.sort = sort; }

  constructor(
    private apiDataService: ApiDataService,
    private notificationService: NotificationService,
    private store: Store<AppState>) {

  }

  ngOnInit() {
    this.setHeaders(this.showingGroupedReport);
    this.store.dispatch(new PageTitleActions.SetPageTitleAction({ pageTitle: 'Duplicate Errors' }));
  }

  private setHeaders(grouped: boolean) {
    if (grouped) {
      this.statisticTableHeader = [
        'year',
        'month',
        'sendingUnit',
        'count',
      ];
    } else {
      this.statisticTableHeader = [
        'sendingUnit',
        'count',
      ];
    }
  }

  private notifyIfEmpty() {
    if (this.duplicateReport.length === 0) {
      this.notificationService.showSuccess('No duplicates matched your search.');
    }
  }

  performSearch(event: DuplicateReportRequest) {
    this.apiDataService.getDuplicateReport(event).subscribe((response: DuplicateReportResultRow[]) => {
      this.showingGroupedReport = event.groupByMonth;
      this.setHeaders(this.showingGroupedReport);
      this.duplicateReport = response;
      this.dataSource.data = this.duplicateReport;
      this.notifyIfEmpty();
    }, (error) => {
      this.notificationService.showError('An unknown error occurred');
    });

  }

  onSortData(sort: Sort) {
    if (sort.active && sort.direction !== '') {
      if (sort.active === 'month') {
        // TODO: Sort by something here
      }
    }
    this.dataSource.data = this.duplicateReport;
  }

  downloadExcel(event: DuplicateReportRequest) {
    this.apiDataService.downloadDuplicateReport(event);
  }
}
