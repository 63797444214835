import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from '../../service/lookup.service';
import { ErrorInflowSearchResult, TopErrorsSearchResult } from '../../state/dashboard/dashboard.reducers';
import { MITInflowSearchResult } from '../../state/missing-in-tracy/missing-in-tracy.reducers';

@Pipe({
    name: 'MITInflowResultChart'
})

export class MITInflowResultChartPipe implements PipeTransform {

    constructor() { }

    transform(result: MITInflowSearchResult, ...args: any): any[] {
        let barChartData = [];
        let dataInflow = []
        let dataRemaining = [];
        let dataPercentHealed = [];
        for (let row of result.rows) {
            dataInflow.push(row.totalMIT);
            dataRemaining.push(row.remainingMIT);
            dataPercentHealed.push((Math.round(row.percentHealed * 100) / 100) + "%");
        }
        barChartData.push({ data: dataInflow, label: "Inflow" });
        barChartData.push({ data: dataRemaining, label: "Remaining" });
        barChartData.push({ data: dataPercentHealed, label: "Percent Healed", hidden: true }); // Data used in the tooltip therefore hidden
        return barChartData
    }
}
