import { Action } from "@ngrx/store";
import { ErrorRecord } from "src/app/model/error-record/ErrorRecord";
import { ErrorResubmissionRequest } from "src/app/model/error-record/ErrorResubmissionRequest";
import { FailedResubmission } from "src/app/model/error-record/FailedResubmission";

export enum ErrorUpdateActionTypes {
    LoadError = '[Error Update] Load Error',
    LoadErrorSuccess = '[Error Update] Load Error Success',
    UpdateError = '[Error Update] Update Error',
    UpdateErrorSuccess = '[Error Update] Update Error Success',
    UpdateErrorFailed = '[Error Update] Update Error Failed',
    ArchiveError = '[Error Update] Archive Error',
    ArchiveErrorSuccess = '[Error Update] Archive Error Success',
    UnarchiveError = '[Error Update] Unarchive Error',
    UnarchiveErrorSuccess = '[Error Update] Unarchive Error Success',
    SendEmail = '[Error Update] Send Email',
    SendEmailSuccess = '[Error Update] Send Email Success',
}

export class LoadErrorAction implements Action {
    readonly type = ErrorUpdateActionTypes.LoadError;

    constructor(readonly payload: { id: number }) { }
}

export class LoadErrorSuccessAction implements Action {
    readonly type = ErrorUpdateActionTypes.LoadErrorSuccess;

    constructor(readonly payload: { errorRecord: ErrorRecord }) { }
}

export class UpdateErrorAction implements Action {
    readonly type = ErrorUpdateActionTypes.UpdateError;

    constructor(readonly payload: { resubmissionRequest: ErrorResubmissionRequest }) { }
}

export class UpdateErrorSuccessAction implements Action {
    readonly type = ErrorUpdateActionTypes.UpdateErrorSuccess;
}

export class UpdateErrorFailedAction implements Action {
    readonly type = ErrorUpdateActionTypes.UpdateErrorFailed;

    constructor(readonly payload: { resubmission: FailedResubmission }) { }
}

export class ArchiveErrorAction implements Action {
    readonly type = ErrorUpdateActionTypes.ArchiveError;
}

export class ArchiveErrorSuccessAction implements Action {
    readonly type = ErrorUpdateActionTypes.ArchiveErrorSuccess;
}

export class UnarchiveErrorAction implements Action {
    readonly type = ErrorUpdateActionTypes.UnarchiveError;
}

export class UnarchiveErrorSuccessAction implements Action {
    readonly type = ErrorUpdateActionTypes.UnarchiveErrorSuccess;
}

export class SendEmailAction implements Action {
    readonly type = ErrorUpdateActionTypes.SendEmail;
}

export class SendEmailSuccessAction implements Action {
    readonly type = ErrorUpdateActionTypes.SendEmailSuccess;
}

export type ErrorUpdateActions =
    LoadErrorAction |
    LoadErrorSuccessAction |
    UpdateErrorAction |
    UpdateErrorSuccessAction |
    UpdateErrorFailedAction |
    ArchiveErrorAction |
    ArchiveErrorSuccessAction |
    UnarchiveErrorAction |
    UnarchiveErrorSuccessAction |
    SendEmailAction |
    SendEmailSuccessAction;