import { Action } from "@ngrx/store";
import { DashboardSearchRequest } from "src/app/model/dashboard/dashboardSearchRequest";
import { MITInflowResultRow } from "src/app/model/missing-in-tracy/MITInflowResultRow";
import { TopMITFactoryHittersResultRow } from "src/app/model/missing-in-tracy/topMITFactoryHittersResultRow";


export enum MITActionTypes {
    LoadMITInflowResults = '[MIT] Load MIT Inflow Results',
    LoadMITInflowResultsSuccess = '[MIT] Load MIT Inflow Results Success',
    SetSearchRequests = '[MIT] Set Search Requests',
    LoadTopMITFactoryHittersResults = '[MIT] Load Top MIT Factory Hitters Results',
    LoadTopMITFactoryHittersResultsSuccess = '[MIT] Load Top MIT Factory Hitters Results Success',
}

export class SetSearchRequestsAction implements Action {
    readonly type = MITActionTypes.SetSearchRequests;

    constructor(readonly payload: { request: DashboardSearchRequest }) { }
}

export class LoadMITInflowResultsAction implements Action {
    readonly type = MITActionTypes.LoadMITInflowResults;

    constructor(readonly payload: { loadCount: boolean }) { }
}

export class LoadMITInflowResultsSuccessAction implements Action {
    readonly type = MITActionTypes.LoadMITInflowResultsSuccess;

    constructor(readonly payload: {
        rows: MITInflowResultRow[],
        count: number | null
    }) { }
}

export class LoadTopMITFactoryHittersResultsAction implements Action {
    readonly type = MITActionTypes.LoadTopMITFactoryHittersResults;

    constructor(readonly payload: { loadCount: boolean }) { }
}

export class LoadTopMITFactoryHittersResultsSuccessAction implements Action {
    readonly type = MITActionTypes.LoadTopMITFactoryHittersResultsSuccess;

    constructor(readonly payload: {
        rows: TopMITFactoryHittersResultRow[],
        count: number | null
    }) { }
}

export type MITActions =
    LoadMITInflowResultsAction |
    LoadMITInflowResultsSuccessAction |
    LoadTopMITFactoryHittersResultsAction |
    LoadTopMITFactoryHittersResultsSuccessAction |
    SetSearchRequestsAction;
