import { Action } from '@ngrx/store';

export enum NavigationActionTypes {
  NavigateToPage = '[Navigation] Navigate To Page',

}

export class NavigateToPageAction implements Action {
  readonly type = NavigationActionTypes.NavigateToPage;

  constructor(readonly payload: { route: string[] }) { }
}


export type NavigationActions =
  NavigateToPageAction;
