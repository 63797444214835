<div *ngIf="!deployed">
    <div class="sign-in-text">
        <span>Sign in to TECT</span><img src="assets/img/tect-logo.png">
    </div>
    <mat-card class="mat-elevation-z8">
        <div style="padding: 20px;">
            <form novalidate [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <mat-form-field class="custom-input">
                    <input matInput autocomplete="off" formControlName="username" required placeholder="Username">
                </mat-form-field>
                <br />
                <div>
                    <button class="login-button mat-elevation-z4" type="submit" mat-raised-button
                        [disabled]="!loginForm.valid">Login</button>
                </div>
            </form>
            <div style="margin-top: 1em;">
                <span class="login-progress-text">[Dev] Please log in</span>
            </div>
        </div>
    </mat-card>
</div>
