import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { forkJoin, of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { ApiDataService } from "src/app/service/api-data.service";
import { AppState } from "..";
import { ErrorNotificationAction } from "../notification/notification.actions";
import { ErrorCount } from "src/app/model/error-search/ErrorCount";
import { LoadMITInflowResultsAction, LoadMITInflowResultsSuccessAction, LoadTopMITFactoryHittersResultsAction, LoadTopMITFactoryHittersResultsSuccessAction, MITActionTypes } from "./missing-in-tracy.actions";
import { selectMITInflowRequest, selectTopMITFactoryHittersRequest } from "./missing-in-tracy.selectors";

@Injectable()
export class MITEffects {

    /**
     * When a LoadDetailsSearchAction is dispatched, create an error details request with parameters
     * that match the row in the action. Dispatch it to the store, then start the error details search
     * by dispatching an MITActionTypes.LoadResultsAction.
     */

    @Effect()
    loadMITInflowResults$ = this.actions$
        .pipe(
            ofType<LoadMITInflowResultsAction>(MITActionTypes.LoadMITInflowResults),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                return forkJoin({
                    searchResult: this.apiDataService.searchMITInflow(selectMITInflowRequest(storeState)),
                    countResult: action.payload.loadCount ?
                        this.apiDataService.MITInflowCount(selectMITInflowRequest(storeState)) :
                        of({ count: null } as ErrorCount)
                })
                    .pipe(
                        map(({ searchResult, countResult }) => {
                            return new LoadMITInflowResultsSuccessAction({ rows: searchResult, count: countResult.count });
                        }),
                        catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to load errors: ${error.messade}` })))
                    )
            })
        );

    @Effect()
    loadTopMITFactoryHittersResult$ = this.actions$
        .pipe(
            ofType<LoadTopMITFactoryHittersResultsAction>(MITActionTypes.LoadTopMITFactoryHittersResults),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                return forkJoin({
                    searchResult: this.apiDataService.searchTopMITFactoryHitters(selectTopMITFactoryHittersRequest(storeState)),
                    countResult: action.payload.loadCount ?
                        this.apiDataService.topMITFactoryHittersCount(selectTopMITFactoryHittersRequest(storeState)) :
                        of({ count: null } as ErrorCount)
                })
                    .pipe(
                        map(({ searchResult, countResult }) => {
                            return new LoadTopMITFactoryHittersResultsSuccessAction({ rows: searchResult, count: countResult.count });
                        }),
                        catchError((error) => of(new ErrorNotificationAction({ errorMessage: `Failed to load errors: ${error.message}` })))
                    )
            })
        );

    constructor(
        private actions$: Actions,
        private apiDataService: ApiDataService,
        private store$: Store<AppState>) { }
}
