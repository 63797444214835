<app-spinner-loader></app-spinner-loader>

<div class="div-panel sticky-top">
  <app-most-common-error-filter (searchClicked)="performSearch($event)" (downloadClicked)="downloadExcel($event)">
  </app-most-common-error-filter>
</div>

<div class="row" *ngIf="mostCommonErrorReport.length > 0">
  <div class="col-sm-12">

    <div style="margin-top:-2px; overflow-y: auto; overflow-x: hidden; height: 65vh !important;">
      <table mat-table id="report-table" matSort [dataSource]="dataSource" matSort class="mat-elevation-z8">

        <ng-container matColumnDef="sendingUnit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sending Unit</th>
          <td mat-cell *matCellDef="let element"> {{ element.sendingUnit }} </td>
        </ng-container>

        <ng-container matColumnDef="errorCategory">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Error Category</th>
          <td mat-cell *matCellDef="let element"> {{ element.errorCategory | errorCategory }} </td>
        </ng-container>

        <ng-container matColumnDef="numberOfErrors">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Number of Errors</th>
          <td mat-cell *matCellDef="let element"> {{ element.numberOfErrors }} </td>
        </ng-container>

        <ng-container matColumnDef="remainingErrors">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Remaining Errors</th>
          <td mat-cell *matCellDef="let element"> {{ element.remainingErrors }} </td>
        </ng-container>

        <ng-container matColumnDef="totalErrors">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Errors</th>
          <td mat-cell *matCellDef="let element"> {{ element.totalErrors }} </td>
        </ng-container>

        <tr mat-header-row class="centre" *matHeaderRowDef="statisticTableHeader; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: statisticTableHeader;"></tr>
      </table>
    </div>
  </div>
</div>