import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-error-category-select',
  templateUrl: './error-category-select.component.html',
  styleUrls: ['./error-category-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorCategorySelectComponent implements OnInit {

  @Input() errorCategories: string[];
  @Input() defaultSelected: string;
  @Output() categoryChanged: EventEmitter<string> = new EventEmitter<string>();

  selected = '';
  panelClass = 'error-category-select';

  constructor() {
  }

  ngOnInit(): void {
    this.selected = this.defaultSelected;
  }

  valueChanged() {
    this.categoryChanged.emit(this.selected);
  }
}
