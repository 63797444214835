import { Pipe, PipeTransform } from '@angular/core';
import { TopMITFactoryHittersSearchResult } from 'src/app/state/missing-in-tracy/missing-in-tracy.reducers';

@Pipe({
    name: 'topMITFactoryHittersResultLabelsChart'
})

export class TopMITFactoryHittersResultLabelsChartPipe implements PipeTransform {

    constructor() { }

    transform(result: TopMITFactoryHittersSearchResult, ...args: any): string[] {
        let barChartLabels = [];
        for (let row of result.rows) {
            barChartLabels.push(row.sendingUnit)
        }
        return barChartLabels
    }
}
