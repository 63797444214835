<form [formGroup]="form" (ngSubmit)="formlySubmit(model)">
    <div class="row">
        <div class="col-md-6">
            <p class="section-label"><strong>Error Categories</strong></p>
            <p>{{errorCategories}}</p>
            <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
        </div>
    </div>

    <div class="modal-bottom-toolbar">
        <button *ngIf="allowedToUpdate" mat-raised-button type="button" (click)="sendEmailClicked()">Send Email</button>
        <button *ngIf="!errorIsArchived && allowedToUpdate" mat-raised-button type="button" color="warn"
            (click)="archiveErrorClicked()">
            Archive error</button>
        <button *ngIf="errorIsArchived && allowedToUpdate" mat-raised-button type="button" color="primary"
            (click)="unarchiveErrorClicked()">Unarchive
            error</button>
        <button *ngIf="allowedToUpdate" mat-raised-button type="submit" color="primary">
            Update in Tracy</button>
        <button mat-raised-button type="button" (click)="cancelClicked()">Cancel</button>
    </div>
</form>