import { Component, OnInit, ViewChild } from '@angular/core';
import MostCommonErrorReportRequest from 'src/app/model/statistics/MostCommonErrorReportRequest';
import { NotificationService } from 'src/app/service/notification.service';
import MostCommonErrorReportResultRow from 'src/app/model/statistics/MostCommonErrorReportResultRow';
import { ApiDataService } from 'src/app/service/api-data.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as PageTitleActions from '../../../state/page-title/page-title.actions';

@Component({
  selector: 'app-most-common-error',
  templateUrl: './most-common-error.component.html',
  styleUrls: ['./most-common-error.component.scss']
})
export class MostCommonErrorComponent implements OnInit {

  mostCommonErrorReport: MostCommonErrorReportResultRow[] = [];
  dataSource = new MatTableDataSource<MostCommonErrorReportResultRow>(this.mostCommonErrorReport);
  showingGroupedReport = false;

  statisticTableHeader: string[] = [
    'sendingUnit',
    'errorCategory',
    'numberOfErrors',
    'remainingErrors',
    'totalErrors',
  ];

  @ViewChild(MatSort) set content(sort: MatSort) { this.dataSource.sort = sort; }

  constructor(
    private apiDataService: ApiDataService,
    private notificationService: NotificationService,
    private store: Store<AppState>) {

  }

  ngOnInit() {
    this.store.dispatch(new PageTitleActions.SetPageTitleAction({ pageTitle: 'Most Common Errors' }));
  }

  private notifyIfEmpty() {
    if (this.mostCommonErrorReport.length === 0) {
      this.notificationService.showSuccess('No errors matched your search.');
    }
  }

  performSearch(event: MostCommonErrorReportRequest) {
    this.apiDataService.getMostCommonErrorReport(event).subscribe((response: MostCommonErrorReportResultRow[]) => {
      this.mostCommonErrorReport = response;
      this.dataSource.data = this.mostCommonErrorReport;
      this.notifyIfEmpty();
    }, (error) => {
      this.notificationService.showError('An unknown error occurred');
    });
  }

  downloadExcel(event: MostCommonErrorReportRequest) {
    this.apiDataService.downloadMostCommonErrorReport(event);
  }
}
