import { DashboardActions, DashboardActionTypes } from './dashboard.actions';
import * as _ from 'lodash';
import { addDays, format } from 'date-fns';
import { TopErrorInflowResultRow } from "src/app/model/dashboard/topErrorInflowResultRow";
import { ErrorInflowResultRow } from 'src/app/model/dashboard/errorInflowResultRow';
import { DashboardSearchRequest } from 'src/app/model/dashboard/dashboardSearchRequest';
import { TopFactoryHittersResultRow } from 'src/app/model/dashboard/topFactoryHittersResultRow';

export interface DashboardState {
    searchRequest: DashboardSearchRequest | null;
    topErrorsResult: TopErrorsSearchResult | null;
    errorInflowResult: ErrorInflowSearchResult | null;
    topFactoryHittersResult: TopFactoryHittersSearchResult | null;
}

export interface TopErrorsSearchResult {
    rows: TopErrorInflowResultRow[] | null;
    count: number;
}

export interface ErrorInflowSearchResult {
    rows: ErrorInflowResultRow[] | null;
    count: number;
}

export interface TopFactoryHittersSearchResult {
    rows: TopFactoryHittersResultRow[] | null;
    count: number;
}

const initialDashboardState: DashboardState = {
    searchRequest: {
        fromDate: format(addDays(new Date(), -1), 'yyyy-MM-dd'),
        toDate: format(new Date(), 'yyyy-MM-dd'),
        dateGrouping: 'Day',
        eventType: '',
        sendingUnit: '',
        productNumber: '',
        revisionState: '',
        errorCategory: '',
        pageSize: 100,
    },
    topErrorsResult: null,
    errorInflowResult: null,
    topFactoryHittersResult: null,
};

export function dashboardReducer(state: DashboardState = initialDashboardState, action: DashboardActions): DashboardState {
    switch (action.type) {
        case DashboardActionTypes.SetSearchRequests:
            return {
                ...state,
                searchRequest: action.payload.request,
            };
        case DashboardActionTypes.LoadTopErrorsResultsSuccess:
            return {
                ...state,
                topErrorsResult: {
                    rows: action.payload.rows,
                    count: action.payload.count ?? state.topErrorsResult?.count,
                }
            };
        case DashboardActionTypes.LoadErrorInflowResultsSuccess:
            return {
                ...state,
                errorInflowResult: {
                    rows: action.payload.rows,
                    count: action.payload.count ?? state.errorInflowResult?.count,
                }
            };
        case DashboardActionTypes.LoadTopFactoryHittersResultsSuccess:
            return {
                ...state,
                topFactoryHittersResult: {
                    rows: action.payload.rows,
                    count: action.payload.count ?? state.topFactoryHittersResult?.count,
                }
            };
        default:
            return state;
    }
}
